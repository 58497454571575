import React, { useState, useEffect } from "react";
import CurrencyFlag from "react-currency-flags";
import useStyles from "./styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditIcon from "@mui/icons-material/Edit";
import {
  validateBeneficiaryDetailsReviewScreen,
  getDataForReviewBenefiToAddBenefiPage,
  validateBeneficiaryDetails,
} from "../../CommonService/sendPaymentService";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import flagsCode from "../../CommonService/flagServies";
const ReviewBeneficiary = ({
  onContinue,
  selectedBeneDetails,
  purposeCodeList,
  nextAssignmentId,
  eTag,
  setEtag,
  setReviewDetails,
  onPrevious,
  setPreviousData,
  previousData,
}) => {
  const classes = useStyles();
  const [purposeCode, setPurposeCode] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [editableBeneDetails, setEditableBeneDetails] =
    useState(selectedBeneDetails);
  const [visible, setvisibile] = useState(true);
  const [icon, seticon] = useState(false);

  const [flag, setFlag] = useState(true);

  const handleEdit = () => {
    setFlag(!flag);
    setIsEditable(!isEditable);
  };

  useEffect(() => {
    if (previousData) {
      setEditableBeneDetails(
        previousData?.caseInfo?.content?.SelectedBeneDetails
      );
      setPurposeCode(
        previousData?.caseInfo?.content?.SelectedBeneDetails?.PaymentPurposeCode
      );
    }
  }, [previousData]);

  const handlePurposeCode = (event) => {
    const purposeCode = event.target.value;
    setPurposeCode(purposeCode);
  };

  const handleContinue = async () => {
    try {
      const response = await validateBeneficiaryDetailsReviewScreen(
        purposeCode,
        nextAssignmentId,
        eTag
      );
      setReviewDetails(response.data.data.caseInfo.content);
      setEtag(response.headers.etag);
      onContinue(nextAssignmentId, eTag, setReviewDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrevious = async () => {
    try {
      const response = await getDataForReviewBenefiToAddBenefiPage(
        editableBeneDetails.BeneBankAddress,
        purposeCode,
        nextAssignmentId,
        eTag
      );
      setEtag(response.headers["etag"]);
      const resourceData = response.data.data;
      setPreviousData(resourceData);
      onPrevious();
    } catch {}
  };

  const handleValidateAndSave = async () => {
    try {
      const response = await validateBeneficiaryDetails(
        editableBeneDetails,
        nextAssignmentId,
        eTag
      );
      setEditableBeneDetails(
        response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
      );
      setFlag(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditableBeneDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handlevisibility = () => {
    if (visible) {
      setvisibile(false);
    }
    if (!visible) {
      setvisibile(true);
    }
    seticon((prev) => !prev);
  };
  console.log(selectedBeneDetails);
  return (
    <div>
      <Grid container className={classes.header} size={{xs:12}}>
        <Grid size={{xs:12}} className={classes.headerDetails}>
          <Typography variant="body1"> Enter Beneficiary Details</Typography>{" "}
          {!icon && <RemoveCircleRoundedIcon onClick={handlevisibility} />}
          {icon && <AddCircleRoundedIcon onClick={handlevisibility} />}
        </Grid>
      </Grid>
      {visible && (
        <>
          <Box className={classes.space}>
            <Grid className={classes.flex} size={{xs:12}}>
              <Typography variant="body2" style={{ fontWeight: "600" }}>
                Pay to Country : {selectedBeneDetails?.Country}{" "}
              </Typography>
              <CurrencyFlag
                currency={flagsCode[selectedBeneDetails.Country]}
                style={{
                  marginLeft: "5px",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50px",
                }}
              />
              <EditIcon
                fontSize="medium"
                className={classes.editIcon}
                onClick={handleEdit}
              />
            </Grid>
          </Box>
          <Box className={classes.space}>
            <Typography variant="body2" style={{ fontWeight: "600" }}>
              Nickname (Optional)
            </Typography>
            {/* <TextField
              variant="outlined"
              size="small"
              className={classes.textfield}
              value={selectedBeneDetails?.FullName}
            /> */}
            {isEditable ? (
            <TextField
              variant="outlined"
              size="small"
              // className={classes.textfield}
              slotProps={{
                style: { fontSize: "13px", }
              }} 
              value={selectedBeneDetails?.FullName}
              onChange={handleInputChange}
              name="NickName"
            />
          ) : selectedBeneDetails?.FullName ? (
            <span style= { {fontSize: "13px"} }>{selectedBeneDetails?.FullName}</span>
          ) : (
            "---"
          )}
          </Box>
          <Grid container md={12} xs={12} className={classes.space}>
            <Grid item xs={12} md={6} /* className={classes.spacing} */>
              <Typography variant="body2" style={{ fontWeight: "600" }}>
                Beneficiary Name
              </Typography>
             {/*  <TextField
                variant="outlined"
                size="small"
                className={classes.textfield}
                value={selectedBeneDetails?.FullName}
              /> */}
              {isEditable ? (
                <TextField
                  variant="outlined"
                  size="small"
                  // className={classes.textfield}
                  slotProps={{
                    style: { fontSize: "13px",/* height:"4vh",width:"30vw" */ }
                  }}
                  value={selectedBeneDetails?.FullName}
                  onChange={handleInputChange}
                  name="NickName"
                />
              ) : selectedBeneDetails?.FullName ? (
                <span style= { {fontSize: "13px"} }>{selectedBeneDetails?.FullName}</span>
              ) : (
                "---"
              )}
            </Grid>
            <Grid size={{xs:12,md:6}}  className={classes.spacing} >
              <Typography variant="body2" style={{ fontWeight: "600",marginLeft:"-9px" }}>
                Account Number
              </Typography>
              {/* <TextField
                variant="outlined"
                size="small"
                className={classes.textfield}
                value={selectedBeneDetails?.CdtrAcctId}
              /> */}
              {isEditable ? (
                <TextField
                  variant="outlined"
                  size="small"
                  // className={classes.textfield}
                  slotProps={{
                    style: { fontSize: "13px",/* height:"4vh",width:"30vw" */marginLeft:"-9px" }
                  }}
                  value={selectedBeneDetails?.CdtrAcctId}
                  onChange={handleInputChange}
                  name="NickName"
                />
              ) : selectedBeneDetails?.CdtrAcctId? (
                <span style= { {fontSize: "13px",marginLeft:"-9px"} }>{selectedBeneDetails?.CdtrAcctId}</span>
              ) : (
                "---"
              )}
            </Grid>
          </Grid>
          <Box className={classes.space}>
            <Box className={classes.flex}>
              <InfoOutlinedIcon style={{ color: "blue", fontSize: "1.2rem" }} />
              <Typography variant="body2" style={{ marginLeft: "5px" }}>
                Enter BIC Code or ABA Number
              </Typography>
            </Box>
          </Box>

          <Grid item md={6} xs={12} className={classes.space}>
            <Typography variant="body2" style={{ fontWeight: "600" }}>
              Beneficiary Bank BIC Code
            </Typography>
{/*             <TextField
              variant="outlined"
              size="small"
              className={classes.textfield}
              value={selectedBeneDetails?.BICFI}
            /> */}

                {isEditable ? (
                <TextField
                  variant="outlined"
                  size="small"
                  // className={classes.textfield}
                  slotProps={{
                    style: { fontSize: "13px",/* height:"4vh",width:"30vw" */ }
                  }}
                  value={selectedBeneDetails?.BICFI}
                  onChange={handleInputChange}
                  name="NickName"
                />
              ) : selectedBeneDetails?.BICFI? (
                <span style= { {fontSize: "13px",} }>{selectedBeneDetails?.BICFI}</span>
              ) : (
                "---"
              )}
          </Grid>
          <Grid item md={6} xs={12} className={classes.space}>
            <Typography variant="body2" style={{ fontWeight: "600" }}>
              ABA Routing Number
            </Typography>
            {/* <TextField
              variant="outlined"
              size="small"
              className={classes.textfield}
              disabled
            ></TextField> */}
            {isEditable ? (
            <TextField
              variant="outlined"
              size="small"
              //className={classes.textfield}

              disabled={isEditable}
            />
          ) : (
            "--"
          )}
          </Grid>

          <Box className={classes.space}>
            <Typography variant="body2" style={{ fontWeight: "600" }}>
              Beneficiary Bank Address
            </Typography>
            <Typography style={{ fontSize: "13px" }}>
              {selectedBeneDetails?.BeneBankAddress}
            </Typography>
          </Box>
        </>
      )}
      <Box className={classes.space}>
        <Typography variant="body2" style={{ fontWeight: "600" }}>
          Validation Message
        </Typography>
        <Typography style={{ fontSize: "13px" }}>
          {" "}
          Unable to provide account validation for this transaction. Ensure you
          have valid beneficiary details.
        </Typography>
      </Box>
      {/* <Grid>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel style={{ margin: "10px 0 0px 10px" }}>
            Select Pay Purpose:{" "}
          </InputLabel>
          <Select
          variant="standard"
            id="PurposeCode"
            value={purposeCode}
            onChange={handlePurposeCode}
            inputProps={{ className: classes.acctInput }}
            label="Select Pay Purpose"
            disableUnderline
            displayEmpty
          >
            {purposeCodeList &&
              purposeCodeList.map((pCode) => (
                <MenuItem
                  key={pCode.PayPurPCodeDesc}
                  value={pCode?.PayPurPCodeDesc}
                >
                  {pCode?.PayPurPCode}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid> */}
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.spacing}
      >
        <Box className={classes.flex}>
          <Button className={classes.cancel}>Cancel</Button>
          <Button
            onClick={handlePrevious}
            className={classes.cancel}
            style={{ marginLeft: "5px" }}
          >
            Back
          </Button>
        </Box>
        <Grid item>
          {!flag && (
            <Button
              className={classes.continue}
              onClick={handleValidateAndSave}
              style={{marginLeft:"3px"}}
            >
              Validate And Save
            </Button>
          )}
          <Button className={classes.continue} onClick={handleContinue} style={{marginLeft:"2px"}}>
            Continue
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default ReviewBeneficiary;
