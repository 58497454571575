import React, { useState } from "react";
import { getAuthorizeUrl } from "../Authorization/Utils/oauth";
import "./style.css";
import logo from "../../assets/images/EPP_withcode.svg";
import { Capacitor } from "@capacitor/core";
import { Box, Button, Container, TextField } from "@mui/material";

const Login = () => {
  
  const [state, setState] = useState({
    userName: "",
    password: "",
  });
  const isMobile = Capacitor.isNativePlatform();


  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    const userIdentifier = state.userName;
    const password = state.password;
    window.location.href = getAuthorizeUrl(userIdentifier, password,isMobile);
  };
  return (
    <>
      <div className="body">
        <Container
          maxWidth="xs"
          className="container"
        >
          <Box  className="logo">
            <img src={logo} alt="Logo" width="136" />
          </Box>

          <Box component="form" onSubmit={handleSubmit} className="feildsmargin">
            <TextField
              fullWidth
              label="Username"
              type="text"
              name="userName"
              id="login-userName"
              placeholder="Enter Your Username"
              value={state.userName}
              onChange={handleChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Password"
              type="password"
              name="password"
              id="login-password"
              placeholder="Enter Your Password"
              value={state.password}
              onChange={handleChange}
              margin="normal"
            />

            <Button
              variant="contained"
              fullWidth
              type="submit"
              className="button"
            >
              LOGIN
            </Button>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default Login;
