import { makeStyles } from "@mui/styles";
// const drawerWidth = "22%";

const useStyles = makeStyles((theme) => ({
  accountCard2: {
    borderRadius: '20px !important',
    marginTop: 'inherit !important'
  },
  acContent2: {
    padding: '0px !important'
  },
  qBContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 10px 0px 10px'
  },
  quickBtn: {
    padding: '4px 16px !important',
    backgroundColor: '#52459f !important',
    color: '#fff',
    borderRadius: '30px !important',
    boxShadow: 'none !important',
    fontSize: '14px !important',
    textTransform: 'none !important',
    '&:hover': {
      backgroundColor: '#014c92 !important'
    }
  },
  a_heading: {
    fontSize: '15px !important',
    fontWeight: 'bold !important',
    padding: '16px 12px !important',
    marginTop: "2% !important"
  },
  acInfo: {
    color: '#909090',
    fontSize: '14px',
    margin: '10px 0px !important'
  },
  details: {
    marginBottom: '10px !important',
    paddingLeft: '16px !important'
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  subDetails: {
    paddingBottom: '10px',
    borderTop: '1px solid #ddd',
    paddingLeft: '16px'
  },
  headerTitle: {
    color: '#51459E',
    fontWeight: 'bold !important',
    textDecoration: 'inherit !important',
    textTransform: 'none !important',
    fontFamily: 'OpenSans, sans-serif !important',
    fontSize: '16px !important',
    lineHeight: '1 !important',
    paddingLeft: '20px !important',
    marginBottom: '10px !important',
    marginTop: '16px !important',
  },
  accountgrid:{
    maxWidth:"100%",
    marginLeft:"1%",
    //padding:"15px"
    position:"relative",
    top:"-1.5%",
  },
}));

export default useStyles;
