import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAccessToken, setAccessToken, setRefreshToken,fetchUserInfo,setUserName} from '../Services/authService';

const Callback = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const fetchToken = async () => {
            const query = new URLSearchParams(window.location.search);
            const code = query.get('code');

            if (code) {
                try {
                    const data = await fetchAccessToken(code);
                    setAccessToken(data.access_token);
                    const userInfo = await fetchUserInfo(data.access_token);
                    setUserName(userInfo)                   
                    setRefreshToken(data.refresh_token);

                    window.handleOAuthToken(data.access_token);
                    //window.location.href = '/'; // Redirect to home or another page
                    navigate('/');
                } catch (error) {
                    console.error('Error during token exchange:', error);
                }
            }
        };

        fetchToken();
    }, [navigate]);

    return <div>Loading...</div>;
};

export default Callback;
