import React from "react";
import Usa from "./CountrySpecificFields/Usa";
import Japan from "./CountrySpecificFields/Japan";
import Qatar from "./CountrySpecificFields/Qatar";
import Europe from "./CountrySpecificFields/Europe";
import Australia from "./CountrySpecificFields/Australia";
import Bangladesh from "./CountrySpecificFields/Bangladesh";
import Brazil from "./CountrySpecificFields/Brazil";
import Canada from "./CountrySpecificFields/Canada";
import China from "./CountrySpecificFields/China";
import Ghana from "./CountrySpecificFields/Ghana";
import HongKong from "./CountrySpecificFields/HongKong";
import India from "./CountrySpecificFields/India";
import Malaysia from "./CountrySpecificFields/Malaysia";
import Mexico from "./CountrySpecificFields/Mexico";
import Morocco from "./CountrySpecificFields/Morocco";
import Philippines from "./CountrySpecificFields/Philippines";
import Poland from "./CountrySpecificFields/Poland";
import Singapore from "./CountrySpecificFields/Singapore";
import SouthAfrica from "./CountrySpecificFields/SouthAfrica";
import SriLanka from "./CountrySpecificFields/SriLanka";
import UnitedArabEmirates from "./CountrySpecificFields/UnitedArabEmirates";
import Argentina from "./CountrySpecificFields/Argentina";
import Bulgaria from "./CountrySpecificFields/Bulgaria";
import CzechRepublic from "./CountrySpecificFields/CzechRepublic";
import Denmark from "./CountrySpecificFields/Denmark";
import Egypt from "./CountrySpecificFields/Egypt";
import Georgia from "./CountrySpecificFields/Georgia";
import Guatemala from "./CountrySpecificFields/Guatemala";
import Hungary from "./CountrySpecificFields/Hungary";
import Israel from "./CountrySpecificFields/Israel";
import Liechtenstein from "./CountrySpecificFields/Liechtenstein";
import Luxembourg from "./CountrySpecificFields/Luxembourg";
import Malta from "./CountrySpecificFields/Malta";
import Netherlands from "./CountrySpecificFields/Netherlands";
import Norway from "./CountrySpecificFields/Norway";
import Sweden from "./CountrySpecificFields/Sweden";
import Switzerland from "./CountrySpecificFields/Switzerland";
import Turkey from "./CountrySpecificFields/Turkey";


const CountrySpecificComponent = (props) => {
  const {
    editableBeneDetails,
    payAgain,
    isEditable,
    review,
    reviewDetails,
    handleInputChange,
    flag,
    repeatPayment,
  } = props;

  const countryName = review ? reviewDetails.ReceiversCountryName : editableBeneDetails.Country;

  return (
    <>
      {countryName === "United States" && (
        <Usa
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Japan" && (
        <Japan
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Qatar" && (
        <Qatar
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Europe" && (
        <Europe
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Australia" && (
        <Australia
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Bangladesh" && (
        <Bangladesh
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Brazil" && (
        <Brazil
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Bangladesh" && (
        <Bangladesh
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Canada" && (
        <Canada
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "China" && (
        <China
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Ghana" && (
        <Ghana
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "HongKong" && (
        <HongKong
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "India" && (
        <India
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      
      {countryName === "Malaysia" && (
        <Malaysia
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Mexico" && (
        <Mexico
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Morocco" && (
        <Morocco
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Philippines" && (
        <Philippines
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Poland" && (
        <Poland
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Singapore" && (
        <Singapore
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "SouthAfrica" && (
        <SouthAfrica
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "SriLanka" && (
        <SriLanka
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "UnitedArabEmirates" && (
        <UnitedArabEmirates
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Argentina" && (
        <Argentina
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Bulgaria" && (
        <Bulgaria
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "CzechRepublic" && (
        <CzechRepublic
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Denmark" && (
        <Denmark
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Egypt" && (
        <Egypt
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Georgia" && (
        <Georgia
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Guatemala" && (
        <Guatemala
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Hungary" && (
        <Hungary
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Israel" && (
        <Israel
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Liechtenstein" && (
        <Liechtenstein
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Luxembourg" && (
        <Luxembourg
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Malta" && (
        <Malta
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Netherlands" && (
        <Netherlands
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Norway" && (
        <Norway
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Sweden" && (
        <Sweden
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Switzerland" && (
        <Switzerland
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
      {countryName === "Turkey" && (
        <Turkey
          editableBeneDetails={editableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgain={payAgain}
          repeatPayment={repeatPayment}
        />
      )}
    </>
  );
};

export default CountrySpecificComponent;
