import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex !important ",
    justifyContent: "flex-end !important ",
  },
  btn: {
    backgroundColor: "#52459f !important ",
    fontSize: "14px !important",
    color: "#fff !important",
    padding: "4px 16px !important",
    textTransform: "none !important",
    cursor: "pointer !important",
    border: "none !important",
    height: "35px !important",
    borderRadius: "20px !important",
    
    "&:hover": {
      backgroundColor: "#fff !important",
      color: "#52459f !important",
    },
  },
  txt: {
    fontSize: "13px !important ",
    fontWeight: "bold !important ",
  },
    padding:'10px',
  txt2: {
    fontSize: "16px !important",
    fontWeight: "bold !important",
  },
  accountCard: {
    border: "1px solid #DDDDDD !important ",
    borderRadius: "16px !important",
    paddingBottom: "20px !important",
    "&:hover": {
      backgroundColor: "#51459e20 !important", // Hover effect for Card
    },
  },
  accountCard2: {
    borderRadius: "20px !important ",
  },
  acContent: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    backgroundColor: "transparent !important", // Ensure no conflicting background color
    padding: "20px 20px !important",
  },
  acContent2: {
    padding: "0px !important",
  },
  qBContainer: {
    display: "flex !important",
    justifyContent: "flex-end !important",
    padding: "10px 10px 0px 10px !important",
  },
  quickBtn: {
    padding: "4px 16px !important ",
    backgroundColor: "#52459f !important ",
    color: "#fff",
    borderRadius: "30px !important ",
    boxShadow: "none !important",
    fontSize: "14px ",
    textTransform: "none !important ",
    "&:hover": {
      backgroundColor: "#014c92",
    },
  },
  a_heading: {
    background:'#52459f',
    color:'#fff',
    padding:'10px',
    marginBottom: "16px !important",
    fontSize: "14px !important ",
    fontWeight: "bold !important ",
  },
  acInfo: {
    color: "#909090 !important",
    fontSize: "14px !important",
    margin: "10px 0px !important ",
  },
  details: {
    marginBottom: "10px !important ",
    paddingLeft: "16px !important ",
  },
  detailsContainer: {
    display: "flex !important",
    flexDirection: "column !important",
    justifyContent: "flex-start !important",
  },
  subDetails: {
    paddingBottom: "10px !important",
    borderTop: "1px solid #ddd !important",
    paddingLeft: "16px !important",
  },
}));

export default useStyles;
