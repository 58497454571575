import React from "react";
import useStyles from "./styles";
import rewards from "../../../assets/images/badge-reward-svgrepo-com.svg";
import budget from "../../../assets/images/money-svgrepo-com.svg";
import { Outlet } from "react-router-dom";
import { Button, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

const Dataview = (props) => {
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid size={{ xs: 12 }} className={classes.root}>
          <Grid container className={classes.rootContainer}>
            <Grid size={{ xs: 8.5 }} className={classes.outletContainer}>
              <Outlet />
            </Grid>
            <Grid size={{ xs: 2 }} className={classes.rightMainContainer}>
              <Grid container>
                <Grid
                  size={{ md: 12, xs: 12 }}
                  className={classes.rightSubContainer}
                >
                  <div className={classes.rewardBudgetContainer}>
                    <img src={rewards} alt="rewardsImg" width="40%" />
                    <div className={classes.flexColumn}>
                      <Typography variant="body1" className={classes.heading}>
                        Preferred Rewards
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.subHeading}
                      >
                        Platinum
                      </Typography>
                    </div>
                  </div>
                  <Button variant="contained" className={classes.viewBtn}>
                    View my rewards
                  </Button>
                </Grid>

                <Divider className={classes.divider} />

                <Grid
                  size={{ md: 12, xs: 12 }}
                  className={classes.rightSubContainer}
                >
                  <div className={classes.rewardBudgetContainer}>
                    <img src={budget} alt="budgetimg" width="40%" />
                    <Typography variant="body1" className={classes.heading}>
                      Spending & Budgeting
                    </Typography>
                  </div>
                  <Button variant="contained" className={classes.viewBtn}>
                    Track Spending
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Dataview;
