import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  
  flexCenter: {
    display: "flex !important ",
    justifyContent: "center !important ",
    alignItems: "center !important ",
  },
  drawerPaper: {
    bottom: "79px",
    height: "calc(100vh - 108px)",
    backgroundColor: "#52459f !important ",
    color: "#fff",
    padding: "16px",
    borderRadius: '65px',
    top:"56px",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  middleContainer: {
    height: "calc(100vh - 134px)",
    overflowX: "hidden",
    overflowY: "scroll",
    scrollbarWidth: "none",
    borderBottomLeftRadius:'65px',
    borderBottomRightRadius:'65px'
  },
  footerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#52459f",
    padding: "12px",
    zIndex:"9999",
  },
  footerIcons: {
    color: "#fff",
    cursor: "pointer",
    fontSize: "35px",
    width: "35px !important",
    height: "35px !important",
  },
  contentContainer: {
    background: "#f5f7ff",
  },
  mainMenu: {
    display: "flex",
    borderBottom: "2px #aca4df80 dotted",
    padding:'0px'
  },
  userName: {
    "& span": {
      fontWeight: "bold",
    },
  },
  linkStyle: {
    color: "#fff",
    textDecoration: "none",
    fontSize: "14px",
    fontWeight: "bold",
    "&:hover": {
      fontSize: "14.5px !important",
    },
  },
  linkIcons: {
    color: "#fff",
    textDecoration: "none",
    fontSize: "14px",
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
  },
  txt: {
    "& span": {
      fontSize: "14px !important",
      fontWeight: "bold",
      cursor: "pointer",
      "&:hover": {
        fontSize: "14.5px !important",
      },
    },
  },
  logoutBtn: {
    backgroundColor: "#fff !important ",
    color: "#52459f !important",
    fontSize: "15px !important",
    fontWeight: "bold !important",
    padding: "5px 50px 5px 50px !important",
    cursor: "pointer !important",
    border: "none !important",
    borderRadius: "20px !important",
    marginTop: "5px !important",
    textTransform: "none !important",
    "& :hover": {
      backgroundColor: "#52459f !important",
      color: "#fff !important",
      border: "1px solid #ffff !important",
    },
  },
  nes1: {
    // marginLeft: "10px",
    paddingRight: "10px",
  },
  closeIcon: {
    width: "23px",
  },
  menuIcon: {
    minWidth: "10px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom:'0px !important'
  },
  menuBullet: {
    color: "#fff",
    fontSize: "10px !important",
  },
  nestedList: {
    color: "#fff",
    display: "flex",
    borderBottom: "2px #aca4df80 dotted",
  },
}));

export default useStyles;
