import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import React from "react";
import ProtectedRoute from "./config/protectedRoute";
import { Capacitor } from "@capacitor/core";
import Navigation from "./components/WebApp/Navigation/index";
import MobileNavigation from "./components/Mobile/MobileNavigation";
import Loader from "./components/Loader";
import './global';

import Dataview from "./components/WebApp/DataView";
import Home from "./components/WebApp/Home";
import Accounts from "./components/WebApp/Accounts";
import RecentPayments from "./components/WebApp/RecentPayments";
import Login from "./components/Login/Login";
import Callback from "./components/Authorization/OAuth/Callback";

import MobileDataview from "./components/Mobile/MobileDataView";
import MobileHome from "./components/Mobile/MobileHome";
import MobileAccounts from "./components/Mobile/MobileAccounts";
import MobileRecentPayments from "./components/Mobile/MobileRecentPayments";
import AddModifyBeneficiary from "./components/WebApp/AddModifyBeneficiary";
import MobileAddModifyBeneficiary from "./components/Mobile/MobileAddModifyBeneficiary";
import MobileSendPayment from "./components/Mobile/MobileSendPayment";
import SendPayment from "./components/WebApp/SendPayment";
import PayAgain from "./components/WebApp/PayAgain";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme()

function App() {

  const isMobile = Capacitor.isNativePlatform();
  //const isMobile = true;

  return (
    <>
    <ThemeProvider theme={theme}>
      {isMobile ? (
        <>
          <Router>
            <NavigationWrapper isMobile={isMobile} />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/callback" element={<Callback />} />
              <Route
                path="/"
                element={<ProtectedRoute element={<MobileDataview />} />}
              >
                <Route
                  index
                  element={<ProtectedRoute element={<MobileHome />} />}
                />
                <Route
                  path="accounts"
                  element={<ProtectedRoute element={<MobileAccounts />} />}
                />
                <Route
                  path="recentpayments"
                  element={
                    <ProtectedRoute element={<MobileRecentPayments />} />
                  }
                />
               <Route
                  path="addmodifybeneficiary"
                  element={
                    <ProtectedRoute element={<MobileAddModifyBeneficiary />} />
                  }
                />
                 <Route
                  path="sendpayment"
                  element={
                    <ProtectedRoute element={<MobileSendPayment />} />
                  }
                />
              </Route>
            </Routes>
          </Router>
        </>
      ) : (
        <>
          <Router>
            <NavigationWrapper isMobile={isMobile} />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/callback" element={<Callback />} />
              <Route
                path="/"
                element={<ProtectedRoute element={<Dataview />} />}
              >
                <Route index element={<ProtectedRoute element={<Home />} />} />
                <Route
                  path="accounts"
                  element={<ProtectedRoute element={<Accounts />} />}
                />
                <Route
                  path="recentpayments"
                  element={<ProtectedRoute element={<RecentPayments />} />}
                />
                 <Route
                  path="addmodifybeneficiary"
                  element={
                    <ProtectedRoute element={<AddModifyBeneficiary />} />
                  }
                />
                 <Route
                  path="sendpayment"
                  element={
                    <ProtectedRoute element={<SendPayment />} />
                  }
                />
                 <Route
                  path="payagain"
                  element={
                    <ProtectedRoute element={<PayAgain />} />
                  }
                />
              </Route>
            </Routes>
          </Router>
        </>
      )}
      </ThemeProvider>
    </>
  );
}

function NavigationWrapper( {isMobile}) {
  const location = useLocation();
  const shouldShowNavigation = !location.pathname.startsWith("/login");

  return <>{shouldShowNavigation && (isMobile ? <MobileNavigation/> : <Navigation/>)}</>;
}
export default App;
