import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import axios from "axios";
import endPoints from "../../../config/Api";
import { getAccessToken } from "../../Authorization/Services/authService";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Card, CardContent, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Modalpopup from "./Modal";

const AddModifyBeneficiary = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [searchText,setSearchText] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [Row,setRow] = useState(null)
  const [modelOpen, setmodelOpen] = useState(false)

  const fetchData = () => {
    const token = getAccessToken();
    axios
      .post(
        `${endPoints.baseUrl}/data_views/${endPoints.addModifyBeneficiary.D_Page_Name}`,
        {
          dataViewParameters: {},
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setData(data.data.data);
        setSearchData(data.data.data);
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        navigate("/login");
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const searchHandler=(event)=>{
    const searchValue = event.target.value;
    setSearchText(searchValue)
    if (searchValue === '') {
      fetchData();  
    }
    const SearchedData = searchData.filter((row) =>
      row.FullName && row.FullName.toLowerCase().includes(searchValue?.toLowerCase())
      );
    setData(SearchedData);
    setPage(0);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function CustomTablePagination({ count, page, rowsPerPage, onPageChange }) {
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeftIcon />
        </IconButton>

        {/* Page count in the center */}
        <Typography variant="body2" sx={{ margin: "0 16px" }}>
          {`${page * rowsPerPage + 1}-${Math.min(
            count,
            (page + 1) * rowsPerPage
          )} of ${count}`}
        </Typography>

        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    );
  }

  const colors = [
    "#0b8953",
    "#c23824",
    "#2c97de",
    "#00657c",
    " #00aa66",
    "#667700",
    "#e27100",
    "#52459f",
  ];

  const getInitials = (name) => {
    if (!name) return "";
    const nameArray = name.trim().split(/\s+/);

    return nameArray.length > 1
      ? `${nameArray[0][0]}${nameArray[1][0]}`.toUpperCase()
      : name.substring(0, 1).toUpperCase();
  };

  const getColor = (initials) => {
    if (!initials) return colors[0]; // Default color

    const firstChar = initials[0].toUpperCase();

    if (firstChar >= "A" && firstChar <= "Z") {
      const charCode = firstChar.charCodeAt(0) - "A".charCodeAt(0);
      return colors[Math.floor(charCode / 3) % colors.length];
    } else if (firstChar >= "0" && firstChar <= "9") {
      const num = parseInt(firstChar, 10);
      return colors[Math.floor(num / 3) % colors.length];
    }

    return colors[0]; // Default color for any other characters
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

const handlepop=(data)=>{
  setRow(data)
  setmodelOpen(true)
  console.log(data)
}
 
const handleCloseModal = ()=>{
  setmodelOpen(false)
  //setRow(null)
}
  return (
    <>
      <Grid container  style={{ padding: "10px" }}>
        {/* Header */}
        <Grid
            size={{xs:12}}
            style={{paddingBottom:'10px', borderBottom:'1px solid #c7c2c2'}}
          >
            <h2 style={{ margin: 0, fontSize: "18px", color:'#52459f' }}>Add/Modify Beneficiary</h2>
          </Grid>
        <Grid
          size={{xs:12, md:12}} style={{marginTop:'20px'}}
        >
          <Grid
            size={{xs:12, md:12}}
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <input
              placeholder="Search for beneficiary by name"
              className={classes.inputInput}
              onChange={searchHandler}
              value={searchText}
            />{" "}
          </Grid>
        </Grid>
        <Grid size={{xs:12,md:12}}>
          <div style={{ display: "flex", gap: "10px" ,margin: "20px 0px" }}>
            <Typography variant="h6" className={classes.addtxt}>
              Add New Beneficiary
            </Typography>
            <AddCircleIcon className={classes.circleIcon} />
          </div>
        </Grid>
        <Grid size={{xs:12, md:12}} style={{ padding: "2px",}}>
          <Typography variant="body1" className={classes.b_heading}>
            Available Beneficiaries
          </Typography>
        </Grid>
        <Grid size={{xs:12, md:12}}>
          <Grid container spacing={1}>
            <Grid size={{xs:12, md:6}} style={{padding:"10px"}}>
              {data
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row) => {
                  const initials = getInitials(row.FullName);
                  const backgroundColor = getColor(initials);
                  return (
                      <Card className={classes.accountCard}>
                        <CardContent
                          key={row.pxRefObjectInsName || row.pyID}
                          className={classes.acContent}
                        >
                          <div style={{ display: "flex", width: "80%" }}>
                            <Avatar
                              style={{ backgroundColor }}
                              className={classes.avatar}
                            >
                              {initials}
                            </Avatar>
                            <div className={classes.infoContainer}>
                              <Typography
                                variant="body1"
                                style={{
                                  color: "#222",
                                  fontWeight: "600",
                                  fontSize: "14px",
                                }}
                              >
                                {row.FullName}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{
                                  color: "#52459f",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                }}
                              >
                                A/C No:{" "}
                                {row.MaskAccNumb
                                  ? row.MaskAccNumb
                                  : row.MaskIBANNumb}
                              </Typography>
                            </div>
                          </div>
                          <div className={classes.iconContainer}>
                            <LaunchOutlinedIcon
                              fontSize="medium"
                              style={{
                                color: "#52459f",
                                marginRight: "14px",
                                cursor: "pointer",
                                
                              }}
                              onClick={()=>handlepop(row)}
                            />
                            <DeleteOutlineOutlinedIcon
                              fontSize="medium"
                              style={{ color: "#52459f", cursor: "pointer" }}
                            />
                          </div>
                          {/* <div className={classes.iconContainer}>
                            <IconButton onClick={handleClick}>
                              <MoreVert
                                fontSize="medium"
                                style={{ color: "#5649a1" }}
                              />
                            </IconButton>
                          </div>
                          <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                              horizontal: "center",
                              vertical: "bottom",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem onClick={handleClose}>Edit</MenuItem>
                            <MenuItem onClick={handleClose}>Delete</MenuItem>
                          </Menu> */}
                        </CardContent>
                      </Card>
                  );
                })}
              {data && data.length > 0 && (
                <CustomTablePagination
                  count={data.length}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modalpopup open={modelOpen} close={handleCloseModal} rowdata = {Row}/>
    </>
  );
};

export default AddModifyBeneficiary;
