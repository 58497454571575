import {
  TOKEN_URL,
  CLIENT_ID,
  CLIENT_SECRET,
  REDIRECT_URI,
  REDIRECT_URI_MOBILE,
} from "../Utils/oauth";
import axios from "axios";
import { Preferences } from "@capacitor/preferences";
import { Capacitor } from "@capacitor/core";
import { useNavigate } from "react-router-dom";

const isMobile = Capacitor.isNativePlatform();
  //const isMobile = true;
  
export const fetchAccessToken = async (code) => {
  try {
    const response = await axios.post(
      TOKEN_URL,
      new URLSearchParams({
        grant_type: "authorization_code",
        code,
        redirect_uri: isMobile ? REDIRECT_URI_MOBILE : REDIRECT_URI,
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
   if(isMobile){
    await Preferences.set({
      key: "accessToken",
      value: response.data.access_token,
    });
  }
    return response.data;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
};

export const fetchUserInfo = async (accessToken) => {
  console.log(accessToken);
  try {
    const response = await axios.get(
      'https://eppdev.evonsys.com.au/prweb/PRRestService/oauthclients/v1/userinfo/json',
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    
    return response.data;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
};

export const getAccessToken = () => {
  if(isMobile){
    const {value} = Preferences.get({key:"accessToken"})
    return value;
  }else{
  return localStorage.getItem("accessToken");
  }
};

export const setAccessToken = (token) => {
  if(isMobile){
    Preferences.set({
      key:"accessToken",
      value:token
    })
  } else {
  localStorage.setItem("accessToken", token);
  }

};

export const setUserName = (userInfo) => {

  if(isMobile){
    Preferences.set({
      key:"userName",
      value:userInfo.name
    })
  } else {
    localStorage.setItem("userName", userInfo.name);
  }
}

export const getUserName = () => {
  if(isMobile){
    const {value} = Preferences.get({key:"userName"})
    return value
  } else {
   return localStorage.getItem("userName");
  }
};

export const getReefreshToken = () => {
  return localStorage.getItem("refreshToken");
};

export const setRefreshToken = (token) => {
  localStorage.setItem("refreshToken", token);
};

export const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");

  if (!refreshToken) return null;
  const params = new URLSearchParams();
  params.append("client_secret", CLIENT_SECRET);
  params.append("client_id", CLIENT_ID);
  params.append("grant_type", "refresh_token");
  params.append("refresh_token", refreshToken);

  const response = await fetch(TOKEN_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: params,
  });

  console.log(response);

  if (response.ok) {
    const data = await response.json();
    setAccessToken(data.access_token);
    return data.access_token;
  } else {
    // Handle error (e.g., logout user)
    Logout();
  }
};

export const Logout = () => {
  const navigate = useNavigate();
  localStorage.clear();
  navigate("/login");
};

export const isTokenExpired = (token) => {
  if (!token) return true;
  const payload = JSON.parse(atob(token.split(".")[1]));
  const exp = payload.exp * 1000; // Convert to milliseconds
  return Date.now() >= exp;
};
