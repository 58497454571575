import { makeStyles } from "@mui/styles";
import bgImage from "../../../assets/images/bgimg.png";
import bgImageAccounts from "../../../assets/images/bgimg_beni.png";

const drawerWidth = "22%";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    width: "100vw",
  },
  rootContainer: {
    justifyContent: "space-evenly",
    marginTop: "35px !important",
  },
  outletContainer:{
    marginLeft: "28px !important"
  },

  //Right side container stylings -- Begin
  rightMainContainer: {
    height: "140vh",
    backgroundSize: "cover",
    borderRadius: "30px 30px 0 0 !important",
    padding: "20px !important",
    backgroundImage: `url(${bgImageAccounts})`,
  },
  rightSubContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100% !important",
    maxWidth: "200px !important",
    height: "auto !important",
  },
  rewardBudgetContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  heading: {
    color: "#ffff",
    fontWeight: "bold !important",
    marginLeft: "10px !important",
    fontSize: "1.17em !important",
  },
  subHeading: {
    marginTop: "10px !important",
    marginLeft: "10px !important",
    color: "#fff",
    fontSize: "0.83em !important",
    fontWeight: "bold !important",
  },
  viewBtn: {
    backgroundColor: "#ffff !important",
    color: "#52459f !important",
    borderRadius: "67px !important",
    textTransform: "none !important",
    padding: "5px 20px 5px 20px !important",
    fontSize: "12px !important",
    fontWeight: "bold !important",
    marginTop: "10px !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "#fff !important",
      border: "1px solid #fff !important",
    },
  },
  divider: {
    width: "100% !important",
    border: "1px #ffffff80 solid  !important",
    margin: "20px 0px !important",
  },
}));

export default useStyles;
