import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  /* Search bar */
  searchBar: {
    margin: "20px 0  ",
  },

  inputInput: {
    width: "86% !important ",
    padding: "12px !important",
    border: "1px solid #dcdcdc",
    borderRadius: "15px",
    fontSize: "12px",
    // background: "#f5f7ff",
    "&:focus": {
      border: "1px solid #dcdcdc",
      outline: "none",
    },
  },
  searchIcon: {
    position: "absolute",
    right: "60px", // Adjust to align icon properly
    top: "50%",
    transform: "translateY(-50%)",
    color: "#52459f",
  },
  closeIcon: {
    position: "absolute",
    right: "35px", // Adjust to align icon properly
    top: "50%",
    transform: "translateY(-50%)",
    color: "#52459f",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "end",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center", // Center button container on small screens
    },
  },
  accountCard: {
    border: "1px solid #DDDDDD",
    borderRadius: "16px !important ",
    marginTop:'10px !important ',
    //width:"86vw !important "
  },
  acContent: {
    display: "flex",
    justifyContent: "space-between !important",
    alignItems: "center",
    backgroundColor: "transparent", // Ensure no conflicting background color
    padding: "24px 16px !important",
    // "& .MuiCardContent-root:last-child ": { paddingBottom: 0 },
  "&:hover": {
      backgroundColor: "#51459e20 !important", // Hover effect for Card
    borderRadius: "16px",
    },
  },
  cell: {
    whiteSpace: "nowrap",
    color: "#52459f",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px", // Adjust font size for mobile screens
    },
  },
  title: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    color: "#52459f",
    fontSize: "16px",
    fontWeight: "bold",
    borderBottom: "1.4px solid #dddd",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px", // Adjust font size for mobile screens
    },
  },
  sendBtn: {
    backgroundColor: "#52459f !important",
    color: "#fff",
    fontSize: "0.9em",
    padding: "10px 25px",
    borderRadius: "30px",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "#52459f",
      padding: "8px 23px",
      border: "2px solid #52459f",
      boxShadow: "none",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8em", // Adjust font size for mobile screens
      padding: "8px 20px", // Adjust padding for mobile screens
    },
  },
  RequestBtn: {
    padding: "10px 25px",
    borderRadius: "30px",
    backgroundColor: "transparent",
    fontSize: "0.9em",
    color: "#52459f",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#52459f",
      color: "#fff",
      boxShadow: "none",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8em", // Adjust font size for mobile screens
      padding: "8px 20px", // Adjust padding for mobile screens
    },
  },
  image: {
    width: "20px",
    height: "20px",
    marginLeft: "25px",
    [theme.breakpoints.down("xs")]: {
      width: "16px", // Adjust image size for mobile screens
      height: "16px", // Adjust image size for mobile screens
      marginLeft: "10px", // Adjust margin for mobile screens
    },
  },
  avatar: {
    marginRight: "15px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "10px", // Adjust margin for mobile screens
    },
  },
  flag: {
    marginTop: "-1px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px", // Adjust margin for mobile screens
    },
  },
  tableInForm: {
    marginTop: "16px",
    minWidth: 750,
    maxHeight: 550,
    overflow: "auto",
    background: "transparent",
    scrollbarWidth: "thin",
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%", // Full width on small screens
      maxHeight: "400px", // Adjust height for mobile screens
    },
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff !important",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#efefef !important",
    },
    "& .MuiTableCell-root": {
      padding: "8px 8px 8px 16px",
      height: "50px",
      border: "none",
      [theme.breakpoints.down("xs")]: {
        padding: "4px 4px 4px 8px", // Adjust padding for mobile screens
        height: "40px", // Adjust height for mobile screens
      },
    },
  },
}));

export default useStyles;
