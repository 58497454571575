import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import useStyles from "./styles";
import closeIcon from "../../../assets/images/close.svg";
import borderLine from "../../../assets/images/borderline.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Button, Collapse, Drawer, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import {getUserName} from "../../Authorization/Services/authService";

const DrawerMenu = (props) => {
  const navigate = useNavigate();

  const { open, onClose } = props;
  const classes = useStyles();
  const [listOpen, setListOpen] = useState(false);
  const [nesMenu, setNesMenu] = useState(false);

  const handleClick = () => {
    setListOpen(!listOpen);
    setNesMenu(false);
  };
  const handleDrawerClose = () => {
    onClose();
    // isSetOpen(false);
    setListOpen(false);
    setNesMenu(false);
  };
  function navPanelButtonClick(pageName) {
    setListOpen(false);
    setNesMenu(false);
  }

  const handleClickSubMenu = () => {
    setNesMenu(!nesMenu);
  };
  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };
  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true,BackdropProps: { classes: { root: classes.backdrop } }
        }}
        // container={container}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div
          className={classes.closeIconstyle}
        >
          <img
            src={closeIcon}
            alt="closeIcon"
            className={classes.closeIcon}
            onClick={handleDrawerClose}
          />
        </div>
        <Box  className={classes.listContent}>
          <List
           className={classes.profile}
          >
            <ListItemIcon>
              <Avatar alt="OP" src={""} />
            </ListItemIcon>
            <ListItemText primary={getUserName()} className={classes.userName} sx={{marginTop: '20px'}} />
          </List>

          <img
            src={borderLine}
            alt="borderline"
            className={classes.borderline}
          />
          {/* <Divider style={{ border: 'rgb(255 255 255 / 50%) 1px solid' }} /> */}

          <List className={classes.mainMenu} onClick={handleDrawerClose}>
            <ListItemIcon>
              <FiberManualRecordIcon className={classes.menuBullet} />
            </ListItemIcon>
            <ListItem key="Accounts'">
              <Link
                to="accounts"
                className={`${classes.linkStyle} ${classes.accountmargin}`}
              >
                Accounts
              </Link>
            </ListItem>
            {/* </div> */}
          </List>

          <List className={classes.listItem}>
            <div onClick={handleClick} className={classes.mainMenu}>
              <ListItemIcon
                className={classes.listitemIcon}
              >
                {listOpen ? (
                  <KeyboardArrowDownIcon className={classes.Arrowicon} />
                ) : (
                  <ChevronRightIcon className={classes.Arrowicon} />
                )}
              </ListItemIcon>
              <ListItem>
                <ListItemText
                  primary="Pay & Transfer"
                  className={`${classes.txt} ${classes.listtext}`}
                />
              </ListItem>
            </div>
            {/* submenu */}
            <Collapse in={listOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem className={classes.nested}>
                  <span className={classes.nes1}>
                    <FiberManualRecordIcon className={classes.menuBullet} />
                  </span>
                  <ListItemText primary="Pay a Bill" className={classes.txt} />
                </ListItem>

                <ListItem className={classes.nested}>
                  <span className={classes.nes1}>
                    <FiberManualRecordIcon className={classes.menuBullet} />
                  </span>
                  <ListItemText
                    primary="Domestic Transfer"
                    className={classes.txt}
                  />
                </ListItem>

                <ListItem
                  className={classes.nested}
                  onClick={handleClickSubMenu}
                >
                  <span
                    className={`${classes.nes1} ${classes.nes2}`}
                  >
                    {nesMenu ? (
                      <KeyboardArrowDownIcon className={classes.Arrowicon} />
                    ) : (
                      <ChevronRightIcon className={classes.Arrowicon} />
                    )}
                  </span>
                  <ListItemText
                    primary="International Transfer"
                    className={`${classes.txt} ${classes.listmargin}`}
                  />
                </ListItem>

                <Collapse in={nesMenu} timeout="auto" unmountOnExit>
                  <List
                    component="div"
                    disablePadding
                    onClick={handleDrawerClose}
                  >
                    <ListItem
                      className={`${classes.nestedList} ${classes.listpadding}`}
                      key="Recent Payments"
                    >
                      <span className={classes.nes1}>
                        <FiberManualRecordIcon className={classes.menuBullet} />
                      </span>
                      <Link
                        to="recentpayments"
                        className={classes.linkStyle}
                      >
                        Recent Payments
                      </Link>
                    </ListItem>
                    <ListItem
                      className={`${classes.nestedList} ${classes.listpadding}`}
                      onClick={() => navPanelButtonClick("Recent Payments")}
                      key="Add/modify"
                    >
                      <Link
                        to="addmodifybeneficiary"
                        className={classes.linkStyle}
                      >
                        <span className={classes.nes1}>
                          <FiberManualRecordIcon
                            className={classes.menuBullet}
                          />
                        </span>
                        Add/Modify Beneficiary
                      </Link>
                      {/* <span className={classes.nes1}>
                        <FiberManualRecordIcon className={classes.menuBullet} />
                      </span>
                      <ListItemText
                        primary="Add/modify Beneficiary"
                        className={classes.txt}
                      /> */}
                    </ListItem>
                    <ListItem
                      className={`${classes.nestedList} ${classes.listpadding}`}
                      onClick={() => navPanelButtonClick("Recent Payments")}
                      key="Send payment"
                    >
                       <Link
                        to="sendpayment"
                        className={classes.linkStyle}
                      >
                        <span className={classes.nes1}>
                          <FiberManualRecordIcon
                            className={classes.menuBullet}
                          />
                        </span>
                        Send Payment
                      </Link>
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </Collapse>
          </List>

          <List className={classes.listItem}>
            <div className={classes.mainMenu}>
              <ListItemIcon  className={classes.listItempadding}>
                <FiberManualRecordIcon className={classes.menuBullet} />
              </ListItemIcon>
              <ListItem>
                <ListItemText
                  primary="Rewards & Deals"
                  className={`${classes.txt} ${classes.listItemText}`}
                />
              </ListItem>
            </div>
          </List>

          <List className={classes.listItem}>
            <div className={classes.mainMenu}>
              <ListItemIcon className={classes.listItempadding}>
                <FiberManualRecordIcon className={classes.menuBullet} />
              </ListItemIcon>
              <ListItem>
                <ListItemText
                  primary="Tools & Investing"
                  className={`${classes.txt} ${classes.listItemText}`}
                />
              </ListItem>
            </div>
          </List>

          <List className={classes.listItem}>
            <div className={classes.mainMenu}>
              <ListItemIcon className={classes.listItempadding}>
                <FiberManualRecordIcon className={classes.menuBullet} />
              </ListItemIcon>
              <ListItem>
                <ListItemText
                  primary="Security Center"
                  className={`${classes.txt} ${classes.listItemText}`}
                />
              </ListItem>
            </div>
          </List>

          <List className={classes.listItem}>
            <div className={classes.mainMenu}>
              <ListItemIcon className={classes.listItempadding}>
                <FiberManualRecordIcon className={classes.menuBullet} />
              </ListItemIcon>
              <ListItem>
                <ListItemText
                  primary="Open an Account"
                  className={`${classes.txt} ${classes.listItemText}`}
                />
              </ListItem>
            </div>
          </List>

          <List className={classes.listItem}>
            <div className={classes.mainMenu}>
              <ListItemIcon className={classes.listItempadding}>
                <FiberManualRecordIcon className={classes.menuBullet} />
              </ListItemIcon>
              <ListItem>
                <ListItemText
                  primary="Help & Support"
                  className={`${classes.txt} ${classes.listItemText}`}
                />
              </ListItem>
            </div>
          </List>

          <List>
            <ListItem>
              <Button
                variant="contained"
                className={classes.logoutBtn}
                onClick={logout}
              >
                Logout
              </Button>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};
export default DrawerMenu;
