import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import AmountSection from "./amountSection";
import Beneficiary from "./Beneficiary";
import { fetchAccountsData } from "../../CommonService/sendPaymentService";
import { useNavigate } from "react-router-dom";
import ReviewBeneficiary from "./ReviewBeneficiary";
import Review from "./Review";
import FinalResponse from "./FinalResponse";
import {
  Box,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Loader from "../Loader";

const SendPayment = () => {
  
  const classes = useStyles();
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState([]);
  const [data, setData] = useState();
  const [avaiableToPayCurrencyList, setAvailableToPayCurrencyList] = useState([]);
  const [nextAssignmentId, setnextAssignmentId] = useState();
  const [caseId, setCaseId] = useState();
  const [eTag, setEtag] = useState();
  const [availableToPayCountryNamesList, setAvailableToPayCountryNamesList] =  useState([]);
  const [receiversCountryName, setReceiversCountryName] = useState("");
  const [selectedBeneDetails, setSelectedBeneDetails] = useState(null);
  const [previousData, setPreviousData] = useState(null);
  const [purposeCodeList, setPurposeCodeList] = useState([]);
  const [reviewDetails, setReviewDetails] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [finalResponse, setFinalResponse] = useState(null);
  const [showReviewBeneficiary, setShowReviewBeneficiary] = useState(false);
  const [loading, setLoading] = useState(true);


  function getAccounts(response) {
    const list = response.shared.D_GetAccountList;
    const keys = Object.keys(list);
    let pxResults = [];

    for (const key of keys) {
      if (key.startsWith("D_GetAccountList_")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  function getCountryNamesList(response) {
    const list = response.shared.D_GetCountryName;
    const keys = Object.keys(list);
    let pxResults = [];

    for (const key of keys) {
      if (key.startsWith("D_GetCountryName")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }


  useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetchAccountsData();
          setData(response.data);
          setEtag(response.headers["etag"]);
          setnextAssignmentId(response.data.nextAssignmentInfo.ID);
          setCaseId(response.data.ID.split(" ")[1]);
          setAvailableToPayCurrencyList(
            response.data.data.shared.D_AvailabletoPayCurrencyListAPIList
              .D_AvailabletoPayCurrencyListAPIList.pxResults
          );
          setAvailableToPayCountryNamesList(getCountryNamesList(response?.data?.data));
          let pxResults = getAccounts(response?.data?.data);
          setAccounts(pxResults);
        } catch (error) {
          console.error("Error fetching accounts data:", error);
          localStorage.clear();
          navigate("/login");
        } finally {
          setLoading(false); 
        }
      };
      fetchData();
  }, [navigate]);

  // Function to render tab content
  const renderTabContent = () => {
    switch (tabIndex) {
      case 0:
        return (
          <AmountSection
            onContinue={() => setTabIndex(1)}
            data={data}
            accounts={accounts}
            eTag={eTag}
            nextAssignmentId={nextAssignmentId}
            avaiableToPayCurrencyList={avaiableToPayCurrencyList}
            availableToPayCountryNamesList={availableToPayCountryNamesList}
            caseId={caseId}
            setEtag={setEtag}
            setReceiversCountryName={setReceiversCountryName}
            previousData={previousData}
          />
        );
      case 1:
        return (
          <Beneficiary
            onContinue={() => {
              setTabIndex(2);
              setShowReviewBeneficiary(true);
            }}
            receiversCountryName={receiversCountryName}
            nextAssignmentId={nextAssignmentId}
            eTag={eTag}
            setEtag={setEtag}
            setSelectedBeneDetails={setSelectedBeneDetails}
            setPurposeCodeList={setPurposeCodeList}
            onPrevious={() => setTabIndex(0)}
            setPreviousData={setPreviousData}
            previousData={previousData}
          />
        );
      case 2:
        if (showReviewBeneficiary) {
          return (
            <ReviewBeneficiary
              onContinue={() => {
                setTabIndex(3);
              }}
              selectedBeneDetails={selectedBeneDetails}
              purposeCodeList={purposeCodeList}
              nextAssignmentId={nextAssignmentId}
              eTag={eTag}
              setEtag={setEtag}
              setReviewDetails={setReviewDetails}
              onPrevious={() => {
                setShowReviewBeneficiary(false);
                setTabIndex(1);
              }}
              setPreviousData={setPreviousData}
              previousData={previousData}
            />
          );
        } else {
          return null;
        }
      case 3:
        return (
          <Review
            onContinue={() => setTabIndex(3)}
            nextAssignmentId={nextAssignmentId}
            eTag={eTag}
            setEtag={setEtag}
            reviewDetails={reviewDetails}
            setFinalResponse={setFinalResponse}
            onPrevious={() => {
              setTabIndex(2);
              setShowReviewBeneficiary(true);
            }}
            setPreviousData={setPreviousData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
    {loading ? (<Loader />)
      : (
      <>
        {!finalResponse?.content &&
        <>
        <Box className={classes.rootContainer}>
            <Typography className={classes.title}>
              New Send Payment {caseId}
            </Typography>
            <Tabs
              value={tabIndex}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="payment steps"
              className={classes.Tabsborder}
            >
              <Tab label="Amount" className={classes.tabHeading} />
              <Tab label="Beneficiary" className={classes.tabHeading} />
              <Tab label="Review" className={classes.tabHeading} />
            </Tabs>

            <Box className={classes.CotntentPadding}>
              {renderTabContent()}
            </Box>
          </Box>
          </>
        }
        {finalResponse?.content && <FinalResponse finalResponse = {finalResponse} />}
        </>
      )
   }
    </>
  );
};
export default SendPayment;
