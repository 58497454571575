import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  //Flex Box Common Stylings
  flexCenter: {
    display: "flex",
    justifyContent: "center",
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  //Custom Stylings
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  sendBtn: {
    backgroundColor: "#52459f !important",
    color: "#fff !important",
    padding: "5px 23px !important",
    cursor: "pointer !important",
    border: "2px solid #52459f !important",
    borderRadius: "20px !important",
    fontSize: "0.9em !important",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "transparent !important",
      color: "#52459f !important ",
      padding: "5px 23px !important ",
      border: "2px solid #52459f !important ",
      boxShadow: "none !important ",
    },
  },
  requestBtn: {
    padding: "5px 23px !important",
    borderRadius: "30px !important",
    backgroundColor: "#fff !important",
    fontSize: "0.9em !important",
    color: "#52459f !important",
    boxShadow: "none !important",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#52459f !important",
      color: "#fff !important",
      boxShadow: "none !important",
    },
  },
  title: {
    color: "#52459f !important",
    fontSize: "16px !important",
    fontWeight: "bold !important",
    fontFamily: "Open sans !important ",
  },
  searchContainer: {
    position: "relative",
  },
  searchInput: {
    border: "1px solid #ddd !important",
    color: "#4F4F4F",
    fontSize: "14px",
    borderRadius: "15px !important",
    lineHeight: "38px",
    height: "40px !important",
    fontWeight: "normal",
    textDecoration: "inherit",
    textTransform: "none",
    margin: "10px  0px",
    padding: "0 32px 0 16px",
    width: " -webkit-fill-available",
    background: " #FFF !important",
    "&:focus": {
      boxShadow:
        "0 0 0 0.11rem #fff, 0 0 0 0.18rem #295ED9, 0 0 0 0.3rem #295ED91a",
      outline: "none",
    },
  },
  searchIcon: {
    position: "absolute",
    right: "38px", // Adjust to align icon properly
    top: "50%",
    transform: "translateY(-50%)",
    color: "#52459f",
  },
  closeIcon: {
    position: "absolute",
    right: "8px", // Adjust to align icon properly
    top: "50%",
    transform: "translateY(-50%)",
    color: "#52459f",
  },
  tableContainer: {
    marginTop: "10px",
    overflow: "auto !important ",
    background: "transparent",
    scrollbarWidth: "thin",
    boxSizing: "border-box",
  },
  tHeading: {
    background: "#fff",
  },
  tCell: {
    whiteSpace: "nowrap !important",
    color: "#52459f !important",
    display: "flex",
    justifyContent: "space-between",
  },
  filterIcon: {
    width: "18px !important",
    marginLeft: "100px !important",
  },
  tableRow: {
    cursor: "pointer",
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff !important",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#efefef !important",
    },
    "& .MuiTableCell-root": {
      padding: " 8px 8px 8px 16px",
      height: " 50px",
      border: "none",
    },
  },
  avatar: {
    marginRight: "15px",
  },
  currencyContainer: {
    display: "flex",
    alignItems: "center",
    width: "40% !important",
    gap: "0px !important",
    justifyContent: "space-between",
  },
  amountContainer: {
    flexGrow: 1,
    textAlign: "left",
  },
  currencyFlag: {
    borderRadius: "50% !important",
    width: "20px !important",
    height: "20px !important",
    marginLeft: "5px !important",
  },
  imageIcon: {
    width: "20px !important",
    height: "20px !important",
    marginLeft: "25px",
  },
  actions: { display: "flex !important", gap: "20px !important" },
  repeatIcon: {
    fill: "#54329f !important",
    paddingTop: "10px !important",
  },

  //Dialog Stylings --Begin
  dialogTitle: {
    background: "#51459E",
    color: "#FCFBFA",
    borderRadius: "42px",
    margin: "10px !important",
    // "& div[class*='MuiDialogTitle-root']":{
    //   padding:"0px"
    // }
  },
  popup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  popupTitle: {
    fontFamily: " OpenSans, sans-serif",
    fontSize: "16px !important",
    color: "inherit",
    fontWeight: "bold !important",
    textDecoration: "inherit",
    textTransform: "none",
    paddingLeft: "10px",
  },
  clearIcon: {
    cursor: "pointer",
  },
  alertMessage: {
    textAlign: "center",
    fontSize: "14px !important",
    fontWeight: "700 !important",
    color: "#222222 !important",
  },
  labels: {
    fontSize: "12px !important",
    fontWeight: "700 !important",
    color: "#222222 !important",
  },
  values: {
    fontFamily: "OpenSans, sans-serif",
    fontSize: "14px !important",
    color: "#4F4F4F !important",
    fontWeight: "normal !important",
    textAlign: "left !important",
  },
}));

export default useStyles;
