import React from "react";
import CurrencyFlag from "react-currency-flags";
import flagsCode from "../../../CommonService/flagServies";
import useStyles from "../styles";
import checkMark from "../../../../assets/images/check_mark_green.svg";
import { Box, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

const Singapore = (props) => {
  const classes = useStyles();

  const {
    editableBeneDetails,
    isEditable,
    handleInputChange,
    flag,
    review,
    reviewDetails,
    payAgain,
    repeatPayment,
  } = props;

  return (
    <>
      {review ? (
        <>
          <Box className={classes.spacing}>
            <Grid container size={{ xs: 12, md: 12 }}>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Name
                </Typography>
                <Typography className={classes.textFont}>
                  {reviewDetails?.SelectedBeneDetails?.FullName}
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary IBAN
                </Typography>
                <Typography variant="body2" className={classes.textFont}>
                  {reviewDetails?.SelectedBeneDetails?.MaskIBANNumb}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.spacing}>
            <Grid container xs={12} md={12}>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Bank BIC Code
                </Typography>
                <Typography className={classes.textFont}>
                  {reviewDetails?.SelectedBeneDetails?.BICFI}
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Bank Address
                </Typography>
                <Typography variant="body2" className={classes.textFont}>
                  {reviewDetails?.SelectedBeneDetails?.BeneBankAddress}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.spacing}>
            <Grid container xs={12} md={12}>
              <Grid size={{ xs: 12, md: 6 }} direction="column">
                <Typography variant="body2" className={classes.headerstyle}>
                  Purpose of payment
                </Typography>
                <Typography className={classes.textFont}>
                  {reviewDetails?.PaymentInfo?.PaymentsPurposeCode}
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Remarks
                </Typography>
                <Typography className={classes.textFont}>
                  {reviewDetails?.Remarks}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (payAgain || !review || repeatPayment) && (
        <>
          <Grid container className={classes.spacing}>
            {!payAgain && !repeatPayment && <Grid size={{ xs: 6 }} className={classes.flexCenterAlign}>
              <Typography variant="body2" className={classes.headerstyle}>
                <b>Pay to Country :</b>
              </Typography>
              <Typography
                variant="body2"
                className={`${classes.flexCenterAlign} ${classes.Gap}`}
              >
                {editableBeneDetails?.Country} &nbsp;
                <span>
                  <CurrencyFlag
                    currency={flagsCode[editableBeneDetails?.Country]}
                    className={classes.Currency}
                  />
                </span>
              </Typography>
            </Grid>}
            <Grid size={{ xs: 6 }}></Grid>
          </Grid>
          <Grid container className={classes.spacing}>
            <Grid size={{ xs: 6 }}>
              <Typography variant="body2" className={classes.headerstyle}>
                Nickname (Optional)
              </Typography>
              {isEditable ? (
                <TextField
                  variant="outlined"
                  size="small"
                  // className={classes.textfield}

                  value={editableBeneDetails.NickName}
                  onChange={handleInputChange}
                  name="NickName"
                />
              ) : editableBeneDetails.NickName ? (
                <span>{editableBeneDetails.NickName}</span>
              ) : (
                "---"
              )}
            </Grid>
          </Grid>
          <Grid container size={{ xs: 12, md: 12 }} className={classes.spacing}>
            <Grid size={{ xs: 12, md: 6 }}>
              <Typography variant="body2" className={classes.headerstyle}>
                Beneficiary Name
              </Typography>
              {isEditable ? (
                <TextField
                  variant="outlined"
                  size="small"
                  value={editableBeneDetails.CdtrNm}
                  onChange={handleInputChange}
                  name="FullName"
                />
              ) : (
                <span> {editableBeneDetails.CdtrNm}</span>
              )}

              {flag && editableBeneDetails.IsValidBeneNameURL && (
                <img
                  src={checkMark}
                  alt="checkmark"
                  className={classes.checkMark}
                />
                //IsValidBeneNameURL
              )}
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Typography variant="body2" className={classes.headerstyle}>
                Beneficiary Account Number
              </Typography>
              {isEditable ? (
                <TextField
                  variant="outlined"
                  size="small"
                  value={editableBeneDetails.CdtrAcctId}
                  onChange={handleInputChange}
                  name="CdtrAcctTpPrtry"
                />
              ) : (
                <span>{editableBeneDetails.CdtrAcctId}</span>
              )}

              {flag && (
                <img
                  src={checkMark}
                  alt="checkmark"
                  className={classes.checkMark}
                />
                // IsValidAccountTypeURL
              )}
            </Grid>
          </Grid>
          <Grid container size={{ md: 12 }} className={classes.spacing}>
            <Grid size={{ xs: 12, md: 6 }}>
              <Typography variant="body2" className={classes.headerstyle}>
                Beneficiary Bank BIC Code
              </Typography>
              <span>{editableBeneDetails.BICFI}</span>
              {!isEditable && flag && (
                <img
                  src={checkMark}
                  alt="checkmark"
                  className={classes.checkMark}
                />
                // IsValidBeneBICCodeURL
              )}
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Typography variant="body2" className={classes.headerstyle}>
                Beneficiary Bank Address
              </Typography>
              <span>{editableBeneDetails?.PostalAddress}</span>
              {!isEditable && flag && (
                <img
                  src={checkMark}
                  alt="checkmark"
                  className={classes.checkMark}
                />
                // editableBeneDetails?.IsValidBeneAddressURL
              )}
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Typography variant="body2" className={classes.headerstyle}>
                Beneficiary Bank Name
              </Typography>
              <span>{editableBeneDetails?.BankName}</span>
              {!isEditable && flag && (
                <img
                  src={checkMark}
                  alt="checkmark"
                  className={classes.checkMark}
                />
                // editableBeneDetails?.IsValidBeneAddressURL
              )}
            </Grid>
          </Grid>
          {editableBeneDetails?.message && (
            <Box className={classes.spacing} sty>
              <Typography variant="body2" className={classes.headerstyle}>
                Validation message
              </Typography>
              <Typography className={classes.textFont}>
                {editableBeneDetails.message}
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
};
export default Singapore;
