import React from "react";
import CurrencyFlag from "react-currency-flags";
import flagsCode from "../../../CommonService/flagServies";
import useStyles from "../styles";
import checkMark from "../../../../assets/images/check_mark_green.svg";
import {
  Box,
  TextField,
  Typography,
} from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Grid from "@mui/material/Grid2";
const Luxembourg = (props) => {
  const classes = useStyles(); 

  const {editableBeneDetails, isEditable, handleInputChange, flag,review, reviewDetails, payAgain,
    repeatPayment,}=props
  return (
    <>
    {review ? (
      <>
      <Box className={classes.spacing}>
        <Grid container size={{ xs: 12, md: 12 }}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography variant="body2" className={classes.headerstyle}>
              Beneficiary Name
            </Typography>
            <Typography /* style={{ fontSize: "13px" }} */ className={classes.textFont}>
              {reviewDetails?.SelectedBeneDetails?.CdtrNm}
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography variant="body2" className={classes.headerstyle}>
            Beneficiary IBAN
            </Typography>
            <Typography variant="body2" className={classes.textFont}>
              {reviewDetails?.SelectedBeneDetails?.CdtrAcctIdIBAN}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.spacing}>
        <Grid container xs={12} md={12}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography variant="body2" className={classes.headerstyle}>
              Beneficiary Address
            </Typography>
            <Typography className={classes.textFont}>
              {reviewDetails?.SelectedBeneDetails?.beneficiaryAddress}
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography variant="body2" className={classes.headerstyle}>
              Beneficiary Bank Address
            </Typography>
            <Typography variant="body2" className={classes.textFont}>
              {reviewDetails?.SelectedBeneDetails?.PostalAddress}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.spacing}>
      <Grid container xs={12} md={12}>
        <Typography variant="body2" className={classes.headerstyle}>
        Beneficiary Bank BIC Code
        </Typography>
        <Typography className={classes.textFont}>
            {reviewDetails?.SelectedBeneDetails?.BICFI}
        </Typography>
      </Grid>
      </Box>
    </>
    ) : (
      (payAgain || !review || repeatPayment) && (
    <>
      {" "}
      <Grid container className={classes.spacing}>
        { !payAgain && !repeatPayment && (<Grid size={{ xs: 6 }} className={classes.flexCenterAlign}>
          <Typography variant="body2" className={classes.headerstyle}>
            <b>Pay to Country :</b>
          </Typography>
          <Typography variant="body2"  className={`${classes.flexCenterAlign} ${classes.Gap}`}>
            {editableBeneDetails?.Country} &nbsp;
            <span>
              <CurrencyFlag
                currency={flagsCode[editableBeneDetails?.Country]}
                className={classes.Currency}
              />
            </span>
          </Typography>
        </Grid>)}
        <Grid size={{ xs: 6 }}></Grid>
      </Grid>
      <Grid container className={classes.spacing}>
        <Grid size={{ xs: 6 }}>
          <Typography variant="body2" className={classes.headerstyle}>
            Nickname (Optional)
          </Typography>
          {isEditable ? (
            <TextField
              variant="outlined"
              size="small"
              value={editableBeneDetails.NickName}
              onChange={handleInputChange}
              name="NickName"
            />
          ) : editableBeneDetails.NickName ? (
            <span>{editableBeneDetails.NickName}</span>
          ) : (
            "---"
          )}
        </Grid>
      </Grid>
      <Grid container size={{ xs: 12, md: 12 }} className={classes.spacing}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Typography variant="body2" className={classes.headerstyle}>
            Beneficiary Name
          </Typography>
          {isEditable ? (
            <TextField
              variant="outlined"
              size="small"
              // className={classes.textfield}
              // className={`${classes.textfieldNew} ${isEditable ? classes.outlinedEditable :  classes.textfieldDisable}`}
              value={editableBeneDetails.CdtrNm}///
              onChange={handleInputChange}
              name="FullName"
              // InputProps={{ readOnly: !isEditable }}
            />
          ) : (
            <span> {editableBeneDetails.CdtrNm}</span>
          )}

          {/* {flag && editableBeneDetails.IsValidBeneNameURL && (
            <img
              src={checkMark}
              alt="checkmark"
              className={classes.checkMark}
            />
            // IsValidBeneNameURL
          )} */}
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Typography variant="body2" className={classes.headerstyle}>
            Beneficiary IBAN
          </Typography>
          {isEditable ? (
            <TextField
              variant="outlined"
              size="small"
              value={editableBeneDetails.CdtrAcctIdIBAN}
              onChange={handleInputChange}
              name="CdtrAcctIdIBAN"
              // InputProps={{ readOnly: !isEditable }}
              // disabled={!isEditable}
            />
          ) : (
            <span>{editableBeneDetails.CdtrAcctIdIBAN}</span>
          )}

          {/* {flag && (
            <img
              src={checkMark}
              alt="checkmark"
              className={classes.checkMark}
            />
            // IsValidBeneIBANURL
          )} */}
        </Grid>
      </Grid>
     {/*  <Grid container className={classes.spacingCont}>
        <Grid item className={classes.flex} container alignItems="center">
          <InfoOutlined className={classes.InfoOutlined} />
          &nbsp;&nbsp;<Typography variant="body2">
            Enter BIC Code or ABA Number
          </Typography>
        </Grid>
      </Grid> */}
      <Grid container size={{md:12}} className={classes.spacing}>
        <Grid size={{xs:12,md:6}}>
          <Typography variant="body2" className={classes.headerstyle}>
          Beneficiary Bank BIC Code
          </Typography>
          {isEditable ? (
            <TextField
              variant="outlined"
              size="small"
              value={editableBeneDetails.BICFI}
              onChange={handleInputChange}
              name="BICFI"
            />
          ) : (
            <span>{editableBeneDetails.BICFI}</span>
          )}
        </Grid>
        <Grid size={{xs:12,md:6}}>
            <Typography variant="body2" className={classes.headerstyle}>
                Beneficiary Bank Address
            </Typography>
            {isEditable ? (
            <TextField
              variant="outlined"
              size="small"
              value={editableBeneDetails.PostalAddress}
              onChange={handleInputChange}
              name="PostalAddress"
            />
          ) : (
            <span>{editableBeneDetails.PostalAddress}</span>
          )}
        </Grid>
      </Grid>
      {editableBeneDetails?.message && (
        <Box className={classes.spacing} sty>
          <Typography variant="body2" className={classes.headerstyle}>
            Validation message
          </Typography>
          <Typography className={classes.textFont}>
            {editableBeneDetails.message}
          </Typography>
        </Box>
      )}
    </>
      ))}{" "}
    </>
  );
};
export default Luxembourg;
