import React, { useEffect, useState } from "react";
import CurrencyFlag from "react-currency-flags";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getListOfCountries } from "../../CommonService/sendPaymentService";
import {
  createNewBeneficiary,
  initiateNewBeneficiary,
} from "../../CommonService/commonAppService";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import useStyles from "./styles";

const ModalPopup = (props) => {
  const { handleClose, rowData, open, newFormFlag } = props;
  const classes = useStyles();
  const [getCountries, setGetCountries] = useState([]);
  const [getAssignmentID, setAssignmentID] = useState();
  const [formFlag, setFormFlag] = useState(false);
  const [etag, setEtag] = useState();

  useEffect(() => {
    if(open != true) return;
    createNewBeneficiary()
      .then((res) => {
        console.log(res, res.headers["etag"]);
        setEtag(res.headers["etag"]);
        setAssignmentID(res?.data?.data?.caseInfo?.assignments[0].ID);
      })
      .catch((error) => {
        console.log(error);
      });
    getListOfCountries()
      .then((res) => {
        console.log("getListOfCountries:-", res);

        setGetCountries(res.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setFormFlag(false);
  }, [open]);

  const handleCountries = (evt) => {
    const value = evt.target.value;
    if (value != "") {
      setFormFlag(true);
    } else {
      setFormFlag(false);
    }
    initiateNewBeneficiary(getAssignmentID, value, etag)
      .then((res) => console.log(res))
      .catch((err) => console.error(err));
  };
  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.Dialog}
      >
        {newFormFlag ? (
          <>
            {" "}
            <DialogTitle
              id="simple-dialog-title"
              className={classes.dialogTitle}
            >
              <div className={classes.popup}>
                <Typography variant="body1" className={classes.popupTitle}>
                  Add New Beneficiary
                </Typography>
                <ClearIcon
                  className={classes.ClearIcon}
                  onClick={handleClose}
                />
              </div>
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid size={{ md: 4 }}>
                  <Typography>Pay-to Country:</Typography>
                </Grid>
                <Grid size={{ md: 5 }}>
                  {" "}
                  <Box  className={classes.formselect}>
                    <FormControl fullWidth>
                      {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={handleCountries}
                        size="small"
                      >
                        {getCountries &&
                          getCountries.map((item) => (
                            <MenuItem
                              value={item?.Country}
                              key={item?.CountryCode}
                            >
                              {item?.Country}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                {formFlag && (
                  <>
                    <Grid container spacing={1}>
                      <Box sx={{ minWidth: 120 }}>
                        <Typography pt={2}>
                          Please enter Beneficiary details:
                        </Typography>
                      </Box>
                      <Grid size={{ md: 12 }}>
                        <FormControl>
                          <Typography variant="body2">
                            Nickname(Optional)
                          </Typography>

                          <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                          />
                        </FormControl>
                      </Grid>
                      <Grid size={{ md: 6 }}>
                        <FormControl>
                          <Typography variant="body2">
                            Beneficiary Name{"*"}
                          </Typography>

                          <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                          />
                        </FormControl>
                      </Grid>
                      <Grid size={{ md: 6 }}>
                        <FormControl>
                          <Typography variant="body2">
                            Account Number{"*"}
                          </Typography>

                          <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        size={{ md: 12 }}
                        spacing={2}
                        sx={{ display: "flex", gap: "3px" }}
                      >
                        <InfoOutlinedIcon className={classes.infoIcon} />
                        <Typography variant="body2">
                          Enter BIC Code or ABA Number
                        </Typography>
                      </Grid>
                      <Grid size={{ md: 6 }}>
                        <FormControl>
                          <Typography variant="body2">
                            Beneficiary Bank BIC Code{"*"}
                          </Typography>

                          <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                          />
                        </FormControl>
                      </Grid>
                      <Grid size={{ md: 6 }}>
                        <FormControl>
                          <Typography variant="body2">
                            ABA Routing Number{"*"}
                          </Typography>

                          <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container spacing={2}>
                <Grid md={6}>
                  <Button className={classes.cancel}>Cancel</Button>
                </Grid>
                <Grid md={6}>
                  {" "}
                  <Button className={classes.submit}>Validate</Button>
                </Grid>
              </Grid>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle
              id="simple-dialog-title"
              className={classes.dialogTitle}
            >
              <div className={classes.popup}>
                <Typography variant="body1" className={classes.popupTitle}>
                  Add/Modify Beneficiary
                </Typography>
                <ClearIcon
                  className={classes.ClearIcon}
                  onClick={handleClose}
                />
              </div>
            </DialogTitle>

            <DialogContent>
              <Grid container spacing={2}>
                <Grid size={{ md: 12 }} className={classes.dialogFlex}>
                  <Grid container spacing={2}>
                    <Grid
                      size={{ md: 9 }}
                      className={classes.dialogFlexAround}
                    >
                      <Typography variant="body2">Pay-to Country:</Typography>
                      <Typography variant="body2">
                        &nbsp;{rowData?.Country}
                      </Typography>
                      <CurrencyFlag
                        currency=  {rowData?.CountryCode}
                        className={classes.CurrencyFlag}
                      />
                    </Grid>
                    {/* <Grid size={{ md: 3 }}></Grid> */}
                    <Grid size={{ md: 3 }}>
                      <div className={classes.main}>
                        <EditIcon className={classes.editIcon} />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid size={{ md: 12 }}>
                  <Typography variant="body2">Nickname (Optional)</Typography>
                  <Typography variant="body2">--</Typography>
                </Grid>
                <Grid size={{ md: 12 }}>
                  <Grid container spacing={2}>
                    <Grid size={{ md: 6 }}>
                      <Typography variant="body2">Beneficiary Name</Typography>
                      <Typography variant="body2">
                        {rowData?.FullName}
                      </Typography>
                    </Grid>
                    <Grid size={{ md: 6 }}>
                      <Typography variant="body2">Account Number</Typography>
                      <Typography variant="body2">
                        {rowData?.CdtrAcctId}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  size={{ md: 12 }}
                  spacing={2}
                  className={classes.Infostyle}
                >
                  <InfoOutlinedIcon className={classes.infoIcon} />
                  <Typography variant="body2">
                    Enter BIC Code or ABA Number
                  </Typography>
                </Grid>
                <Grid size={{ md: 12 }}>
                  <Grid container spacing={2}>
                    <Grid size={{ md: 6 }}>
                      <Typography variant="body2">
                        Beneficiary Bank BIC Code
                      </Typography>
                      <Typography variant="body2">{rowData?.BICFI}</Typography>
                    </Grid>
                    <Grid size={{ md: 6 }}>
                      <Typography variant="body2">
                        ABA Routing Number
                      </Typography>
                      <Typography variant="body2">--</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid size={{ md: 6 }}>
                  <Typography variant="body2">
                    Beneficiary Bank Address
                  </Typography>{" "}
                  <Typography variant="body2">
                    {rowData?.BeneBankAddress}
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Grid
                  size={12}
                  className={classes.Buttongrid}
                >
                  <Button className={classes.cancel}>Cancel</Button>
                  <Button className={classes.submit}>Submit</Button>
                </Grid>
              </Grid>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ModalPopup;
