import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import {
  Box,
  Typography,
  Divider,
} from "@mui/material";
import CurrencyFlag from "react-currency-flags";
import checkMark from "../../../assets/images/check_mark_green.svg";
import Usa from "./CountrySpecificFields/Usa";
import Qatar from "./CountrySpecificFields/Qatar";
import Japan from "./CountrySpecificFields/Japan";
import Grid from "@mui/material/Grid2";

const FinalResponse = (props) => {
  const { finalResponse }=props
  const classes = useStyles();
  return (
    <>
      <Box className={classes.rootContainer}>
        {finalResponse?.content && (
          <>
            <div className={classes.textCenter}>
              <img src={checkMark} width={50} alt="checkmark" height={50} />
              <Typography>Money Sent</Typography>
            </div>
            <Grid
              container
              className={classes.finalScreen}
            >
              <Grid
                size={{ md: 12 }}
                className={classes.ScreenStyle}
              />
              <Grid size={{ md: 12 }}>
                <Typography
                  className={classes.TransactionHeader}
                >
                  Transaction Summary
                </Typography>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Account to be Debited
                </Typography>
                <Box>
                  <Typography variant="body2" className={classes.contentstyle}>
                    {finalResponse?.content?.MaskAccountNumber}
                  </Typography>
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Available Balance
                </Typography>
                <Box className={classes.flex}>
                  <Typography variant=" body2" className={classes.contentstyle}>
                    {finalResponse?.content?.AccountBalance}
                  </Typography>
                  <Box className={classes.optionValue}>
                    {finalResponse?.content?.SendCurrency}
                  </Box>
                  <CurrencyFlag
                    currency={finalResponse?.content?.ReceiveCurrency}
                    className={classes.currencyStyle}
                  />
                </Box>
              </Grid>
              <Grid size={{ md: 12 }}>
                {" "}
                <Divider className={classes.spacing}/>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Amount to be sent
                </Typography>
                <Box className={classes.flexCenterAlign}>
                  <Typography className={classes.contentstyle}>
                    {finalResponse?.content?.ReceiveAmount}
                  </Typography>
                  <Box className={classes.Sendcurrency}>
                    {finalResponse?.content?.SendCurrency}
                  </Box>
                  <CurrencyFlag
                    currency={finalResponse?.content?.ReceiveCurrency}
                    className={classes.currencyStyle}
                  />
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Amount to be Received
                </Typography>
                <Box className={classes.flexCenterAlign}>
                  <Typography className={classes.contentstyle}>
                    {finalResponse?.content?.ReceiveAmount}
                  </Typography>
                  <Box className={classes.Sendcurrency}>
                    {finalResponse?.content?.SendCurrency}
                  </Box>
                  <CurrencyFlag
                    currency={finalResponse?.content?.ReceiveCurrency}
                    className={classes.currencyStyle}
                  />
                </Box>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Transaction Fee
                </Typography>
                <Box className={classes.flex}>
                  <Typography variant="body2" className={classes.textFont}>
                    {finalResponse?.content?.TransactionDesc}
                  </Typography>
                  <CurrencyFlag
                    currency={finalResponse?.content?.ReceiveCurrency}
                    className={classes.currencyStyle}
                  />
                </Box>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Estimated Delivery Time
                </Typography>
                <Typography className={classes.textFont}>
                  {finalResponse?.content?.EstimatedDeliveryTime}
                </Typography>
              </Grid>
              <Grid container size={{ md: 12 }} className={classes.spacing}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    Foreign Exchange Rate
                  </Typography>
                  <Typography className={classes.textFont}>
                    {finalResponse?.content?.CurrencyConvDesc}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    Exchange Rate Valid For
                  </Typography>
                  <Typography variant="small">
                    {finalResponse?.content?.FXRateGoodForText}
                  </Typography>
                </Grid>
              </Grid>
              <Grid size={{ md: 12 }}>
                {" "}
                <Divider className={classes.spacing} />
              </Grid>
              <Grid size={{xs:12, md:12}}>
              {finalResponse?.content?.ReceiversCountryName === "Qatar" ? (
                  <Qatar review={true} reviewDetails={finalResponse?.content} />
                ) : finalResponse?.content?.ReceiversCountryName === "Japan" ? (
                  <Japan review={true} reviewDetails={finalResponse?.content} />
                ) : finalResponse?.content?.ReceiversCountryName === "United States" ? (
                  <Usa review={true} reviewDetails={finalResponse?.content} />
                ) : (
                  <>
                    </>
                )}
                </Grid>
              </Grid>
          </>
        )}
      </Box>
    </>
  );
};
export default FinalResponse;
