import React from "react";
import CurrencyFlag from "react-currency-flags";
import flagsCode from "../../../CommonService/flagServies";
import useStyles from "../styles";
import checkMark from "../../../../assets/images/check_mark_green.svg";
import { Box, TextField, Typography } from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Grid from "@mui/material/Grid2";

const Usa = (props) => {
  const classes = useStyles();

  const {
    editableBeneDetails,
    isEditable,
    handleInputChange,
    flag,
    review,
    reviewDetails,
    payAgain,
    repeatPayment,
  } = props;

  return (
    <>
      {review ? (
        <>
          <Box className={classes.spacing}>
            <Grid container size={{ xs: 12, md: 12 }}>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Name
                </Typography>
                <Typography
                  /* style={{ fontSize: "13px" }} */ className={
                    classes.textFont
                  }
                >
                  {reviewDetails?.SelectedBeneDetails?.FullName}
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Account Number
                </Typography>
                <Typography variant="body2" className={classes.textFont}>
                  {reviewDetails?.SelectedBeneDetails?.ClrSysMmbId}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.spacing}>
            <Grid container xs={12} md={12}>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Bank BIC Code
                </Typography>
                <Typography className={classes.textFont}>
                  {reviewDetails?.SelectedBeneDetails?.BICFI}
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  ABA Routing Number
                </Typography>
                <Typography variant="body2" className={classes.textFont}>
                  {reviewDetails?.SelectedBeneDetails?.MaskAccNumb}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.spacing}>
            <Grid container xs={12} md={12}>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Bank Address
                </Typography>
                <Typography className={classes.textFont}>
                  {reviewDetails?.SelectedBeneDetails?.BeneBankAddress}
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Remarks
                </Typography>
                <Typography variant="body2" className={classes.textFont}>
                  {reviewDetails?.Remarks}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        (payAgain || !review || repeatPayment) && (
          <>
            <Grid container className={classes.spacing}>
              {!payAgain && !repeatPayment && (
                <Grid size={{ xs: 6 }} className={classes.flexCenterAlign}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    <b>Pay to Country :</b>
                  </Typography>
                  <Typography
                    variant="body2"
                    className={`${classes.flexCenterAlign} ${classes.Gap}`}
                  >
                    {editableBeneDetails?.Country} &nbsp;
                    <span>
                      <CurrencyFlag
                        currency={flagsCode[editableBeneDetails?.Country]}
                        className={classes.Currency}
                      />
                    </span>
                  </Typography>
                </Grid>
              )}
              <Grid size={{ xs: 6 }}></Grid>
            </Grid>
            <Grid container className={classes.spacing}>
              <Grid size={{ xs: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Nickname (Optional)
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={editableBeneDetails.NickName}
                    onChange={handleInputChange}
                    name="NickName"
                  />
                ) : editableBeneDetails.NickName ? (
                  <span>{editableBeneDetails.NickName}</span>
                ) : (
                  "---"
                )}
              </Grid>
            </Grid>
            <Grid
              container
              size={{ xs: 12, md: 12 }}
              className={classes.spacing}
            >
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Name
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={editableBeneDetails.FullName}
                    onChange={handleInputChange}
                    name="FullName"
                  />
                ) : (
                  <>
                    <span> {editableBeneDetails.FullName}</span>
                    <span>
                      {flag && (
                        <img
                          src={editableBeneDetails?.IsValidBeneNameURL}
                          width={20}
                          height={20}
                        />
                      )}
                    </span>
                  </>
                )}
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary IBAN
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={editableBeneDetails.CdtrAcctId}
                    onChange={handleInputChange}
                    name="CdtrAcctId"
                  />
                ) : (
                  <>
                    {" "}
                    <span>{editableBeneDetails.CdtrAcctId}&nbsp; </span>
                    <span>
                      {flag && (
                        <img
                          src={editableBeneDetails?.IsValidBeneIBANURL}
                          width={20}
                          height={20}
                        />
                      )}
                    </span>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid container className={classes.spacingCont}>
              <Grid item className={classes.flex} container alignItems="center">
                <InfoOutlined className={classes.InfoOutlined} />
                &nbsp;&nbsp;
                <Typography variant="body2">
                  Enter BIC Code or ABA Number
                </Typography>
              </Grid>
            </Grid>
            <Grid container size={{ md: 12 }} className={classes.spacing}>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Bank BIC Code
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={editableBeneDetails.BICFI}
                    onChange={handleInputChange}
                    name="BICFI"
                  />
                ) : (
                  <>
                    <span>{editableBeneDetails.BICFI}</span>
                    <span>
                      {flag && (
                        <img
                          src={editableBeneDetails?.IsValidBeneBICCodeURL}
                          width={20}
                          height={20}
                        />
                      )}
                    </span>
                  </>
                )}
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  ABA Routing Number
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    disabled={!isEditable}
                    value={editableBeneDetails.BICFI}
                    onChange={handleInputChange}
                  />
                ) : (
                  "--"
                )}
              </Grid>
            </Grid>
            <Box className={classes.spacing} sty>
              <Typography variant="body2" className={classes.headerstyle}>
                Beneficiary Bank Address
              </Typography>
              <div /* style={{ display: "flex", gap: "30px" }} */>
                <Typography className={classes.BankAddress}>
                  {editableBeneDetails.BeneBankAddress}
                </Typography>
                <span>
                  {" "}
                  {flag && (
                    <img
                      src={editableBeneDetails?.IsValidBeneAddressURL}
                      width={20}
                      height={20}
                    />
                  )}
                </span>
              </div>
            </Box>
            {editableBeneDetails?.message && (
              <Box className={classes.spacing} sty>
                <Typography variant="body2" className={classes.headerstyle}>
                  Validation message
                </Typography>
                <Typography className={classes.textFont}>
                  {editableBeneDetails.message}
                </Typography>
              </Box>
            )}
          </>
        )
      )}
    </>
  );
};
export default Usa;
