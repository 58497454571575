import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import endPoints, { BASE_URL } from "../../../config/Api";
import axios from "axios";
import CurrencyFlag from "react-currency-flags";
import FilterListIcon from "@mui/icons-material/FilterList";
import RepeatIcon from "@mui/icons-material/Repeat";
import Alert from "@mui/material/Alert";
import ReplayIcon from '@mui/icons-material/Replay';
import {
  checkBeneficiaryExists,
  createPayAgainCase,
} from "../../CommonService/commonAppService";
import {
  refreshAccessToken,
  getAccessToken,
  isTokenExpired,
} from "../../Authorization/Services/authService";
import { useNavigate } from "react-router-dom";
import ModalPopup from "./Dialog";
import {
  Avatar,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Loader from "../Loader";

const colors = [
  "#0b8953",
  "#c23824",
  "#2c97de",
  "#00657c",
  "#00aa66",
  "#667700",
  "#e27100",
  "#52459f",
];

const RecentPayments = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const token = getAccessToken();
  const [open, setOpen] = useState(false);
  const [isPayAgainAlert, setIsPayAgainAlert] = useState(false);
  const [apiError, setApiError] = useState("");
  const [loading, setLoading] = useState(true);

 const fetchData = async () => {
    setLoading(true); 
    try {
      let token = getAccessToken();

      if (isTokenExpired(token)) {
        token = await refreshAccessToken();
      }
      const response = await axios.post(
        `${endPoints.baseUrl}/data_views/${endPoints.recentPayments.D_Page_Name}`,
        {

          dataViewParameters: {},
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response?.data?.data);
      setSearchData(response?.data?.data);
    } catch (err) {
      console.error("Error fetching data:", err);
      localStorage.clear();
      navigate("/login");
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    "Beneficiary",
    "Date",
    "Sent Amount",
    "Beneficiary Credit Amount",
    "Status",
    "Actions",
  ];

  const fetchRowClickData = async (row) => {
    setOpen(!open);
    setIsPayAgainAlert(false);

    const payload = {
      dataContext: "D_TransactionDetails",
      dataContextParameters: {
        TRN: row.TRN,
      },
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/pages/PaymentTCContent`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const content = response?.data?.data?.dataInfo?.content;
      setSelectedRow(content);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const _handleClose = () => {
    setOpen(false);
  };

  const searchHandler = (event) => {
    const searchValue = event.target.value;
    setSearchText(searchValue);
    if (searchValue === "") {
      fetchData();
    }
    const SearchedData = searchData.filter(
      (row) =>
        row.BeneficiaryName &&
        row.BeneficiaryName?.toLowerCase().includes(searchValue?.toLowerCase())
    );
    setData(SearchedData);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const dateConvert = (isoString) => {
    const date = new Date(isoString);
    // Define options for date and time formatting
    const dateOptions = { month: "2-digit", day: "2-digit", year: "numeric" };
    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };

    // Format the date and time
    const formattedDate = date.toLocaleDateString("en-US", dateOptions);
    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

    // Combine date and time
    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    return formattedDateTime;
  };
  const getInitials = (name) => {
    if (!name) return "";
    const nameArray = name.trim().split(/\s+/);
    return nameArray.length > 1
      ? `${nameArray[0][0]}${nameArray[1][0]}`.toUpperCase()
      : name.substring(0, 2).toUpperCase();
  };

  const getColor = (initials) => {
    if (!initials) return colors[0]; // Default color

    const firstChar = initials[0].toUpperCase();

    if (firstChar >= "A" && firstChar <= "Z") {
      const charCode = firstChar.charCodeAt(0) - "A".charCodeAt(0);
      return colors[Math.floor(charCode / 3) % colors.length];
    } else if (firstChar >= "0" && firstChar <= "9") {
      const num = parseInt(firstChar, 10);
      return colors[Math.floor(num / 3) % colors.length];
    }

    return colors[0]; // Default color for any other characters
  };

  const [openAlertMessage, setOpenAlertMessage] = useState("");

  const handleActions = (row,actionName) => {
    try{
    checkBeneficiaryExists(row?.TRN).then((res) => {
      if (res.status === 200 && res?.data?.message?.includes("Success")) {
        createPayAgainCase(row?.TRN,actionName)
          .then((res) => {
            navigate("/payagain", {
              state: {
                response: res?.data,
                payAgain: true,
                etag: res.headers["etag"],
              },
            });
          })
          .catch((error) => {
            console.log(error);
            if (error.status !== "401") {
              setApiError(error?.response?.data);
            } else {
              navigate("/login");
            }
          }).finally(() => {
            setLoading(false);
          });
      } else {
        setOpen(!open);
        setIsPayAgainAlert(true);
        setOpenAlertMessage(res?.data?.message);
      }
    });
    } catch (error) {
      console.error("Error checking beneficiary:", error);
      if (error?.response?.status === 401) {
        navigate("/login"); 
      }
    }finally {
      setLoading(false);
    }
  };

  const handleSendClick = () => {
    navigate("/sendpayment");
  };

  return (
    <>
      {loading ? (<Loader />)
      : (
      <>
      <Grid container spacing={1}>
        <Grid size={{ md: 12, xs: 12 }}>
          <Grid container>
            <Grid size={{ md: 12 }} className={classes.btnContainer}>
              <Button className={classes.sendBtn} onClick={handleSendClick}>
                Send
              </Button>
              &nbsp;&nbsp;
              <Button className={classes.requestBtn}>Request</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{ md: 12, xs: 12 }}>
          <Typography
            className={classes.title}
            variant="h6"
            color="textPrimary"
            gutterBottom
          >
            Recent Payments
          </Typography>
          <Divider />
        </Grid>
        <Grid size={{ md: 12, xs: 12 }}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid size={{ md: 12, xs: 12 }} className={classes.searchContainer}>
              <input
                placeholder="Search for beneficiary by name"
                className={classes.searchInput}
                onChange={searchHandler}
                value={searchText}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{ md: 12, xs: 12 }}>
          {apiError && (
            <Alert
              severity="error"
              onClose={() => {
                setApiError("");
              }}
            >
              {apiError}
            </Alert>
          )}
          <TableContainer id="list-view" className={classes.tableContainer}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className={classes.tHeading}>
                <TableRow>
                  {columns.map((column, index) => {
                    return (
                      <TableCell key={index} className={classes.tCell}>
                        {column}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    const initials = getInitials(row.BeneficiaryName);
                    const backgroundColor = getColor(initials);
                    const formatted_amount = parseFloat(
                      row.ReceivedAmount
                    ).toFixed(2);
                    const formatted_Payment_amount = parseFloat(
                      row.PaymentAmount
                    ).toFixed(2);
                    return (
                      <TableRow
                        className={classes.tableRow}
                        onClick={() => fetchRowClickData(row)}
                      >
                        <TableCell>
                          <div className={classes.flexAlignCenter}>
                            <Avatar
                              style={{ backgroundColor }}
                              className={classes.avatar}
                            >
                              {initials}
                            </Avatar>
                            {row?.BeneficiaryName}
                          </div>
                        </TableCell>
                        <TableCell>{dateConvert(row?.DateOfPayment)}</TableCell>
                        <TableCell className={classes.flexAlignCenter}>
                          <div className={classes.currencyContainer}>
                            <div className={classes.amountContainer}>
                              {row?.PaymentCurrency?.includes("JPY")
                                ? parseFloat(row.PaymentAmount).toFixed(0)
                                : formatted_Payment_amount}
                              &nbsp;
                            </div>
                            <div className={classes.flexEnd}>
                              {row?.PaymentCurrency}
                              <CurrencyFlag
                                currency={row?.PaymentCurrency}
                                className={classes.currencyFlag}
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className={classes.flexAlignCenter}>
                          <div className={classes.currencyContainer}>
                            <div className={classes.amountContainer}>
                              {row?.ReceivedAmountCurrency?.includes("JPY")
                                ? parseFloat(row.ReceivedAmount).toFixed(0)
                                : formatted_amount}
                              &nbsp;
                            </div>
                            <div className={classes.flexEnd}>
                              {row?.ReceivedAmountCurrency}
                              <CurrencyFlag
                                currency={row?.ReceivedAmountCurrency}
                                className={classes.currencyFlag}
                              />
                            </div>
                          </div>
                        </TableCell>

                        <TableCell>
                          <img
                            src={row?.pyImage}
                            alt="img"
                            className={classes.imageIcon}
                            title={row?.ImageHover}
                          />
                        </TableCell>
                        <TableCell className={classes.actions}>
                          <RepeatIcon
                            className={classes.repeatIcon}
                            titleAccess="Pay Again"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleActions(row,"PayAgain");
                            }}
                          />
                          <ReplayIcon
                            className={classes.repeatIcon}
                            titleAccess="Repeat Payment"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleActions(row,"RepeatPayment");
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {data && data.length > 0 && (
            <TablePagination
              id="pagination"
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </Grid>
      <ModalPopup
        open={open}
        handleClose={_handleClose}
        rowData={selectedRow}
        colors={colors}
        isPayAgainAlert={isPayAgainAlert}
        openAlertMessage={openAlertMessage}
      /></>
      )
   }
    </>
  );
};
export default RecentPayments;
