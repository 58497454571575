import React, { useState, useEffect } from "react";
import CurrencyFlag from "react-currency-flags";
import flagsCode from "../../CommonService/flagServies";
import useStyles from "./styles";
import EditIcon from "@mui/icons-material/Edit";
import HelpIcon from "@mui/icons-material/Help";
import {
  validateBeneficiaryDetailsReviewScreen,
  getDataForReviewBenefiToAddBenefiPage,
  validateBeneficiaryDetails,
} from "../../CommonService/sendPaymentService";
import checkMark from "../../../assets/images/check_mark_green.svg";
import {
  Box,
  Button,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Usa from "./CountrySpecificFields/Usa";
import Qatar from "./CountrySpecificFields/Qatar";
import Japan from "./CountrySpecificFields/Japan";
import { useNavigate } from "react-router-dom";
import CountrySpecificComponent from "./CountrySpecificComponent";

const ReviewBeneficiary = ({
  onContinue,
  selectedBeneDetails,
  purposeCodeList,
  nextAssignmentId,
  eTag,
  setEtag,
  setReviewDetails,
  onPrevious,
  setPreviousData,
  previousData,
}) => {
  const classes = useStyles();
  const [purposeCode, setPurposeCode] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [editableBeneDetails, setEditableBeneDetails] =
    useState(selectedBeneDetails);
  const [flag, setFlag] = useState(false);
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/recentpayments");
  };

  useEffect(() => {
    if (previousData) {
      console.log(previousData);
      setEditableBeneDetails(
        previousData?.caseInfo?.content?.SelectedBeneDetails
      );
    }
  }, [previousData]);

  const handleContinue = async () => {
    try {
      const response = await validateBeneficiaryDetailsReviewScreen(
        nextAssignmentId,
        eTag
      );
      setReviewDetails(response.data);
      setEtag(response.headers.etag);
      onContinue(nextAssignmentId, eTag, setReviewDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrevious = async () => {
    try {
      const response = await getDataForReviewBenefiToAddBenefiPage(
        editableBeneDetails.BeneBankAddress,
        nextAssignmentId,
        eTag
      ).catch((err) => {
        console.log(err.response.data.errorDetails[0].message);
      });
      setEtag(response.headers["etag"]);
      const resourceData = response.data.data;
      setPreviousData(resourceData);
      onPrevious();
    } catch {}
  };

  const handleValidateAndSave = async () => {
    try {
      const response = await validateBeneficiaryDetails(
        editableBeneDetails,
        nextAssignmentId,
        eTag
      );
      setEditableBeneDetails(
        response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
      );
      setFlag(true);
      setIsEditable(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = () => {
    setIsEditable(!isEditable);
    setFlag(!flag);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditableBeneDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <div>
      <Grid container className={classes.spacing}>
        <Grid size={{ xs: 6, md: 6 }}>
          <Typography variant="body1" className={classes.heading}>
            Enter Beneficiary Details
          </Typography>
        </Grid>
        <Grid size={{ xs: 6, md: 6 }} className={classes.flexEnd}>
          <EditIcon
            fontSize="small"
            className={classes.editIcon}
            onClick={handleEdit}
          />
          <HelpIcon fontSize="medium" className={classes.helpIcon} />
        </Grid>
      </Grid>

      <CountrySpecificComponent
        editableBeneDetails={editableBeneDetails}
        isEditable={isEditable}
        handleInputChange={handleInputChange}
        flag={flag}
        review={false}
      />

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.spacing}
      >
        <Box className={classes.flex}>
          <Button className={classes.cancel} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className={`${classes.cancel} ${classes.optionValue}`}
            onClick={handlePrevious}
          >
            Back
          </Button>
        </Box>
        <Grid>
          {isEditable && (
            <Button
              className={classes.continue}
              onClick={handleValidateAndSave}
            >
              Validate And Save
            </Button>
          )}
          {!isEditable && (
            <Button className={classes.continue} onClick={handleContinue}>
              Continue
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ReviewBeneficiary;
