import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import SearchIcon from "@mui/icons-material/Search"
import CloseIcon from "@mui/icons-material/Close"
import { useNavigate } from "react-router-dom";
import {
  getListOfBeneficiaries,
  getDataForReviewBeneficiary,
  getDataForFirstPreviousPage,
} from "../../CommonService/sendPaymentService";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import flagsCode from "../../CommonService/flagServies";
import CurrencyFlag from "react-currency-flags"

const BeneficiarySection = ({
  onContinue,
  receiversCountryName,
  nextAssignmentId,
  eTag,
  setEtag,
  setSelectedBeneDetails,
  setPurposeCodeList,
  onPrevious,
  setPreviousData,
  previousData,
}) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [rowsPerPage] = useState(6);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  // const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);

  const handleCancel = () => {
    navigate('/recentpayments');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getListOfBeneficiaries(receiversCountryName);
        setData(response.data.data);
        setSearchData(response.data.data);
        //setEtag(response.headers["etag"]);
      } catch (error) {
        console.error("Error fetching Beneficiaries data:", error);
        localStorage.clear();
        navigate("/login");
      }
    };
    fetchData();
  }, [navigate, receiversCountryName]);

  useEffect(() => {
    if (previousData) {
      setSelectedBeneficiary(
        previousData?.caseInfo?.content?.AvailableRecipients?.pyGUID
      );
    }
  }, [previousData]);

  const searchHandler = (event) => {
    const searchValue = event.target.value;
    setSearchText(searchValue);
    if (searchValue === "") {
      setData(searchData);
    }
    const SearchedData = searchData.filter(
      (row) =>
        row.FullName &&
        row.FullName?.toLowerCase().includes(event.target.value?.toLowerCase())
    );
    setData(SearchedData);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handle_row = (rowid) => {
    setSelectedBeneficiary(rowid);
  };

  const isRowSelected = (rowId) => {
    return selectedBeneficiary === rowId; // Check if the row is selected
  };

  const colors = [
    "#0b8953",
    "#c23824",
    "#2c97de",
    "#00657c",
    "#00aa66",
    "#667700",
    "#e27100",
    "#52459f",
  ];

  function getPurposeCodeList(response) {
    const list = response.D_PurposeCodeList;
    const keys = Object.keys(list);
    let pxResults = [];

    for (const key of keys) {
      if (key.startsWith("D_PurposeCodeList")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  const handleContinue = async () => {
    try {
      const response = await getDataForReviewBeneficiary(
        selectedBeneficiary,
        nextAssignmentId,
        eTag
      );
      const newSelectedBeneDetails =
        response.data.data.caseInfo.content.SelectedBeneDetails;
      setSelectedBeneDetails(newSelectedBeneDetails);
      if(response?.data?.data?.shared){
        setPurposeCodeList(getPurposeCodeList(response.data.data.shared));
      }
      setEtag(response.headers["etag"]);
      onContinue(nextAssignmentId);
    } catch {}
  };

  const handlePrevious = async () => {
    try {
      const response = await getDataForFirstPreviousPage(
        nextAssignmentId,
        eTag
      );
      setEtag(response.headers["etag"]);
      const resourceData = response.data.data;
      setPreviousData(resourceData);
      onPrevious();
    } catch {}
  };

  const getInitials = (name) => {
    if (!name) return "";
    const nameArray = name.trim().split(/\s+/);

    return nameArray.length > 1
      ? `${nameArray[0][0]}${nameArray[1][0]}`.toUpperCase()
      : name.substring(0, 2).toUpperCase();
  };

  const getColor = (initials) => {
    if (!initials) return colors[0]; // Default color

    const firstChar = initials[0].toUpperCase();

    if (firstChar >= "A" && firstChar <= "Z") {
      const charCode = firstChar.charCodeAt(0) - "A".charCodeAt(0);
      return colors[Math.floor(charCode / 3) % colors.length];
    } else if (firstChar >= "0" && firstChar <= "9") {
      const num = parseInt(firstChar, 10);
      return colors[Math.floor(num / 3) % colors.length];
    }

    return colors[0]; // Default color for any other characters
  };

  const _handleClick = (data) => {
    setRowData(data);
    setOpen(!open);
  };

  function CustomTablePagination({ count, page, rowsPerPage, onPageChange }) {
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    return (
      <Box
       className={classes.pageNation}
      >
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeftIcon />
        </IconButton>

        {/* Page count in the center */}
        <Typography variant="body2"  className={classes.pageCount}>
          {`${page * rowsPerPage + 1}-${Math.min(
            count,
            (page + 1) * rowsPerPage
          )} of ${count}`}
        </Typography>

        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        
        <Grid size={{md:12, xs:12}}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid size={{md:12, xs:12}} className={classes.searchbar}>
              <input
                placeholder="Search for beneficiary by name"
                className={classes.inputInput}
                // onChange={_onSearch}
                onChange={searchHandler}
                value={searchText}
              />
              <SearchIcon className={classes.searchIcon} />
              <CloseIcon className={classes.closeIcon} />
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid size={{md:12, xs:12}}>
          <Typography variant="body1" color="textPrimary" gutterBottom>
            <b>Add/Modify Beneficiary</b>
          </Typography>
        </Grid> */}
        <Grid size={{md:12, xs:12}} className={classes.newBeneficiary}>
          <div  className={classes.BeneficiaryItems}>
            <Typography variant="h6" className={classes.addtxt}>
              Add New Beneficiary
            </Typography>
            <AddCircleIcon className={classes.circleIcon} />
          </div>
        </Grid>
        
        <Grid size={{md:12, xs:12}}>
        <Typography
            variant="body1"
            className={`${classes.b_heading} ${classes.flexCenterAlign}`}
          >
            Available Beneficiaries in {receiversCountryName} &nbsp;
            <CurrencyFlag
              currency={flagsCode[receiversCountryName]}
              className={classes.CurrencyFlag}
            />
          </Typography>
        </Grid>
        <Grid size={{md:12, xs:12}}>
          <Grid container spacing={2}>
            {data
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, index) => {
                const initials = getInitials(row.FullName);
                const backgroundColor = getColor(initials);
                return (
                  <>
                    <Grid size={{md:6, xs:6}} key={index}>
                      <Card
                        key={row.pxRefObjectInsName || row?.pyID}
                        className={`${classes.accountCard} ${
                          isRowSelected(row.pyID) ? classes.selectedRow : " "
                        }`}
                        onClick={() => handle_row(row?.pyID)}
                      >
                        <CardContent
                          className={
                            classes.acContent
                          } /*  onClick={()=>handle_row(row.pyID)} */
                        >
                          <div className={classes.cardContent}>
                            <Avatar
                              style={{ backgroundColor }}
                              className={classes.avatar}
                            >
                              {initials}
                            </Avatar>
                            <div></div>
                            <div className={classes.infoContainer}>
                              <Typography
                                variant="body1"
                               className={classes.Fullname}
                              >
                                {row.FullName}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                className={classes.Accountnumber}
                              >
                                A/C No:{" "}
                                {row.MaskAccNumb
                                  ? row.MaskAccNumb
                                  : row.MaskIBANNumb}
                              </Typography>
                            </div>
                          </div>
                          <div className={classes.iconContainer}>
                            <LaunchOutlinedIcon
                              fontSize="medium"
                              className={classes.LaunchOutlinedIcon}
                              onClick={() => {
                                _handleClick(row);
                              }}
                            />
                            <DeleteOutlineOutlinedIcon
                              fontSize="medium"
                              className={classes.Deletedicon}
                            />
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  </>
                );
              })}
          </Grid>
          {data && data.length > 0 && (
            <CustomTablePagination
              count={data.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
            />
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.spacingCont}>
        <Grid size={{md:12, xs:12}} className={classes.flexBetween}>
          <div>
            <Button className={`${classes.cancel} ${classes.cancelStyle}`}  onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              onClick={handlePrevious}
              className={`${classes.cancel} ${classes.BackStyle}`}
              
            >
              Back
            </Button>
          </div>
          <Button
            variant="contained"
            onClick={handleContinue}
            className={classes.continue}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default BeneficiarySection;
