import axios from "axios";
import {
  getAccessToken,
  isTokenExpired,
  refreshAccessToken,
} from "../Authorization/Services/authService";
import endPoints, { BASE_URL } from "../../config/Api";
import { Capacitor } from "@capacitor/core";

const getHeaders = (etag = null) => {
  // const token = getAccessToken();
  let token = getAccessToken();
  if (isTokenExpired(token)) {
    token = refreshAccessToken(); // Refresh the token
  }
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // Add -specific headers if needed
  if (Capacitor.isNativePlatform()) {
    headers["x-origin-channel"] = "Mobile";
  }

  // Append the ETag header if provided
  if (etag) {
    headers["If-Match"] = etag; // Use If-Match for ETag
  }
  return headers;
};

export const fetchAccountsData = async () => {
  const payload = {
    caseTypeID: "EVN-EPP-Work-SendPayment",
    processID: "pyStartCase",
    content: {},
  };

  return await axios.post(`${BASE_URL}cases`, payload, {
    params: {
      viewType: "page",
    },
    headers: getHeaders(),
  });
};

export const getDataForFromAccount = async (
  fromAccount,
  nextAssignmentId,
  eTag
) => {
  const payload = {
    content: { FromAccount: fromAccount },
    pageInstructions: [],
  };

  return await axios.patch(
    `${BASE_URL}assignments/${nextAssignmentId}/actions/ActionInitiatePayment/refresh`,
    payload,
    {
      params: {
        refreshFor: ".FromAccount",
      },
      headers: getHeaders(eTag),
    }
  );
};

export const getDataForSendAmount = async (
  fromAccount,
  sendAmount,
  ReceiveCurrency,
  ReceiversCountryName,
  countryCode,
  nextAssignmentId,
  eTag,
  actionName,
) => {
  const payload = {
    content: {
      EnterAmount: "",
      FromAccount: fromAccount,
      PaymentInfo: {
        CountryCode: countryCode,
        PaymentsPurposeCode: "",
      },
      ReceiveAmount: "",
      ReceiveCurrency: ReceiveCurrency,
      ReceiversCountryName: ReceiversCountryName,
      SendAmount: sendAmount,
    },
    pageInstructions: [],
  };
//ActionInitiatePayment
  return await axios.patch(
    `${BASE_URL}assignments/${nextAssignmentId}/actions/${actionName}/refresh`,
    payload,
    {
      params: {
        refreshFor: ".SendAmount",
      },
      headers: getHeaders(eTag),
    }
  );
};

export const getDataForReceiverCurrency = async (
  fromAccount,
  sendAmount,
  ReceiveAmount,
  receiverCountryName,
  receiveCurrency,
  countryCode,
  nextAssignmentId,
  eTag,
  actionName
) => {
  const payload = {
    content: {
      EnterAmount: "",
      FromAccount: fromAccount,
      PaymentInfo: {
        CountryCode: countryCode,
        PaymentsPurposeCode: "",
      },
      ReceiveAmount: ReceiveAmount,
      ReceiveCurrency: receiveCurrency,
      ReceiversCountryName: receiverCountryName,
      SendAmount: sendAmount,
    },
    pageInstructions: [],
  };

  return await axios.patch(
    `${BASE_URL}assignments/${nextAssignmentId}/actions/${actionName}/refresh`,
    payload,
    {
      params: {
        refreshFor: ".ReceiveCurrency",
      },
      headers: getHeaders(eTag),
    }
  );
};

export const getDataForReceiverAmount = async (
  fromAccount,
  sendAmount,
  receiveAmount,
  receiveCurrency,
  receiverCountryName,
  countryCode,
  nextAssignmentId,
  eTag,
  actionName
) => {
  const payload = {
    content: {
      EnterAmount: "",
      FromAccount: fromAccount,
      PaymentInfo: {
        CountryCode: countryCode,
        PaymentsPurposeCode: "",
      },
      ReceiveAmount: receiveAmount,
      ReceiveCurrency: receiveCurrency,
      ReceiversCountryName: receiverCountryName,
      SendAmount: "",
    },
    pageInstructions: [],
  };

  return await axios.patch(
    `${BASE_URL}assignments/${nextAssignmentId}/actions/${actionName}/refresh`,
    payload,
    {
      params: {
        refreshFor: ".ReceiveAmount",
      },
      headers: getHeaders(eTag),
    }
  );
};

export const getDataForReceiversCountryName = async (
  fromAccount,
  sendAmount,
  receiverCurrency,
  receiverCountryName,
  countryCode,
  nextAssignmentId,
  eTag
) => {
  const payload = {
    content: {
      EnterAmount: "",
      FromAccount: fromAccount,
      PaymentInfo: {
        CountryCode: countryCode,
      },
      ReceiveAmount: "",
      ReceiveCurrency: receiverCurrency,
      ReceiversCountryFlagImg: "",
      ReceiversCountryName: receiverCountryName,
      Remarks: "",
      SendAmount: sendAmount,
      SendCurrencyFlagImg: "",
    },
    pageInstructions: [],
  };

  return await axios.patch(
    `${BASE_URL}assignments/${nextAssignmentId}/actions/ActionInitiatePayment/refresh`,
    payload,
    {
      params: {
        refreshFor: ".ReceiversCountryName",
      },
      headers: getHeaders(eTag),
    }
  );
};

export const getDataForReceiversCountryNameRefresh = async (
  fromAccount,
  sendAmount,
  receiverCurrency,
  receiverCountryName,
  nextAssignmentId,
  eTag
) => {
  const payload = {
    content: {
      EnterAmount: "",
      FromAccount: fromAccount,
      ReceiveAmount: "",
      ReceiveCurrency: receiverCurrency,
      ReceiversCountryFlagImg: "",
      ReceiversCountryName: receiverCountryName,
      Remarks: "",
      SendAmount: sendAmount,
      SendCurrencyFlagImg: "",
    },
    pageInstructions: [],
  };

  return await axios.patch(
    `${BASE_URL}assignments/${nextAssignmentId}/actions/ActionInitiatePayment/refresh`,
    payload,
    {
      headers: getHeaders(eTag),
    }
  );
};

export const getDataForPurposeCodeChange = async (
  fromAccount,
  sendAmount,
  receiverCurrency,
  receiverCountryName,
  receiveAmount,
  paymentsPurposeCode,
  nextAssignmentId,
  eTag
) => {
  const payload = {
    content: {
      EnterAmount: "",
      FromAccount: fromAccount,
      PaymentInfo: {
        CountryCode: "",
        PaymentsPurposeCode: paymentsPurposeCode,
      },
      ReceiveAmount: receiveAmount,
      ReceiveCurrency: receiverCurrency,
      ReceiversCountryFlagImg: "",
      ReceiversCountryName: receiverCountryName,
      Remarks: "",
      SendAmount: sendAmount,
      SendCurrencyFlagImg: "",
    },
    pageInstructions: [],
  };

  return await axios.patch(
    `${BASE_URL}assignments/${nextAssignmentId}/actions/ActionInitiatePayment/refresh`,
    payload,
    {
      params: {
        refreshFor: ".PaymentInfo.PaymentsPurposeCode",
      },
      headers: getHeaders(eTag),
    }
  );
};

export const getDataAfterFirstContinue = async (
  fromAccount,
  sendAmount,
  receiverCurrency,
  receiveAmount,
  receiverCountryName,
  countryCode,
  purposeCode,
  remarks,
  nextAssignmentId,
  eTag
) => {
  // payload condition
  let payload;
  if (purposeCode) {
    payload = {
      content: {
        EnterAmount: "",
        FromAccount: fromAccount,
        PaymentInfo: {
          CountryCode: countryCode,
          PaymentsPurposeCode: purposeCode,
        },
        ReceiveAmount: receiveAmount,
        ReceiveCurrency: receiverCurrency,
        ReceiversCountryName: receiverCountryName,
        SendAmount: sendAmount,
        Remarks: remarks,
      },
      pageInstructions: [],
    };
  } else {
    payload = {
      content: {
        EnterAmount: "",
        FromAccount: fromAccount,
        PaymentInfo: {
          CountryCode: countryCode,
        },
        ReceiveAmount: receiveAmount,
        ReceiveCurrency: receiverCurrency,
        ReceiversCountryName: receiverCountryName,
        SendAmount: sendAmount,
        Remarks: remarks,
      },
      pageInstructions: [],
    };
  }

  return await axios.patch(
    `${BASE_URL}assignments/${nextAssignmentId}/actions/ActionInitiatePayment`,
    payload,
    {
      params: {
        viewType: "page",
      },
      headers: getHeaders(eTag),
    }
  );
};

export const getListOfBeneficiaries = async (receiverCountryName) => {
  const payload = {
    dataViewParameters: {
      Country: receiverCountryName,
      SearchText: "",
    },
  };

  return await axios.post(
    `${BASE_URL}data_views/${endPoints.sendPaymentBeneficiaries.D_Page_Name}`,
    payload,
    {
      headers: getHeaders(),
    }
  );
};

export const getDataForReviewBeneficiary = async (
  pyGuid,
  nextAssignmentId,
  eTag
) => {
  const payload = {
    content: {
      AvailableRecipients: {
        pyGUID: pyGuid,
      },
      AddNewBeneficiary: "",
    },
    pageInstructions: [],
  };

  return await axios.patch(
    `${BASE_URL}assignments/${nextAssignmentId}/actions/ActionEnterBeneficiaryDetails`,
    payload,
    {
      params: {
        viewType: "page",
      },
      headers: getHeaders(eTag),
    }
  );
};

export const validateBeneficiaryDetails = async (
  selectedBeneDetails,
  nextAssignmentId,
  eTag
) => {
  const payload = {
    content: {
      SelectedBeneDetails: selectedBeneDetails,
    },
    pageInstructions: [],
  };

  return await axios.patch(
    `${BASE_URL}assignments/${nextAssignmentId}/actions/ValidateBeneficiaryDetails/refresh`,
    payload,
    {
      params: {
        refreshFor: ".ValidateAndSave",
      },
      headers: getHeaders(eTag),
    }
  );
};

export const validateBeneficiaryDetailsReviewScreen = async (
  nextAssignmentId,
  eTag
) => {
  const payload = {
    content: {
      SelectedBeneDetails: {
        Edit: false,
      },
    },
    pageInstructions: [],
  };

  return await axios.patch(
    `${BASE_URL}assignments/${nextAssignmentId}/actions/ValidateBeneficiaryDetails`,
    payload,
    {
      params: {
        viewType: "page",
      },
      headers: getHeaders(eTag),
    }
  );
};

export const getDataForFirstPreviousPage = async (nextAssignmentId, eTag) => {
  const payload = {
    content: {
      AvailableRecipients: {
        pyGUID: "",
      },
      AddNewBeneficiary: "",
    },
    pageInstructions: [],
  };

  return await axios.patch(
    `${BASE_URL}assignments/${nextAssignmentId}/navigation_steps/previous`,
    payload,
    {
      params: {
        viewType: "page",
      },
      headers: getHeaders(eTag),
    }
  );
};

export const sendPaymentProcessSubmit = async (nextAssignmentId, eTag,actionName) => {
  const payload = {
    content: {
      ConfirmAcknowledgement: true,
      PayAllCharges: true,
    },
    pageInstructions: [],
  };

  const viewType = actionName === 'PayAgain' ? 'form' : 'page';

  return await axios.patch(
    `${BASE_URL}assignments/${nextAssignmentId}/actions/${actionName}`,
    payload,
    {
      params: {
        viewType: viewType,
      },
      headers: getHeaders(eTag),
    }
  );
};


export const payAgainProcessSubmit = async (payload,nextAssignmentId, eTag,actionName) => {

  const viewType = actionName === 'PayAgain' ? 'form' : 'page';

  return await axios.patch(
    `${BASE_URL}assignments/${nextAssignmentId}/actions/${actionName}`,
    payload,
    { 
      params: {
        viewType: viewType,
      },
      headers: getHeaders(eTag),
    }
  );
};

export const sendPaymentFinalSubmit = async (
  eTag,
  caseID
) => {

  return await axios.get(`${BASE_URL}cases/${caseID}/views/PaymentCompleted`, {
    headers: getHeaders(eTag),
  });
};

export const getDataForReviewBenefiToAddBenefiPage = async (
  beneAddress,
  nextAssignmentId,
  eTag
) => {
  const payload = {
    content: {
      SelectedBeneDetails: {
        BeneBankAddress: beneAddress,
        Edit: false,
      },
    },
    pageInstructions: [],
  };

  return await axios.patch(
    `${BASE_URL}assignments/${nextAssignmentId}/navigation_steps/previous`,
    payload,
    {
      params: {
        viewType: "page",
      },
      headers: getHeaders(eTag),
    }
  );
};

export const getDataForReviewToReviewBeneficiaryPage = async (
  nextAssignmentId,
  eTag
) => {
  const payload = {
    content: {
      ConfirmAcknowledgement: false,
      PayAllCharges: false,
    },
    pageInstructions: [],
  };

  return await axios.patch(
    `${BASE_URL}assignments/${nextAssignmentId}/navigation_steps/previous`,
    payload,
    {
      params: {
        viewType: "page",
      },
      headers: getHeaders(eTag),
    }
  );
};

// this method is used to get the country names in add/modify beneficiary
export const getListOfCountries = async () => {
  const payload = {
    dataViewParameters: {
    
    },
  };

  return await axios.post(
    `${BASE_URL}data_views/D_GetCountryName`,
    payload,
    {
      headers: getHeaders(),
    }
  );
};