import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import CurrencyFlag from "react-currency-flags";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import InfoIcon from "@mui/icons-material/Info";
import {
  getDataForSendAmount,
  getDataForReceiverCurrency,
  getDataForReceiverAmount,
  getDataForReceiversCountryName,
  payAgainProcessSubmit,
  sendPaymentFinalSubmit,
} from "../../CommonService/sendPaymentService";
import ReactFlagsSelect, { Jp } from "react-flags-select";
import iso3166 from "iso-3166-1";
import SelectFeild from "react-select";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate, useLocation } from "react-router-dom";
import FinalResponse from "../SendPayment/FinalResponse";
import CountrySpecificComponent from "../SendPayment/CountrySpecificComponent";
import Loader from "../Loader";

const PayAgain = () => {
  const classes = useStyles();
  const location = useLocation();

  const { state } = location;
  const [accounts, setAccounts] = useState([]);
  const [payAgainData] = useState(state);
  const [account, setAccount] = useState("");
  const [isShowWarning, setShowWarning] = useState(false);
  const [showExchangeFields, setShowExchangeFields] = useState(false);
  const [purposeCode, setPurposeCode] = useState("");
  const [purposeCodeList, setPurposeCodeList] = useState("");
  const [flagselect, setflagselect] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const [avaiableToPayCurrencyList, setAvailableToPayCurrencyList] = useState(
    []
  );
  const [setValidateCurrency] = useState("");
  const [nextAssignmentId, setnextAssignmentId] = useState();
  const [caseId, setCaseId] = useState();
  const [eTag, setEtag] = useState();
  const [availableToPayCountryNamesList, setAvailableToPayCountryNamesList] =  useState([]);
  const [payAgainEtag] = useState(state?.etag);
  const [editableBeneDetails] = useState(state?.response?.data.caseInfo?.content?.SelectedBeneDetails);
  const [actionName,setActionName] = useState(state?.response?.data.caseInfo?.content?.pyViewName);
  const [finalResponse, setFinalResponse] = useState();
  const [isFinalResponse, setIsFinalResponse] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [disableProps, setDisbaleProps] = useState(false);
  const [loading, setLoading] = useState(true);

  if("repeatPayment" === actionName ){
    setDisbaleProps(true);
  }

  const [checkbox_Details] = useState(
    state?.response?.uiResources.resources.views?.PayAgain[0]?.children[0]
      .children
  );

  const [accountDetails, setAccountDetails] = useState({
    FromAccount: "",
    AccountBalance: "",
    SendAmount: "",
    ReceiveAmount: "",
    SendCurrency: "",
    CurrencyConvDesc: "",
    FXRateGoodForText: "",
    ReceiversCountryName: "",
    ReceiveCurrency: "",
  });

  const euroCountries = [
    "DE",
    "FR",
    "ES",
    "IT",
    "NL",
    "BE",
    "AT",
    "FI",
    "PT",
    "IE",
    "GR",
    "CY",
    "LU",
    "MT",
    "SK",
    "SI",
    "EE",
    "LT",
    "LV",
  ];
  const flagToCountryMap = {
    US: "United States",
    CA: "Canada",
    GB: "United Kingdom",
    JP: "Japan",
    CN: "China",
    QA: "Qatar",
    EU: euroCountries,
  };
  const matchCountries = (countryName) => {
    for (const [code, fullCountryName] of Object.entries(flagToCountryMap)) {
      if (countryName === fullCountryName) {
        return code;
      }
    }
  };

  useEffect(() => {
    const initialCheckedState = checkbox_Details.reduce((acc, data) => {
      if (data?.type === "Checkbox" && data?.config?.value) {
        // Assuming initial checked state can be based on some condition (e.g., if value starts with '@P .')
        acc[data.config.value.replace(/^@P \./, "")] = false; // Or true if you want it checked initially
      }
      return acc;
    }, {});
    setCheckedItems(initialCheckedState); // Set initial checked state
  }, [checkbox_Details]);

  function getAccounts(response) {
    const list = response.shared.D_GetAccountList;
    const keys = Object.keys(list);
    let pxResults = [];

    for (const key of keys) {
      if (key.startsWith("D_GetAccountList_")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  function getCountryNamesList(response) {
    const list = response.shared.D_GetCountryName;
    const keys = Object.keys(list);
    let pxResults = [];

    for (const key of keys) {
      if (key.startsWith("D_GetCountryName")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  const handleCheckbox = (event) => {
    const { name, checked } = event.target;
    setCheckedItems((prevState) => ({
      ...prevState,
      [name]: checked, // Toggle the checked state of the checkbox
    }));
  };

  useEffect(() => {
    setnextAssignmentId(payAgainData?.response?.nextAssignmentInfo?.ID);
    setCaseId(payAgainData?.response?.data.caseInfo?.ID.split(" ")[1]);
    setAvailableToPayCountryNamesList(
      getCountryNamesList(payAgainData?.response?.data)
    );
    let pxResults = getAccounts(payAgainData?.response?.data);
    setAccounts(pxResults);
    setAvailableToPayCurrencyList(
      payAgainData?.response?.data.shared.D_AvailabletoPayCurrencyListAPIList
        .D_AvailabletoPayCurrencyListAPIList.pxResults
    );
    setEtag(payAgainEtag);

    setAccount(payAgainData?.response?.data.caseInfo?.content?.FromAccount);
    matchCountries(
      payAgainData?.response?.data.caseInfo?.content?.ReceiversCountryName
    );
    setflagselect(
      matchCountries(
        payAgainData?.response?.data.caseInfo?.content?.ReceiversCountryName
      )
    );
    const option = {
      label: payAgainData?.response?.data.caseInfo?.content?.ReceiveCurrency,
      value: payAgainData?.response?.data.caseInfo?.content?.ReceiveCurrency,
    };
    setSelectedCurrency(option);
    setShowExchangeFields(true);
    if (payAgainData?.response?.data?.shared?.D_PurposeCodeList) {
      setPurposeCodeList(
        getPurposeCodeList(payAgainData?.response.data.shared)
      );
    } else {
      setPurposeCodeList("");
    }
    const newDetails = payAgainData?.response?.data.caseInfo?.content;
    setAccountDetails((prevDetails) => ({
      ...prevDetails,
      ...newDetails,
    }));
    setLoading(false);
  }, [navigate]);

  useEffect(() => {
    const selectedvalue = selectedCurrency?.value?.slice(0, 2);
    if (flagselect && selectedvalue) {
      setShowWarning(flagselect !== selectedvalue);
    } else {
      setShowWarning(false);
    }
  }, [flagselect, selectedCurrency]);

  function getPurposeCodeList(response) {
    const list = response.D_PurposeCodeList;
    const keys = Object.keys(list);
    let pxResults = [];
    for (const key of keys) {
      if (key.startsWith("D_PurposeCodeList")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  const handlePurposeCode = (event) => {
    const purposeCode = event.target.value;
    setPurposeCode(purposeCode);
  };

  const handleAmountInputChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);
    if (value === "" || !isNaN(parsedValue)) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));

      setAccountDetails((prev) => ({
        ...prev,
        SendAmount: value,
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Please enter a valid number.",
      }));
    }

    if (parsedValue < 0) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Send Amount must be greater than zero.",
      }));
    } else if (parsedValue > parseFloat(accountDetails?.AccountBalance)) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Send Amount should not exceed Available balance.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));
    }
  };

  const handleAmountInputBlur = () => {
    const amount = parseFloat(accountDetails.SendAmount);
    if (!isNaN(amount) && !error?.amountYouSendError) {
      getDataForSendAmount(
        account,
        amount,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiversCountryName,
        flagselect,
        nextAssignmentId,
        eTag,
        actionName
      )
        .then((response) => {
          const resData = response.data.data.caseInfo.content;
          setAccountDetails({
            ...accountDetails,
            CurrencyConvDesc: resData.CurrencyConvDesc,
            FXRateGoodForText: resData.FXRateGoodForText,
            TransactionDesc: resData.TransactionDesc,
            SendAmount: resData.SendAmount,
            ReceiveAmount: resData.ReceiveAmount,
          });
          setShowExchangeFields(true);
        })
        .catch((error) => {
          if (error.status == "401") {
            navigate("/login");
          } else {
            console.log("Promise rejected with error:", error.message);
          }
        });
    }
  };

  const handleReceiveAmountInputChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);
    if (!isNaN(value) || value === "") {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));

      setAccountDetails((prev) => ({
        ...prev,
        ReceiveAmount: value,
      }));
    }

    if (parsedValue < 0) {
      setError((prevState) => ({
        ...prevState,
        amountEnteredError: "Amount Entered must be greater than zero.",
      }));
    } else if (parsedValue > parseFloat(accountDetails?.AccountBalance)) {
      setError((prevState) => ({
        ...prevState,
        amountEnteredError:
          "Amount Entered should not exceed Available balance.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));
    }
  };

  const handleReceiveAmountInputBlur = () => {
    const amount = parseFloat(accountDetails.ReceiveAmount);
    if (!isNaN(amount) && accountDetails.ReceiveCurrency) {
      getDataForReceiverAmount(
        account,
        accountDetails.SendAmount,
        amount,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiversCountryName,
        flagselect,
        nextAssignmentId,
        eTag,
        actionName
      ).then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          SendAmount: resData.SendAmount,
        });
        setShowExchangeFields(true);
      });
    }
  };

  const handleReceiverCurrencyChange = (option) => {
    const newCurrency = option.value;
    setSelectedCurrency(option);
    setAccountDetails((prev) => ({
      ...prev,
      ReceiveCurrency: newCurrency,
    }));
    getDataForReceiverCurrency(
      account,
      accountDetails.SendAmount,
      accountDetails.ReceiveAmount,
      accountDetails.ReceiversCountryName,
      newCurrency,
      flagselect,
      nextAssignmentId,
      eTag,
      actionName
    )
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          ReceiveCurrency: resData.ReceiveCurrency,
          ReceiversCountryName: resData.ReceiversCountryName,
          SendAmount: resData.SendAmount,
        });
        //setSelectedCountryName(resData.ReceiversCountryName);
      })
      .catch((err) => {
        console.log(err.response.data.errorDetails[0].message);
        setValidateCurrency(err.response.data.errorDetails[0].message);
      });
  };

  const countriesToShow = availableToPayCountryNamesList
    .map((item) => item.Country)
    .map((country) => {
      const normalizedCountry = country.trim().toLowerCase();

      const countryObj = iso3166.all().find((item) => {
        const isoCountryName = item.country.toLowerCase();

        return isoCountryName.includes(normalizedCountry);
      });

      if (!countryObj) {
        console.warn(`Country code not found for ${country}`);
        return null;
      }

      const countryCode = countryObj.alpha2;
      return countryCode;
    })
    .filter(Boolean);

  const handleReceiverCountrySelect = (code) => {
    setflagselect(code);
    const countryName = flagToCountryMap[code] || "";
    setAccountDetails((prev) => ({
      ...prev,
      ReceiversCountryName: countryName,
    }));

    getDataForReceiversCountryName(
      account,
      accountDetails.SendAmount,
      accountDetails.ReceiveAmount,
      countryName,
      code,
      nextAssignmentId,
      eTag
    )
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          ReceiveCurrency: resData.ReceiveCurrency,
          ReceiversCountryName: resData.ReceiversCountryName,
          SendAmount: resData.SendAmount,
        });
        if (response?.data?.data?.shared?.D_PurposeCodeList) {
          setPurposeCodeList(getPurposeCodeList(response.data.data.shared));
        } else {
          setPurposeCodeList("");
        }
      })
      .catch((err) => {
        console.log(err);
        setValidateCurrency(err.response.data.errorDetails[0].message);
      });
  };

  const validateAmount = () => {
    const sendAmount = parseFloat(accountDetails?.SendAmount);
    const receiveAmount = parseFloat(accountDetails?.ReceiveAmount);
    if (
      isNaN(sendAmount) ||
      sendAmount <= 0 ||
      isNaN(receiveAmount) ||
      receiveAmount <= 0
    ) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError:
          "Send Amount must be a valid number greater than zero.",
        amountEnteredError:
          "Amount Entered must be a valid number greater than zero.",
      }));
    } else if (
      sendAmount > accountDetails?.AccountBalance ||
      receiveAmount > accountDetails?.AccountBalance
    ) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError:
          "Send Amount should not greater than Available balance",
        amountEnteredError:
          "Amount Entered should not greater than Available balance",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));
    }
  };

  const validateRemarks = () => {
    if (remarks.trim() === "") {
      setError((prevState) => ({
        ...prevState,
        remarksError: "Remarks are required.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        remarksError: "",
      }));
    }
  };
  const validateForm = async () => {
    validateRemarks();
    validateAmount();
    if (!error.amountYouSendError && !error.remarksError) {
      //need to Write Handlesubmit logic
      try {
        const payLoad = {
          content: {
            EnterAmount: "",
            ReceiveAmount: accountDetails.ReceiveAmount,
            ReceiveCurrency: accountDetails.ReceiveCurrency,
            ReceiversCountryName: accountDetails.ReceiversCountryName,
            SendAmount: accountDetails.SendAmount,
            ConfirmAcknowledgement: checkedItems.ConfirmAcknowledgement,
            PayAllCharges : checkedItems.PayAllCharges,
            //Remarks : remarks
          },
          pageInstructions: [],
        };
        const response = await payAgainProcessSubmit(
          payLoad,
          nextAssignmentId,
          eTag,
          actionName
        );
        if (response.status === 200) {
          const res = await sendPaymentFinalSubmit(
            eTag,
            response?.data?.data?.caseInfo?.ID
          );
          if (res?.data?.data?.caseInfo) {
            setFinalResponse(res?.data?.data?.caseInfo);
            setIsFinalResponse(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Form is invalid.", error);
    }
  };

  const options = avaiableToPayCurrencyList.map((currency) => ({
    value: currency.CurrencyName,
    label: currency.CurrencyName,
  }));

  const formatOptionLabel = ({ value }) => (
    <div className={classes.option}>
      {value && <CurrencyFlag currency={value} />}
      <span className={classes.optionValue}>{value}</span>
    </div>
  );

  const handleCancel = () => {
    navigate("/recentpayments");
  };

  return (
    <>
    {loading ? (<Loader />)
      : (
      <>
      {!isFinalResponse && (
        <>
          <Box className={classes.rootContainer}>
            <Grid size={{ md: 12, xs: 12 }}>
              <Typography
                className={classes.title}
                variant="h6"
                color="textPrimary"
                gutterBottom
              >
                Send Payment ({actionName})
              </Typography>
              <Divider />
            </Grid>
            <FormControl className={classes.formControl}>
              <InputLabel className={classes.inputLable}>
                Select Account To Make Payment From
              </InputLabel>
              <Select
                id="Account"
                variant="standard"
                value={account}
                inputProps={{ className: classes.acctInput }}
                label="Select Account To Make Payment From"
                disableUnderline
                displayEmpty
                disabled
                /* className={classes.itemWidth} */
              >
                {accounts &&
                  accounts.map((acc) => (
                    <MenuItem key={acc.AccountNumber} value={acc.AccountNumber}>
                      {acc.MaskAccountNumber}{" "}
                      {/* Masked account number shown */}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            {account ? (
              <>
                <Grid container className={classes.spacingCont}>
                  <Grid
                    size={{ md: 12, xs: 12 }}
                    className={`${classes.flex} ${classes.Amountcolor}`}
                  >
                    <Typography className={classes.Availablebal}>
                      Available Balance:
                    </Typography>
                    <Typography variant="body2">
                      &nbsp;{accountDetails.AccountBalance}
                      {/* {accountDetails.SendCurrency} */}
                    </Typography>
                    &nbsp;
                    <CurrencyFlag
                      currency={accountDetails?.SendCurrency}
                      className={classes.CurrencyFlag}
                    />
                    <span className={classes.Sendcurrency}>
                      {accountDetails?.SendCurrency}
                    </span>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container className={classes.spacing}>
                  <Grid size={{ md: 5, xs: 12 }}>
                    <Typography variant="body2" className={classes.Fontweight}>
                      What country are the funds being sent to ?
                    </Typography>
                    <ReactFlagsSelect
                      selected={flagselect}
                      onSelect={handleReceiverCountrySelect}
                      countries={countriesToShow}
                      searchable
                      searchPlaceholder="Search by Country Name"
                      className={classes.sent}
                      disabled
                    />
                  </Grid>
                  <Grid size={{ md: 7, xs: 12 }}>
                    {isShowWarning ? (
                      <>
                        <Box className={classes.flexAlignCenter}>
                          <InfoIcon className={classes.Infoicon} />
                          <Typography
                            variant="body2"
                            className={classes.Infotext}
                          >
                            Paying funds to a country in a currency other than
                            its domestic currency may result in the funds being
                            converted by the beneficiary bank before they are
                            applied to the beneficiary's account if that account
                            is not held in the currency of the payment. Please
                            be sure you want to proceed before continuing.
                          </Typography>
                        </Box>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
                {flagselect && (
                  <Grid
                    container
                    className={classes.spacingCont}
                    alignItems="center"
                  >
                    <Grid size={{ md: 5, xs: 12 }}>
                      <Grid container className={classes.spacingCont}>
                        <Grid className={classes.flex}>
                          <Typography
                            variant="body2"
                            className={classes.textstyle}
                          >
                            Amount You Send
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container /* className={classes.spacingCont} */>
                        <Grid className={classes.flex}>
                          <TextField
                            variant="outlined"
                            size="small"
                            className={`${classes.sendAmountTextField} ${classes.itemWidth}`}
                            onChange={handleAmountInputChange}
                            onBlur={handleAmountInputBlur}
                            value={accountDetails.SendAmount}
                            disabled = {disableProps}
                            slotProps={{
                              input: {
                                className: classes.borderstyle,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <CurrencyFlag
                                      currency={accountDetails?.SendCurrency}
                                      className={classes.CurrencyFlag}
                                    />
                                  </InputAdornment>
                                ),
                              },
                            }}
                          ></TextField>
                        </Grid>
                        {error.amountYouSendError && (
                          <div className="error-text">
                            {error.amountYouSendError}
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid size={{ md: 2 }} className={classes.flexCenter}>
                      <SwapHorizIcon className={classes.Swaphorizicon} />
                    </Grid>
                    <Grid size={{ md: 5, xs: 12 }}>
                      <Grid container className={classes.spacingCont}>
                        <Grid className={classes.flex}>
                          <Typography
                            variant="body2"
                            className={classes.Fontweight}
                          >
                            Beneficiary Gets
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid size={{ xs: 12, md: 5 }}>
                          <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            onChange={handleReceiveAmountInputChange}
                            onBlur={handleReceiveAmountInputBlur}
                            value={accountDetails.ReceiveAmount}
                            className={classes.itemWidth}
                            disabled = {disableProps}
                            slotProps={{
                              input: {
                                className: classes.borderstyle,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <FormControl fullWidth>
                                      <SelectFeild
                                        options={options}
                                        value={selectedCurrency}
                                        onChange={handleReceiverCurrencyChange}
                                        formatOptionLabel={formatOptionLabel}
                                        isSearchable={true}
                                        placeholder="Select Currency"
                                        className={classes.currencyCodeSelect}
                                        disabled = {disableProps}
                                        styles={{
                                          control: (provided) => ({
                                            ...provided,
                                            width: "180px",
                                            height: "6vh",
                                            borderRadius: "8px",
                                            border: "none",
                                            boxShadow: "none",
                                            "&:hover": {
                                              border: "none",
                                            },
                                          }),
                                        }}
                                      />
                                    </FormControl>
                                  </InputAdornment>
                                ),
                              },
                            }}
                          />
                        </Grid>
                        {error.amountEnteredError && (
                          <div className="error-text">
                            {error.amountEnteredError}
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {flagselect && showExchangeFields && (
                  <Grid
                    container
                    spacing={2}
                    className={`${classes.spacing} ${classes.exchangefields}`}
                  >
                    <Grid
                      size={{ md: 12, xs: 12 }}
                      className={classes.flexAround}
                    >
                      <Grid container>
                        <Grid size={{ md: 4 }}>
                          <div className={classes.label}>
                            <Typography variant="body2">
                              Transaction Fee
                            </Typography>
                            <InputBase
                              inputProps={{ "aria-label": "naked" }}
                              value={accountDetails.TransactionDesc}
                              className={classes.inputBase}
                            />
                          </div>
                        </Grid>
                        <Grid size={{ md: 4 }}>
                          {" "}
                          <div className={classes.label}>
                            <Typography variant="body2">
                              Exchange Rate
                            </Typography>
                            <InputBase
                              inputProps={{ "aria-label": "naked" }}
                              value={accountDetails.CurrencyConvDesc}
                              className={classes.inputBase}
                            />
                          </div>
                        </Grid>
                        <Grid size={{ md: 4 }}>
                          <div className={classes.label}>
                            <Typography variant="body2">
                              Exchange Rate Valid For
                            </Typography>
                            <InputBase
                              inputProps={{ "aria-label": "naked" }}
                              value={accountDetails.FXRateGoodForText}
                              className={classes.inputBase}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid container spacing={1}>
                  {purposeCodeList && (
                    <Grid size={{ xs: 12, md: 6 }} className={classes.purpose}>
                      <Typography
                        variant="body2"
                        className={classes.headerstyle}
                      >
                        Purpose of Payment
                      </Typography>

                      <TextField
                        id="PurposeCode"
                        variant="outlined"
                        value={purposeCode}
                        size="small"
                        onChange={handlePurposeCode}
                        slotProps={{
                          input: { className: classes.purposeCodeDropdown },
                        }}
                        //label="Select Pay Purpose"
                        select
                        fullWidth
                      >
                        {purposeCodeList &&
                          purposeCodeList.map((pCode) => (
                            <MenuItem
                              key={pCode.PayPurPCodeDesc}
                              value={pCode.PayPurPCodeDesc}
                            >
                              {pCode.PayPurPCode}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                  )}
                  {flagselect && (
                    <Grid size={{ md: 6, xs: 12 }} className={classes.purpose}>
                      <Typography
                        variant="body2"
                        className={classes.headerstyle}
                      >
                        Remarks <span className={classes.mandatory}>*</span>
                      </Typography>
                      <TextField
                        id="remarks"
                        //label="Remarks"
                        multiline
                        rows={1}
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                        fullWidth
                      />
                      {error.remarksError && (
                        <div className="error-text">{error.remarksError}</div>
                      )}
                    </Grid>
                  )}
                </Grid>

                <CountrySpecificComponent
                  editableBeneDetails={editableBeneDetails}
                  payAgain={true}
               />
              </>
            ) : (
              <></>
            )}

            {checkbox_Details.map((data, index) => {
              if (data?.type === "Checkbox") {
                const checkboxName = data?.config?.value.replace(/^@P \./, "");
                return (
                  <Box key={data.id} className={classes.spacing}>
                    <Grid className={classes.flex}>
                      <Checkbox
                        name={checkboxName}
                        className={classes.checkboxStyles}
                        checked={!!checkedItems[checkboxName]}
                        onChange={handleCheckbox}
                      />
                      <Typography variant="body2" className={classes.textFont}>
                        {data.config.caption.replace(/@L\s*/, "")}
                      </Typography>
                    </Grid>
                  </Box>
                );
              }
              return null;
            })}

            <Grid container spacing={2} className={classes.spacingCont}>
              <Grid size={{ xs: 12, md: 12 }} className={classes.flexBetween}>
                <Button className={classes.cancel} onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className={classes.continue}
                  disabled={
                    !checkedItems.ConfirmAcknowledgement &&
                    !checkedItems.PayAllCharges
                  }
                  onClick={validateForm}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {isFinalResponse && (
        <FinalResponse finalResponse={finalResponse} />
      )}
      </>
      )
    }
    </>
  );
};

export default PayAgain;
