import React from "react";
import useStyles from "./styles";
import CurrencyFlag from "react-currency-flags";
import { useState } from "react";
import {
  sendPaymentProcessSubmit,
  getDataForReviewToReviewBeneficiaryPage,sendPaymentFinalSubmit
} from "../../CommonService/sendPaymentService";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import checkMark from "../../../assets/images/check_mark_green.svg";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  Tooltip
} from "@mui/material";
import Grid from "@mui/material/Grid2";

const Review = ({
  onContinue,
  nextAssignmentId,
  eTag,
  setEtag,
  reviewDetails,
  onPrevious,
  setPreviousData,
  setFinalResponse
}) => {
  const classes = useStyles();
  const [Checkcount, setcheckcount] = useState(0);
  const [checkbox, setcheckbox] = useState({
    check1: false,
    check2: false,
  });
  const [visible, setvisibile] = useState(true);
  const [transaction, settransaction] = useState(true);
  const [beniDetails, setbeniDetails] = useState(true);
  const [icon, seticon] = useState(false);
  const [removeicon, setremoveicon] = useState(false);
  const [beniIcon, setbeniIcon] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handlePrevious = async () => {
    try {
      const response = await getDataForReviewToReviewBeneficiaryPage(
        nextAssignmentId,
        eTag
      );
      setEtag(response.headers["etag"]);
      const resourceData = response.data.data;
      setPreviousData(resourceData);
      onPrevious();
    } catch {}
  };

  const handleSubmit = async () => {
    try {
      setOpen(true);
      const response = await sendPaymentProcessSubmit(nextAssignmentId, eTag);
      if (response.status === 200) {
        //setOpen(true);
        const res = await sendPaymentFinalSubmit(nextAssignmentId, eTag,response?.data?.data?.caseInfo?.ID);
        console.log(res)
        if(res?.data?.data?.caseInfo){
          setFinalResponse(res?.data?.data?.caseInfo)
          // setOpen(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckbox = (event) => {
    setcheckbox((check) => ({
      ...check,
      [event.target.name]: event.target.checked,
    }));
    setcheckcount((prev) => (event.target.checked ? prev + 1 : prev - 1));
  };

  const handlevisibility = () => {
    if (visible) {
      setvisibile(false);
    } else {
      setvisibile(true);
    }
    seticon((prev) => !prev);
  };
  const TransactioinVisible = () => {
    if (transaction) {
      settransaction(false);
    } else {
      settransaction(true);
    }
    setremoveicon((prev) => !prev);
  };
  const beneficiaryVisible = () => {
    if (beniDetails) {
      setbeniDetails(false);
    } else {
      setbeniDetails(true);
    }
    setbeniIcon((prev) => !prev);
  };
  return (
    <div>
      <Grid container className={classes.header}>
        <Grid size={{xs:12}} className={classes.headerDetails}>
          <Typography variant="body2">Account Details</Typography>{" "}
          {!icon && <RemoveCircleRoundedIcon onClick={handlevisibility} />}
          {icon && <AddCircleRoundedIcon onClick={handlevisibility} />}
        </Grid>
      </Grid>
      {visible && (
        <>
          <Box /* className={classes.spacing} */>
            <Grid size={{xs:12,md:6}} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Account to be Debited
              </Typography>
              <Box>
                <Typography variant="body2" className={classes.contentstyle}>
                  {reviewDetails.MaskAccountNumber}
                </Typography>
              </Box>
            </Grid>
            <Grid size={{xs:12,md:6}} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Available Balance
              </Typography>
              <Box className={classes.flex}>
                <Typography variant=" body2" className={classes.contentstyle}>
                  {reviewDetails.AccountBalance}
                </Typography>
                <Box style={{ marginLeft: "10px" }}>
                  {reviewDetails.SendCurrency}
                </Box>
                <CurrencyFlag
                  currency={reviewDetails.ReceiveCurrency}
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50px",
                  }}
                />
              </Box>
            </Grid>
          </Box>
        </>
      )}
      <Grid container className={classes.header}>
        <Grid size={{xs:12}} className={classes.headerDetails} >
          <Typography variant="body2"> Transaction Details</Typography>{" "}
          {!removeicon && (
            <RemoveCircleRoundedIcon onClick={TransactioinVisible} />
          )}
          {removeicon && <AddCircleRoundedIcon onClick={TransactioinVisible} />}
        </Grid>
      </Grid>
      {transaction && (
        <>
          <Box /* className={classes.spacing} */>
            <Grid container className={classes.flex}>
              <Grid size={{xs:12,md:6}} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Amount to be sent
                </Typography>
                <Box className={classes.flex}>
                  <Typography className={classes.contentstyle}>
                    {reviewDetails.ReceiveAmount}
                  </Typography>
                  <Box style={{ marginLeft: "5px" }}>
                    {reviewDetails.SendCurrency}
                  </Box>
                  <CurrencyFlag
                    currency={reviewDetails.ReceiveCurrency}
                    style={{
                      marginLeft: "5px",
                      width: "20px",
                      height: "20px",
                      borderRadius: "50px",
                    }}
                  />
                </Box>
              </Grid>

              <Grid size={{xs:12,md:6}} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Transaction Fee
                </Typography>
                <Box className={classes.flex}>
                  <Typography variant="body2" style={{ fontsize: "13px" }}>
                    {" "}
                    {reviewDetails.TransactionDesc}
                  </Typography>
                  <CurrencyFlag
                    currency={reviewDetails.ReceiveCurrency}
                    style={{
                      marginLeft: "5px",
                      width: "20px",
                      height: "20px",
                      borderRadius: "50px",
                    }}
                  />
                </Box>
              </Grid>

              <Grid size={{xs:12,md:6}} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Foreign Exchange Rate
                </Typography>
                <Typography style={{ fontSize: "13px" }}>
                  {reviewDetails.CurrencyConvDesc}
                </Typography>
              </Grid>

              <Grid size={{xs:12,md:6}} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Amount to be Received
                </Typography>
                <Box className={classes.flex}>
                  <Typography className={classes.contentstyle}>
                    {reviewDetails.ReceiveAmount}
                  </Typography>
                  <Box style={{ marginLeft: "5px" }}>
                    {reviewDetails.SendCurrency}
                  </Box>
                  <CurrencyFlag
                    currency={reviewDetails.ReceiveCurrency}
                    style={{
                      marginLeft: "5px",
                      width: "20px",
                      height: "20px",
                      borderRadius: "50px",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      <Grid size={{xs:12,md:6}}>
        <Typography variant="body2" className={classes.headerstyle}>
          Estimated Delivery Time{" "}
          <Tooltip title="This is an estimated delivery time frame only based on how long similar payments have taken in the past. It is not a gaurantee that your payment will be delivered within this timeframe, which could take more or less time than this estimate.">
            <InfoOutlinedIcon style={{ color: "blue", fontSize: "1rem" }} />
          </Tooltip>
        </Typography>
        <Typography style={{ fontSize: "13px" }}>
          {reviewDetails.EstimatedDeliveryTime}
        </Typography>
      </Grid>

      {/*   <Box className={classes.spacing}>
            <Box className={classes.flex}>
                <InfoOutlined style={{color:"blue", fontSize:"1.2rem"}} /><Typography variant='body2' style={{marginLeft:"5px"}}>Enter BIC Code or ABA Number</Typography>
            </Box>  
        </Box> */}
      <Grid size={{xs:12,md:6}}>
        <Typography variant="body2" className={classes.headerstyle}>
          Exchange Rate Valid For
        </Typography>
        <Typography variant="small">
          {reviewDetails.FXRateGoodForText}
        </Typography>
      </Grid>

      <Grid container className={classes.header}>
        <Grid size={{xs:12}} className={classes.headerDetails} >
          <Typography variant="body2"> Beneficiary and Bank Details</Typography>{" "}
          {!beniIcon && (
            <RemoveCircleRoundedIcon onClick={beneficiaryVisible} />
          )}
          {beniIcon && <AddCircleRoundedIcon onClick={beneficiaryVisible} />}
        </Grid>
      </Grid>
      {beniDetails && (
        <>
          <Box className={classes.spacing}>
            <Grid container size={{xs:12,md:12}}>
              <Grid size={{xs:12,md:6}}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Name
                </Typography>
                <Typography style={{ fontSize: "13px" }}>
                  {reviewDetails.SelectedBeneDetails?.FullName}
                </Typography>
              </Grid>
              <Grid size={{xs:12,md:6}}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Account Number
                </Typography>
                <Typography variant="body2" style={{ fontSize: "13px" }}>
                  {reviewDetails.SelectedBeneDetails.MaskAccNumb}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.spacing}>
            <Grid container size={{xs:12,md:12}}>
              <Grid size={{xs:12,md:6}}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Bank BIC Code
                </Typography>
                <Typography style={{ fontSize: "13px" }}>
                  {reviewDetails.SelectedBeneDetails.BICFI}
                </Typography>
              </Grid>
              <Grid size={{xs:12}}>
                <Typography className={classes.headerstyle}>
                  ABA Routing Number
                </Typography>
                <Typography className={classes.contentstyle}>--</Typography>
              </Grid>
              <Grid size={{xs:12,md:6}}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Bank Address
                </Typography>
                <Typography variant="body2" style={{ fontSize: "13px" }}>
                  {" "}
                  {reviewDetails.SelectedBeneDetails.BeneBankAddress}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {/* <Box className={classes.spacing}>
            <Typography variant='body2' className={classes.headerstyle}>Account Type</Typography>
            <Typography variant='body2' style={{fontSize:'13px'}}> {reviewDetails.SelectedBeneDetails.CdtrAcctTpPrtry}</Typography>
        </Box>
        <Box className={classes.spacing}>
                <Grid className={classes.flex}>
                    <Typography variant='body2' className={classes.headerstyle}>Payment Purpose Code</Typography>
                    <Typography variant='body2' style={{marginLeft:"34%"}}> {reviewDetails.PaymentPurposeCode_RVW}</Typography>
                </Grid>
        </Box> 
        */}
      <Box className={classes.spacing}>
        <Grid className={classes.flex}>
          <Checkbox
            name="check1"
            checked={checkbox.check1}
            onChange={handleCheckbox}
          />
          <Typography variant="body2" style={{ fontSize: "13px" }}>
            I confirm that the information provided about this beneficiary is
            accurate and complete.Furthermore, i acknowledge that the transfer
            maybe refused or delayed by the beneficiary's bank if the
            information is inaccurate or incomplete,which may result in delays,
            additional fees or rejection of the payment.
          </Typography>
        </Grid>
      </Box>
      <Box className={classes.spacing}>
        <Grid className={classes.flex}>
          <Checkbox
            name="check2"
            checked={checkbox.check2}
            onChange={handleCheckbox}
          />
          <Typography variant="body2" style={{ fontSize: "13px" }}>
            {" "}
            Cross-border payments sometimes result in fees being deducted by
            overseas, intermediary banks .Do You want to pay these overseas bank
            charges so that the beneficiary receives the full amount? Note: Your
            account will be debited for the actual value of the overseas charges
            if/when they are incurred.
          </Typography>
        </Grid>
      </Box>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.spacing}
      >
        <Box className={classes.flex}>
          <Button className={classes.cancel}>Cancel</Button>
          <Button
            className={classes.cancel}
            onClick={handlePrevious}
            style={{ marginLeft: "10px" }}
          >
            Back
          </Button>
        </Box>
        <Grid >
          <Button
            className={classes.continue}
            onClick={handleSubmit}
            disabled={Checkcount !== 2}
          >
            Send Payment
          </Button>
        </Grid>
      </Grid>
     {/*  <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Link to={"/recentpayments"}>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="close"
              style={{ position: "absolute", right: 8, top: 8 }} // Position the close icon
            >
              <CloseIcon />
            </IconButton>
          </Link>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className={classes.successDialog}>
              <img src={checkMark} width={100} height={100} />
              <Typography className={classes.txt1}>Money Sent</Typography> */}
              {/* <Typography className={classes.txt2}>Your transfer will be processed in next 30 minutes</Typography> */}
         {/*    </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions> */}
          {/* <Button onClick={handleClose}>Home</Button> */}
      {/*   </DialogActions>
      </Dialog> */}
    </div>
  );
};

export default Review;
