import { makeStyles } from "@mui/styles";

const drawerWidth = "22%";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  backdrop: {
    background: "none !important",
  },
  drawerPaper: {
    backgroundColor: "#52459f !important",
    color: "#fff !important",
    width: drawerWidth,
    alignItems: "center",
    justifyContent: "start",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme?.transitions.create("width", {
      easing: theme?.transitions.easing.sharp,
      duration: theme?.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(9),
    },
    height: "100vh",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  mainMenu: {
    display: "flex",
    borderBottom: "2px #ffffff80 dotted",
    paddingTop: '0px !important',
    paddingBottom: '0px !important'
  },
  userName: {
    "& span": {
      fontWeight: "bold",
    },
  },
  linkStyle: {
    color: "#fff",
    textDecoration: "none",
    fontSize: "14px",
    fontWeight: "bold",
    "&:hover": {
      fontSize: "14.5px !important",
    },
  },
  txt: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    "& span": {
      fontSize: "14px !important",
      fontWeight: "bold",
      cursor: "pointer",
      "&:hover": {
        fontSize: "14.5px !important",
      },
    },
  },
  nested: {
    borderBottom: "2px #ffffff80 dotted",
    backgroundColor: "#52459f",
    color: "#fff !important",
    padding: "10px !important",
  },
  nestedList: {
    borderBottom: "2px #ffffff80 dotted",
    backgroundColor: "#52459f",
    color: "#fff !important",
    padding: "12px 0px 12px 24px !important",
  },
  appListItem: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  appListLogo: {
    // marginRight: theme.spacing(2),
    marginLeft: "40px",
    width: "130px",
    height: "5.1rem",
    // paddingBottom: "10px",
  },
  appListIcon: {
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
  appListDiv: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logoutBtn: {
    backgroundColor: "#fff !important",
    color: "#52459f !important",
    fontSize: "15px !important",
    fontWeight: "bold !important",
    padding: "5px 50px 5px 50px !important",
    cursor: "pointer !important",
    marginLeft: "30px !important",
    border: "none !important",
    borderRadius: "20px !important",
    marginTop: "30px !important",
    textTransform: "none !important",
    "&:hover": {
      backgroundColor: "#52459f !important",
      color: "#fff !important",
      border: "1px solid #ffff !important",
    },
  },
  applicationLabel: {
    whiteSpace: "initial",
  },
  appBar: {
    width: "100% !important",
    background: "#fff !important",
    borderBottom: " 2px solid #e6eff5 !important",
    boxShadow: "0 2px 2px rgba(0, 0, 0, 0.01) !important",
    transition: theme?.transitions.create(["margin", "width"], {
      easing: theme?.transitions.easing.sharp,
      duration: theme?.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme?.transitions.create(["margin", "width"], {
      easing: theme?.transitions.easing.easeOut,
      duration: theme?.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  hide: {
    display: "none",
  },
  contentPaper: {
    borderRadius: "30px",
    height: "72vh",
    padding: 5,
  },
  accountsPaper: {
    height: "58vh",
    borderRadius: "30px",
    marginTop: "20px",
    padding: "20px",
    backgroundSize: "cover",
  },
  nes1: {
    marginLeft: "10px",
    paddingRight: "10px",
  },
  hamburgerIcon: {
    width: "35px",
  },
  notificationIcon: {
    width: "25px",
  },
  closeIcon: {
    width: "25px",
  },
  menuBullet: {
    color: "#fff",
    fontSize: "7px !important",
    marginTop: "10px",
  },
  listItem:{
    paddingTop:'0px !important',
    paddingBottom: '0px !important'
  },
  toolbar:{
    justifyContent: "space-between",
      paddingTop: "10px",
  },
  iconButton:{
    backgroundColor: "#f5f6fa !important ",
     marginRight: "20px !important " 
  },
  drawerMenu:{
    margin: "0px 40px 0px 10px !important", 
    height: "40px !important", 
    width: "40px !important"
  },
  flexgrow:{
    flexGrow:1
  },
  /* DrawerMenu component stylings */
  listContent:{
    width: "85%", 
    paddingLeft: "5px", 
    marginTop: "35px"
  },
  profile:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  borderline:{
    width: "100%",
    margin: "8px"
  },
  accountmargin:{
    marginLeft: "-42px"
  },
  listitemIcon:{
    paddingTop: "10px",
    cursor: "pointer",
    marginLeft: "-6px",
  },
  listtext:{
    marginLeft: "-37px"
  },
  nes2:{
    marginLeft: "4px"
  },
  Arrowicon:{
    color:"#fff"
  },
  listmargin:{
    marginLeft:"-11px"
  },
  listpadding:{
    paddingLeft: "30px !important "
  },
  listItempadding:{
    paddingTop:"10px !important "
  },
  listItemText:{
    marginLeft: "-42px !important"
  },
  closeIconstyle:{
    width: "100%",
    marginTop: "5px",
    marginLeft: "8px",
    cursor: "pointer",
  }
}));

export default useStyles;
