import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import CurrencyFlag from "react-currency-flags";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import InfoIcon from "@mui/icons-material/Info";
import {
  getDataForFromAccount,
  getDataForSendAmount,
  getDataForReceiverCurrency,
  getDataForReceiverAmount,
  getDataAfterFirstContinue,
  getDataForReceiversCountryName,
} from "../../CommonService/sendPaymentService";
import { et } from "date-fns/locale";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Divider,
  Tooltip,
  Autocomplete
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import flagsCode from "../../CommonService/flagServies";
const AmountSection = ({
  onContinue,
  data,
  accounts,
  eTag,
  nextAssignmentId,
  avaiableToPayCurrencyList,
  availableToPayCountryNamesList,
  purposeCodeList,
  caseId,
  setEtag,
  setReceiversCountryName,
  previousData,
}) => {
  const classes = useStyles();
  const [account, setAccount] = useState("");
  const [isShowWarning, setShowWarning] = useState(false);
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [showAmountFields, setShowAmountFields] = useState(false);
  const [showExchangeFields, setShowExchangeFields] = useState(false);
  const [purposeCode, setPurposeCode] = useState("");
  const [PurposeCodeList, setPurposeCodeList] = useState("");
  const [validateCurrency, setValidateCurrency] = useState("");
  const [flagCode, setFlagCode] = useState("");
  const [remarks, setremarks] = useState("")
  const [error, seterror] = useState("")
  const [accountDetails, setAccountDetails] = useState({
    FromAccount: "",
    AccountBalance: "",
    SendAmount: "",
    ReceiveAmount: "",
    SendCurrency: "",
    CurrencyConvDesc: "",
    FXRateGoodForText: "",
    ReceiversCountryName: "",
    ReceiveCurrency: "",
  });
  useEffect(() => {
    if (previousData) {
      const fmAccount = previousData.caseInfo.content.FromAccount;
      const pxresults = getAccountsList(previousData.shared.D_GetAccountList);
      const maskNumber = pxresults.find(
        (item) => item.AccountNumber === fmAccount
      );
      if (maskNumber) {
        setAccount(maskNumber.AccountNumber);
      }
      setSelectedCountryName(
        previousData.caseInfo.content.ReceiversCountryName
      );
      const newDetails = previousData.caseInfo.content;
      setAccountDetails((prevDetails) => ({
        ...prevDetails,
        ...newDetails,
      }));
    }
  }, [previousData]);
   useEffect(() => {
    setShowWarning(selectedCountryName !== accountDetails.ReceiversCountryName ? true : false);
  }, [accountDetails.ReceiversCountryName]);

  function getPurposeCodeList(response) {
    console.log(response)
    const list = response.D_PurposeCodeList;
    const keys = Object.keys(list);
    let pxResults = [];

    for (const key of keys) {
      if (key.startsWith("D_PurposeCodeList")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  const handlePurposeCode = (event) => {
    const purposeCode = event.target.value;
    setPurposeCode(purposeCode);
  };

  function getAccountsList(list) {
    const keys = Object.keys(list);
    let pxResults = [];

    for (const key of keys) {
      if (key.startsWith("D_GetAccountList_")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  function getAccountsList(list) {
    const keys = Object.keys(list);
    let pxResults = [];

    for (const key of keys) {
      if (key.startsWith("D_GetAccountList_")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  const handleAccountChange = (event) => {
    const accountValue = event.target.value;
    setAccount(accountValue);
    getDataForFromAccount(event.target.value, nextAssignmentId, eTag).then(
      (response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          AccountBalance: resData.AccountBalance,
          SendCurrency: resData.SendCurrency,
          FromAccount: resData.FromAccount,
        });
      }
    );
  };

  const handleAmountInputChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value) || value === "") {
      setAccountDetails((prev) => ({
        ...prev,
        SendAmount: value,
      }));
    }
  };

  const handleAmountInputBlur = () => {
    const amount = parseFloat(accountDetails.SendAmount);
    if (!isNaN(amount)) {
      getDataForSendAmount(
        account,
        amount,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiversCountryName,
        nextAssignmentId,
        eTag
      ).then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          SendAmount: resData.SendAmount,
          ReceiveAmount: resData.ReceiveAmount,
        });
        setShowExchangeFields(true);
      });
    }
  };

  const handleReceiveAmountInputChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value) || value === "") {
      setAccountDetails((prev) => ({
        ...prev,
        ReceiveAmount: value,
      }));
    }
  };

  const handleReceiveAmountInputBlur = () => {
    const amount = parseFloat(accountDetails.ReceiveAmount);
    if (
      !isNaN(amount) &&
      accountDetails.SendAmount &&
      accountDetails.ReceiveCurrency
    ) {
      getDataForReceiverAmount(
        account,
        accountDetails.SendAmount,
        amount,
        nextAssignmentId,
        eTag
      ).then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          SendAmount: resData.SendAmount,
        });
        setShowExchangeFields(true);
      });
    }
  };

  const handleReceiverCurrencyChange = (event) => {
    const newCurrency = event.target.value;
    setAccountDetails((prev) => ({
      ...prev,
      ReceiveCurrency: newCurrency,
    }));
    getDataForReceiverCurrency(
      account,
      accountDetails.SendAmount,
      accountDetails.ReceiveAmount,
      event.target.value,
      nextAssignmentId,
      eTag
    ).then((response) => {
      const resData = response.data.data.caseInfo.content;
      setAccountDetails({
        ...accountDetails,
        CurrencyConvDesc: resData.CurrencyConvDesc,
        FXRateGoodForText: resData.FXRateGoodForText,
        TransactionDesc: resData.TransactionDesc,
        ReceiveAmount: resData.ReceiveAmount,
        ReceiveCurrency: resData.ReceiveCurrency,
        ReceiversCountryName: resData.ReceiversCountryName,
        SendAmount: resData.SendAmount,
      });
      //setSelectedCountryName(resData.ReceiversCountryName);
    })
   /*  .catch((err) => {
      console.log(err.response.data.errorDetails[0].message);
      setValidateCurrency(err.response.data.errorDetails[0].message);
    }); */
  };

  const handleReceiverCountryName = (event) => {
    const value = event.target.value;
    setSelectedCountryName(value);
    setFlagCode(flagsCode[value]);
    getDataForReceiversCountryName(
      account,
      accountDetails.SendAmount,
      accountDetails.ReceiveAmount,
      value,
      nextAssignmentId,
      eTag
    )
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          ReceiveCurrency: resData.ReceiveCurrency,
          ReceiversCountryName: resData.ReceiversCountryName,
          SendAmount: resData.SendAmount,
        });
        if(response?.data?.data?.shared?.D_PurposeCodeList){
          setPurposeCodeList(getPurposeCodeList(response.data.data.shared));
        }
      })
     /*  .catch((err) => {
        console.log(err);
        setValidateCurrency(err.response.data.errorDetails[0].message);
      }); */
      setShowAmountFields(true);
  };
console.log("currency details",flagCode)
  const handleContinue = async () => {
       if(remarks.trim()=== ""){
        seterror("Remarks are required");
        return;
      }else{ 
        try {
          const response = await getDataAfterFirstContinue(
            accountDetails.FromAccount,
            accountDetails.SendAmount,
            accountDetails.ReceiveCurrency,
            accountDetails.ReceiveAmount,
            accountDetails.ReceiversCountryName,
            nextAssignmentId,
            eTag
          );
          setEtag(response.headers["etag"]);
          const receiversCountryName = accountDetails.ReceiversCountryName;
          setReceiversCountryName(receiversCountryName);
          onContinue(nextAssignmentId, eTag, setEtag);
          seterror("")
        } catch {}
   } 
  };
  console.log(error)
  return (
    <>
      <Grid container>
        <Grid size={{ xs: 12 }}>
          <FormControl fullWidth variant="standard">
            <InputLabel className={classes.inputLabel}>
              Select Account To Make Payment From:
            </InputLabel>
            <Select
              id="Account"
              value={account}
              onChange={handleAccountChange}
              inputProps={{ className: classes.acctInput }}
              label="Select Account To Make Payment From"
              disableUnderline
              displayEmpty
            >
              {accounts &&
                accounts.map((acc) => (
                  <MenuItem key={acc.AccountNumber} value={acc.AccountNumber}>
                    {acc.MaskAccountNumber} {/* Masked account number shown */}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {account ? (
        <>
          <Grid container className={classes.spacingCont}>
            <Grid size={{ md: 3, xs: 12 }} className={classes.flex}>
              <Typography
                style={{
                  fontSize: "14px",
                  fontFamily: "Open sans",
                  fontWeight: "600",
                }}
              >
                Available Balance:
              </Typography>
              <Typography
                variant="body2"
                style={{ color: "#4f4f4f", margin: "2px 0" }}
              >
                &nbsp;{accountDetails.AccountBalance}{" "}
              </Typography>
              &nbsp;
              <CurrencyFlag
                currency={accountDetails.SendCurrency}
                style={{
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                }}
              />
              <span style={{ marginLeft: "5px" }}>
                {accountDetails.SendCurrency}
              </span>
            </Grid>
          </Grid>
          <Divider />
          <Grid container>
            <Grid size={{ xs: 12 }}>
              {/* <Grid container className={classes.spacing}>
            <Grid style={{ marginLeft: "-8px" }}> */}
              <Typography
                variant="body2"
                style={{
                  fontSize: "14px",
                  fontFamily: "Open sans",
                  fontWeight: "600",
                }}
              >
                What country are the funds being sent to ?
              </Typography>
              {/* </Grid> */}
              {/* </Grid> */}
              <Box className={classes.spacingCont} style={{ display:"flex", alignItems:"center"}}>
                  <Select
                  value={selectedCountryName}
                  onChange={handleReceiverCountryName}
                  className={classes.countrySelect}
                  displayEmpty
                  disableUnderline
                  style={{ width: "42%" }}
                > 
                  {availableToPayCountryNamesList &&
                    availableToPayCountryNamesList.map((acc) => {
                      return (
                        <MenuItem key={acc.Country} value={acc.Country}>
                          {acc.Country}
                        </MenuItem>
                      );
                    })} 
                </Select>  
                <CurrencyFlag
                    currency={flagCode}
                      style={{
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      marginLeft: "8px",
                      }}
                      />
              </Box>
            </Grid>
            <Grid size={{ xs: 12 }}>
              {isShowWarning && (
                <Box className={classes.flexCenter}>
                  <InfoIcon style={{ color: "yellow", fontSize: "2rem" }} />
                  <Typography
                    variant="body2"
                    style={{ lineHeight: "1.5em", marginLeft: "7px" }}
                  >
                    Paying funds to a country in a currency other than its
                    domestic currency may result in the funds being converted by
                    the beneficiary bank before they are applied to the
                    beneficiary's account if that account is not held in the
                    currency of the payment. Please be sure you want to proceed
                    before continuing.
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          {showAmountFields && (
            <>
              <Grid container className={classes.spacingCont}>
                <Grid className={classes.flexAlignCenter}>
                  <Typography
                    variant="body2"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Open sans",
                      fontWeight: "600",
                    }}
                  >
                    Amount You Send
                  </Typography>
                  &nbsp;
                  <Tooltip title="Enter the amount that you want to send in the currency of your account. The beneficiary will receive the equivalent of this amount.">
                    <HelpOutlineOutlinedIcon
                      style={{ color: "blue", padding: "0.1px" }}
                      fontSize="small"
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container className={classes.spacingCont}>
                <Grid size={{ xs: 12 }}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleAmountInputChange}
                    onBlur={handleAmountInputBlur}
                    value={accountDetails.SendAmount}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <CurrencyFlag
                              currency={accountDetails.SendCurrency}
                              style={{
                                borderRadius: "50%",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                            {/* <span style={{ margin: "0 0 0 10px" }}>
                              {accountDetails.SendCurrency}
                            </span> */}
                          </InputAdornment>
                        ),
                        style: { borderRadius: "8px" },
                      },
                    }}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid container className={classes.spacingCont}>
                <Grid className={classes.flexAlignCenter}>
                  <Typography
                    variant="body2"
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      fontFamily: "Open sans",
                      fontWeight: "600",
                    }}
                  >
                    Beneficiary Gets
                  </Typography>
                  &nbsp;
                  <Tooltip title="Enter the amount that you want the beneficiary to receive. Your account will be debited for the equivalent amount, plus the transaction fee.">
                    <HelpOutlineOutlinedIcon
                      style={{ color: "blue", marginTop: "10px" }}
                      fontSize="small"
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid size={{ md: 5, xs: 12 }}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleReceiveAmountInputChange}
                    onBlur={handleReceiveAmountInputBlur}
                    value={accountDetails.ReceiveAmount}
                    slotProps={{
                      input: {
                        style: { borderRadius: "8px" },
                        endAdornment: (
                          <InputAdornment position="end">
                            <FormControl fullwidth variant="standard">
                              <Select
                                value={accountDetails.ReceiveCurrency}
                                onChange={handleReceiverCurrencyChange}
                                disableUnderline
                                displayEmpty
                                fullWidth
                                className={classes.currencyCodeSelect}
                              >
                                {avaiableToPayCurrencyList &&
                                  avaiableToPayCurrencyList.map((acc) => (
                                    <MenuItem
                                      key={acc.CurrencyName}
                                      value={acc.CurrencyName}
                                    >
                                       &nbsp; &nbsp; &nbsp;
                                      <span>{acc.CurrencyName}</span>
                                      &nbsp; &nbsp; &nbsp;
                                      <CurrencyFlag
                                        currency={acc.CurrencyName}
                                        style={{
                                          borderRadius: "50%",
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      />
                                      {/* &nbsp; &nbsp; &nbsp;
                                      <span>{acc.CurrencyName}</span>
                                      &nbsp; &nbsp; &nbsp; */}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
            {console.log("account Details",accountDetails)}
          {showExchangeFields && (
            <Grid
              container
              spacing={1}
              className={classes.spacing}
              style={{
                background: "#f3eded",
                padding: "0px 5px",
                marginLeft: "0px",
              }}
            >
              <Grid size={{ xs: 9 }}>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                >
                  <Grid size={{ xs: 12 }} className={classes.flexAlignCenter} >
                    <Typography className={classes.text}>
                      Transaction Fee:
                    </Typography>
                    <InputBase
                      inputProps={{ "aria-label": "naked" }}
                      value={accountDetails.TransactionDesc}
                      className={classes.inputBase}
                      style={{marginLeft:"16%"}}
                    />
                  </Grid>
                  <Grid size={{ xs: 12 }} className={classes.flexAlignCenter}>
                    <Typography className={classes.text}>
                      Exchange Rate:
                    </Typography>
                    <InputBase
                      inputProps={{ "aria-label": "naked" }}
                      value={accountDetails.CurrencyConvDesc}
                      className={classes.inputBase}
                      style={{marginLeft:"19%"}}
                    />
                  </Grid>
                  <Grid size={{ xs: 12 }} className={classes.flexAlignCenter}>
                    <Typography className={classes.text}>
                      Exchange Rate valid for:
                    </Typography>
                    <InputBase
                      inputProps={{ "aria-label": "naked" }}
                      value={accountDetails.FXRateGoodForText}
                      className={classes.inputBase}
                      style={{marginLeft:"2%"}}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid size={{ xs: 3 }} className={classes.flexCenter}>
                <Box className={classes.goodForTextContainer}>
                  <Typography className={classes.goodForText}>
                    Good For {accountDetails.FXRateGoodForText}
                  </Typography>
                </Box>
              </Grid> */}
            </Grid>
          )}
           <Grid container spacing={1}>
            {PurposeCodeList && (
            <Grid size={{ xs: 12, md: 6 }} sx={{ marginTop: "10px" }}>
              <Typography variant="body2" className={classes.headerstyle}>
                Purpose of Payment
              </Typography>

              <TextField
                id="PurposeCode"
                variant="outlined"
                value={purposeCode}
                size="small"
                onChange={handlePurposeCode}
                slotProps={{
                  input: { className: classes.purposeCodeDropdown },
                }}
                //label="Select Pay Purpose"
                select
                fullWidth
              >
                {PurposeCodeList &&
                  PurposeCodeList.map((pCode) => (
                    <MenuItem
                      key={pCode.PayPurPCodeDesc}
                      value={pCode.PayPurPCodeDesc}
                    >
                      {pCode.PayPurPCode}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid> 
            )}
            {showAmountFields && (
            <Grid size={{ md: 6, xs: 12 }} sx={{ marginTop: "10px" }}>
              <Typography variant="body2" className={classes.headerstyle}>
                Remarks 
                {error &&(
                  <span style={{color:'red'}}> Please enter  the remarks </span>
                )
                
                }<span style={{color:'red'}}>* </span>
              </Typography>
              <TextField
                id="remarks"
                label="Remarks"
                multiline
                rows={3}
                fullWidth
                //required={true}
                value={remarks}
                onChange={(e)=>{setremarks(e.target.value)}} 
              />
            </Grid>
            )}
          </Grid> 
        </>
      ) : (
        <></>
      )}
      <Grid container className={classes.spacing}>
        <Grid size={{ md: 12, xs: 12 }} className={classes.flexBetween}>
          <Button className={classes.cancel}>Cancel</Button>
          <Button
            variant="contained"
            className={classes.continue}
            onClick={handleContinue}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AmountSection;
