import axios from "axios";
import {
  getAccessToken,
  isTokenExpired,
  refreshAccessToken,
} from "../Authorization/Services/authService";
import endPoints, { BASE_URL } from "../../config/Api";
import { Capacitor } from "@capacitor/core";

const getHeaders = (etag = null) => {
  let token = getAccessToken();
  if (isTokenExpired(token)) {
    token = refreshAccessToken(); // Refresh the token
  }
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // Add -specific headers if needed
  if (Capacitor.isNativePlatform()) {
    headers["x-origin-channel"] = "Mobile";
  }

  // Append the ETag header if provided
  if (etag) {
    headers["If-Match"] = etag; // Use If-Match for ETag
  }
  return headers;
};

export const checkBeneficiaryExists = async (trnNumber) => {
  const params = {
    "dataViewParameters.TRN": trnNumber,
  };

  return await axios.get(`${BASE_URL}data_views/D_CheckBeneExists`, {
    headers: getHeaders(),
    params,
  });
};

export const createPayAgainCase = async (trnNumber,actionName) => {
  const payload = {
    caseTypeID: "EVN-EPP-Work-SendPayment",
    processID: "pyStartCase",
    content: {
      InitiatedFor: actionName,
      OldCaseTRN: trnNumber,
    },
  };

  return await axios.post(`${BASE_URL}cases`, payload, {
    params: {
      viewType: "page",
    },
    headers: getHeaders(),
  });
};

export const createNewBeneficiary = async () => {
  const payload = {
    caseTypeID: "EVN-EPP-Work-Beneficiary",
    processID: "pyStartCase",
    content: {},   
  };

  return await axios.post(`${BASE_URL}cases`, payload, {
    params: {
      viewType: "page",
    },
    headers: getHeaders(),
  });
};
export const initiateNewBeneficiary = async (assignmentID,countryName,etag) => {
  const payload = {
    content: {"Country":countryName},   
  };

  return await axios.patch(`${BASE_URL}assignments/${assignmentID}/actions/InitiateBeneficiary`, payload, {
    params: {
      viewType: "page",
    },
    headers: getHeaders(etag),
  });
};

export const modifyBeneficiary = async (pyGuid) => {
  const payload = {
    caseTypeID: "EVN-EPP-Work-Beneficiary",
    content: {
      InitiatedFor: "BeneEdit",
      NewBeneDetails: {
        pyGUID: pyGuid,
      },
    },
  };

  return await axios.post(`${BASE_URL}cases`, payload, {
    params: {
      viewType: "page",
    },
    headers: getHeaders(),
  });
};
