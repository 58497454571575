const endPoints = {
  baseUrl: `https://eppdev.evonsys.com.au/prweb/api/application/v2`,
  home: {
    D_Page_Name: `D_GetFullAccountDetails`,
    getAllAccounts: ``,
  },
  accounts: {
    D_Page_Name: `D_GetFullAccountDetails`,
    getAllAccounts: ``,
  },
  recentPayments: {
    D_Page_Name: `D_RecentPaymentsList`,
  },
  addModifyBeneficiary: {
    D_Page_Name: `D_GetListRecipient_RO`,
  },
  sendPaymentBeneficiaries : {
    D_Page_Name: `D_GetListRecipient`,
  }
};

export const BASE_URL = 'https://eppdev.evonsys.com.au/prweb/app/evonsys-payment-platform/api/application/v2/';

export default endPoints;
