import useStyles from "./styles";
import { useEffect, useState } from "react";
import axios from "axios";
import endPoints from "../../../config/Api";
import {
  getAccessToken,
  isTokenExpired,
  refreshAccessToken,
} from "../../Authorization/Services/authService";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardContent, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

const Accounts = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const fetchData = async () => {
    let token = getAccessToken();
    if (isTokenExpired(token)) {
      token = await refreshAccessToken(); // Refresh the token
    }
    axios
      .post(
        `${endPoints.baseUrl}/data_views/${endPoints.accounts.D_Page_Name}`,
        {
          "dataViewParameters": {
            "AccountType": '\"Checking\",\"Savings\"',
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setData(data.data.data);
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        navigate("/login");
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const maskedAccount = (number) => {
    return number.split("-")[0];
  };
  const displayAmount = (amount) => {
    const formattedAmount = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
    return formattedAmount === "NaN" ? "0.0" : formattedAmount;
  };
  
  return (
    <>
      <Grid container>
        <Grid size={{ md: 12, xs: 12 }}>
          <Typography variant="h1" className={classes.headerTitle}>
            Accounts
          </Typography>
          <Divider />
        </Grid>
      </Grid>

      <Typography variant="body1" className={classes.a_heading}>
        Personal Accounts *
      </Typography>
      <Grid container spacing={2} className={classes.accountgrid}>
        {data.map((row, index) => {
          return (
            <>
              <Grid size={{ xs: 6 }}>
                <Card key={index} className={classes.accountCard2}>
                  <CardContent className={classes.acContent2}>
                    <div className={classes.qBContainer}>
                      <Button variant="contained" className={classes.quickBtn}>
                        Quick View
                      </Button>
                    </div>
                    <div className={classes.detailsContainer}>
                      <Typography variant="body1" className={classes.details}>
                        <b>
                          {displayAmount(row.AccountBalance)}&nbsp;&nbsp;
                          {row?.AccountCurrency}
                        </b>
                      </Typography>
                      <div className={classes.subDetails}>
                        <Typography variant="body2" className={classes.acInfo}>
                          {row.AccountNumber}
                        </Typography>
                        <Typography variant="body2" className={classes.acInfo}>
                          {row.AccountType} Account
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </>
          );
        })}
      </Grid>
    </>
  );
};

export default Accounts;
