import { Dialog, DialogTitle,Typography,DialogContent,DialogActions,Button,Box } from '@mui/material'
import CurrencyFlag from "react-currency-flags";
import ClearIcon from "@mui/icons-material/Clear";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditIcon from "@mui/icons-material/Edit";
import useStyles from './styles';
import Grid from "@mui/material/Grid2";
import React from 'react'

const Modalpopup = (props) => {
  const {open,close,rowdata} = props
  const classes = useStyles()
  return (
    <>
        <Dialog
        onclose = {close}
        open = {open}
        fullWidth 
         maxWidth="sm"
         className={classes.Dialog} 
        >
         <DialogTitle id="simple-dialog-title" className={classes.dialogTitle} >
          <div className={classes.popup}>
            <Typography variant="body1" className={classes.popupTitle}>
              Modify Beneficiary
            </Typography>
            {/*  <ClearIcon
              style={{cursor: "pointer" }}
              onClick={close}
            />  */}
          </div>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12 }} style={{ display: "flex" }}>
              <Grid container spacing={2}>
                <Grid size={{ md: 6 }} style={{ display: "flex" }}>
                  <Typography variant="body2" style={{fontWeight:"600"}}>Pay-to Country:</Typography>
                  <Typography variant="body2">
                    &nbsp;{rowdata?.Country}
                  </Typography>
                  <CurrencyFlag
                    currency={rowdata?.CountryCode}
                    style={{
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </Grid>
                <Grid size={{ md: 3 }}></Grid>
                <Grid size={{ md: 3 }}>
                  <div className={classes.main}>
                    <EditIcon className={classes.editIcon} />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Typography variant="body2" style={{fontWeight:"600"}}>Nickname (Optional)</Typography>
              <Typography variant="body2">--</Typography>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12 }}>
                  <Typography variant="body2" style={{fontWeight:"600"}}>Beneficiary Name</Typography>
                  <Typography variant="body2">{rowdata?.FullName}</Typography>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Typography variant="body2" style={{fontWeight:"600"}}>Account Number</Typography>
                  <Typography variant="body2">{rowdata?.CdtrAcctId}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              size={{ xs: 12 }}
              spacing={2}
              sx={{ display: "flex", gap: "3px" }}
            >
              <InfoOutlinedIcon style={{ fill: "#52439E", height: "20px" }} />
              <Typography variant="body2">
                Enter BIC Code or ABA Number
              </Typography>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12 }}>
                  <Typography variant="body2" style={{fontWeight:"600"}}>
                    Beneficiary Bank BIC Code
                  </Typography>
                  <Typography variant="body2">{rowdata?.BICFI}</Typography>
                </Grid>
                <Grid size={{ md: 6 }}>
                  <Typography variant="body2" style={{fontWeight:"600"}}>ABA Routing Number</Typography>
                  <Typography variant="body2">--</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Typography variant="body2" style={{fontWeight:"600"}}>Beneficiary Bank Address</Typography>{" "}
              <Typography variant="body2">
                {rowdata?.BeneBankAddress}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container  style={{display:"flex",justifyContent:"space-between ",width:"100%",}} >
            <Grid
              size={{xs:12}}
              style={{display:"flex",justifyContent:"flex-end  ",gap:"55%"}}
            >
               <Button className={classes.cancel} onClick={close}>Cancel</Button>
               
              <Button className={classes.submit}>Submit</Button>

            </Grid>
          </Grid>
        </DialogActions>
        </Dialog>
    </>
  )
}

export default Modalpopup
