import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";
import { getListOfBeneficiaries,getDataForReviewBeneficiary,getDataForFirstPreviousPage } from "../../CommonService/sendPaymentService";
import { Avatar, Box, Button, Card, CardContent, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

const BeneficiarySection = ({ onContinue, receiversCountryName,nextAssignmentId,eTag,setEtag,setSelectedBeneDetails,setPurposeCodeList,
  onPrevious,
  setPreviousData,
  previousData,
 }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [rowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState();
  const [searchText,setSearchText] = useState('');
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getListOfBeneficiaries(receiversCountryName);
        setData(response.data.data);
        setSearchData(response.data.data);
      } catch (error) {
        console.error('Error fetching Beneficiaries data:', error);
        localStorage.clear();
        navigate("/login")
      }
    };  
    fetchData();
  }, [navigate]);

  useEffect(() => {
    if (previousData) {
      setSelectedBeneficiary(previousData?.caseInfo?.content?.AvailableRecipients?.pyGUID);
    }    
  }, [previousData]);

  const searchHandler=(event)=>{
    const searchValue = event.target.value;
    setSearchText(searchValue)
    if (searchValue === '') {
      setData(searchData);
    }
    const SearchedData = searchData.filter((row) =>
      row.FullName && row.FullName?.toLowerCase().includes(event.target.value?.toLowerCase())
      );
    setData(SearchedData);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handle_row = (rowid)=>{
    console.log(rowid);
    setSelectedBeneficiary(rowid)
  }
  const isRowSelected = (rowId) => {
    return selectedBeneficiary === rowId; // Check if the row is selected
  };

  const colors = [
    "#0b8953",
    "#c23824",
    "#2c97de",
    "#00657c",
    " #00aa66",
    "#667700",
    "#e27100",
    "#52459f",
  ];

  function getPurposeCodeList(response) {
    const list = response.D_PurposeCodeList;
    const keys = Object.keys(list);
    let pxResults = [];
  
    for (const key of keys) {
      if (key.startsWith("D_PurposeCodeList")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  const handleContinue =  async () => {
    try{
      const response = await getDataForReviewBeneficiary(selectedBeneficiary,nextAssignmentId,eTag);
      const newSelectedBeneDetails = response.data.data.caseInfo.content.SelectedBeneDetails;
      setSelectedBeneDetails(newSelectedBeneDetails);
      if(response.data.data.shared){
        setPurposeCodeList(getPurposeCodeList(response.data.data.shared));
      }
      setEtag(response.headers["etag"]);
      onContinue(nextAssignmentId);
    }catch {

    }
  };

  const handlePrevious = async () => {
    try {
      const response = await getDataForFirstPreviousPage(
        nextAssignmentId,
        eTag
      );
      setEtag(response.headers['etag'])
      const resourceData = response.data.data;
      setPreviousData(resourceData);
      onPrevious();
    } catch {}
  };


  const getInitials = (name) => {
    if (!name) return "";
    const nameArray = name.trim().split(/\s+/);

    return nameArray.length > 1
      ? `${nameArray[0][0]}${nameArray[1][0]}`.toUpperCase()
      : name.substring(0, 2).toUpperCase();
  };

  const getColor = (initials) => {
    if (!initials) return colors[0]; // Default color

    const firstChar = initials[0].toUpperCase();

    if (firstChar >= "A" && firstChar <= "Z") {
      const charCode = firstChar.charCodeAt(0) - "A".charCodeAt(0);
      return colors[Math.floor(charCode / 3) % colors.length];
    } else if (firstChar >= "0" && firstChar <= "9") {
      const num = parseInt(firstChar, 10);
      return colors[Math.floor(num / 3) % colors.length];
    }

    return colors[0]; // Default color for any other characters
  };

  const _handleClick = (data) => {
    setRowData(data);
    setOpen(!open);
  };

  function CustomTablePagination({ count, page, rowsPerPage, onPageChange }) {
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

  const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeftIcon />
        </IconButton>

        {/* Page count in the center */}
        <Typography variant="body2" sx={{ margin: "0 16px" }}>
          {`${page * rowsPerPage + 1}-${Math.min(
            count,
            (page + 1) * rowsPerPage
          )} of ${count}`}
        </Typography>

        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid size={{xs:12, md:12}}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid size={{xs:12, md:12}} style={{ position: "relative",marginTop:"15px" }}>
              <input
                placeholder="Search for beneficiary by name"
                className={classes.inputInput}
                // onChange={_onSearch}
                onChange={searchHandler}
                value={searchText}
              />
              {/* <SearchIcon className={classes.searchIcon} />
              <CloseIcon className={classes.closeIcon} /> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid size={{xs:12, md:12}}/*  style={{ marginTop: "10px" }} */>
          <div style={{ display: "flex", gap: "10px" }}>
            <Typography variant="h6" className={classes.addtxt}>
              Add New Beneficiary
            </Typography>
            <AddCircleIcon className={classes.circleIcon} />
          </div>
        </Grid>

        {/* <Grid size={{xs:12,md:12}}>
          <Typography variant="body1" color="textPrimary" gutterBottom>
            <b>Add/Modify Beneficiary</b>
          </Typography>
        </Grid> */}
        <Grid size={{xs:12,md:12}}>
          <Typography variant="body1" className={classes.b_heading}>
            Available Beneficiaries in {receiversCountryName}
          </Typography>
        </Grid>
        <Grid size={{xs:12,md:12}}>
          <Grid container spacing={2}>
            {data
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, index) => {
                const initials = getInitials(row.FullName);
                const backgroundColor = getColor(initials);
                return (
                  <>
                    <Grid size={{xs:12,md:6}} key={index} >
                      <Card
                        key={row.pxRefObjectInsName || row.pyID}
                        /* className={classes.accountCard} */ className={`${classes.accountCard} ${isRowSelected(row.pyID)? classes.selectedRow:" "}`}
                        onClick={()=>handle_row(row?.pyID)}
                      >
                        <CardContent className={classes.acContent}>
                          <div style={{ display: "flex", width: "80%" }}>
                            <Avatar
                              style={{ backgroundColor }}
                              className={classes.avatar}
                            >
                              {initials}
                            </Avatar>
                            <div className={classes.infoContainer}>
                              <Typography
                                variant="body1"
                                style={{
                                  color: "#222",
                                  fontWeight: "600",
                                  fontSize: "14px",
                                }}
                              >
                                {row.FullName}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{
                                  color: "#52459f",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                }}
                              >
                                A/C No:{" "}
                                {row.MaskAccNumb
                                  ? row.MaskAccNumb
                                  : row.MaskIBANNumb}
                              </Typography>
                            </div>
                          </div>
                          <div className={classes.iconContainer}>
                            <LaunchOutlinedIcon
                              fontSize="medium"
                              style={{
                                color: "#52459f",
                                marginRight: "14px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                _handleClick(row);
                              }}
                            />
                            <DeleteOutlineOutlinedIcon
                              fontSize="medium"
                              style={{ color: "#52459f", cursor: "pointer" }}
                            />
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  </>
                );
              })}
          </Grid>
          {data && data.length > 0 && (
            <CustomTablePagination
              count={data.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
            />
          )}
        </Grid>
      </Grid>
      <Grid container className={classes.spacing}>
        <Grid size={{xs:12,md:12}} className={classes.flexBetween}>
          <div>
            <Button className={classes.cancel}>
              Cancel
            </Button>
            <Button  onClick={handlePrevious} className={classes.cancel} style={{ marginLeft: "20px" }}>
              Previous
            </Button>
          </div>
          <Button 
            variant="contained"
            onClick={handleContinue}
            className={classes.continue}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default BeneficiarySection;
