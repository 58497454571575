import React, { useState } from "react";
import useStyles from "./styles";
import { Link, Outlet } from "react-router-dom";
import { Apps, Home, Send, SwapHoriz } from "@mui/icons-material";
import DrawerMenu from "./drawerMenu";
import Grid from "@mui/material/Grid2";

const Dataview = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <DrawerMenu
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
      <Grid container direction="column" style={{backgroundColor:"#5649a1"}}>
        <Grid container className={classes.middleContainer}>
          <Grid size={{xs:12}}  className={classes.contentContainer}>
            <Outlet />
          </Grid>
        </Grid>
        <Grid size={{md:12}} className={classes.footerContainer}>
          <Grid  className={classes.flexCenter}>
            <Link to="/" className={classes.linkIcons}>
              <Home className={classes.footerIcons} />
              Home
            </Link>
          </Grid>
          <Grid  className={classes.flexCenter}>
            <Link to="/sendpayment" className={classes.linkIcons}>
              <Send className={classes.footerIcons} />
              Send
            </Link>
          </Grid>
          <Grid  className={classes.flexCenter}>
            <Link to="/recentpayments" className={classes.linkIcons}>
              <SwapHoriz className={classes.footerIcons} />
              Payments
            </Link>
          </Grid>
          <Grid  className={classes.flexCenter}>
            <Link onClick={handleDrawerOpen} className={classes.linkIcons}>
              <Apps className={classes.footerIcons} />
              More
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Dataview;
