import React, { useState, useEffect} from "react";
import useStyles from "./styles";
import CurrencyFlag from "react-currency-flags";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import InfoIcon from "@mui/icons-material/Info";
import flagsCode from "../../CommonService/flagServies";
import {
  getDataForFromAccount,
  getDataForSendAmount,
  getDataForReceiverCurrency,
  getDataForReceiverAmount,
  getDataAfterFirstContinue,
  getDataForReceiversCountryName,
} from "../../CommonService/sendPaymentService";
import ReactFlagsSelect from "react-flags-select";
import iso3166 from "iso-3166-1";
import SelectFeild from "react-select";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";

const AmountSection = ({
  onContinue,
  accounts,
  eTag,
  nextAssignmentId,
  avaiableToPayCurrencyList,
  availableToPayCountryNamesList,
  setEtag,
  setReceiversCountryName,
  previousData,
}) => {
  const classes = useStyles();
  const [account, setAccount] = useState("");
  const [isShowWarning, setShowWarning] = useState(false);
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [validateCurrency, setValidateCurrency] = useState("");
  const [showAmountFields, setShowAmountFields] = useState(false);
  const [showExchangeFields, setShowExchangeFields] = useState(false);
  const [purposeCode, setPurposeCode] = useState("");
  const [purposeCodeList, setPurposeCodeList] = useState("");
  const [flagCode, setFlagCode] = useState("");
  const [flagselect, setflagselect] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const actionName = "ActionInitiatePayment";
  const [loading, setLoading] = useState(false);

  const [accountDetails, setAccountDetails] = useState({
    FromAccount: "",
    AccountBalance: "",
    SendAmount: "",
    ReceiveAmount: "",
    SendCurrency: "",
    CurrencyConvDesc: "",
    FXRateGoodForText: "",
    ReceiversCountryName: "",
    ReceiveCurrency: "",
  });

  const euroCountries = [
    "DE",
    "FR",
    "ES",
    "IT",
    "NL",
    "BE",
    "AT",
    "FI",
    "PT",
    "IE",
    "GR",
    "CY",
    "LU",
    "MT",
    "SK",
    "SI",
    "EE",
    "LT",
    "LV",
  ];
  const flagToCountryMap = {
    US: "United States",
    CA: "Canada",
    GB: "United Kingdom",
    JP: "Japan",
    CN: "China",
    QA:"Qatar",
    EU: euroCountries,
  };

  useEffect(() => {
    if (previousData) {
      const fmAccount = previousData.caseInfo.content.FromAccount;
      const pxresults = getAccountsList(previousData.shared.D_GetAccountList);
      const maskNumber = pxresults.find(
        (item) => item.AccountNumber === fmAccount
      );
      if (maskNumber) {
        setAccount(maskNumber.AccountNumber);
      }
      setSelectedCountryName(
        previousData.caseInfo.content.ReceiversCountryName
      );
      setFlagCode(
        flagsCode[previousData?.caseInfo?.content?.ReceiversCountryName]
      );
       setflagselect(previousData?.caseInfo?.content?.PaymentInfo?.CountryCode)
       if(purposeCodeList){ 
       const list= getPurposeCodeList(previousData?.shared)
        setPurposeCodeList(list)
        setPurposeCode(previousData?.caseInfo?.content?.PaymentInfo?.PaymentsPurposeCode)
       }
      
       const option = {
        label: previousData?.caseInfo?.content?.ReceiveCurrency,
        value: previousData?.caseInfo?.content?.ReceiveCurrency,
      };
      setSelectedCurrency(option);
      setRemarks(previousData?.caseInfo?.content?.Remarks)
      const newDetails = previousData.caseInfo.content;
      setShowAmountFields(true);
      setShowExchangeFields(true);
      setAccountDetails((prevDetails) => ({
        ...prevDetails,
        ...newDetails,
      }));
    }
  }, [previousData]);

  useEffect(() => {
    const selectedvalue = selectedCurrency?.value?.slice(0, 2);
    if (flagselect && selectedvalue) {
      setShowWarning(flagselect !== selectedvalue);
    } else {
      setShowWarning(false);
    }
  }, [flagselect, selectedCurrency]);

  function getPurposeCodeList(response) {
    const list = response.D_PurposeCodeList;
    const keys = Object.keys(list);
    let pxResults = [];
    for (const key of keys) {
      if (key.startsWith("D_PurposeCodeList")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  const handlePurposeCode = (event) => {
    const purposeCode = event.target.value;
    setPurposeCode(purposeCode);
  };
  function getAccountsList(list) {
    const keys = Object.keys(list);
    let pxResults = [];
    for (const key of keys) {
      if (key.startsWith("D_GetAccountList_")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  const handleAccountChange = (event) => {
    const accountValue = event.target.value;
    setAccount(accountValue);
    getDataForFromAccount(event.target.value, nextAssignmentId, eTag)
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          AccountBalance: resData.AccountBalance,
          SendCurrency: resData.SendCurrency,
          FromAccount: resData.FromAccount,
        });
      })
      .catch((error) => {
        if (error.status != "401") {
        } else {
          navigate("/login");
        }
      });
  };

  const handleAmountInputChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);
    if (value === "" || !isNaN(parsedValue)) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));

      setAccountDetails((prev) => ({
        ...prev,
        SendAmount: value,
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Please enter a valid number.",
      }));
    }

    if (parsedValue < 0) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Send Amount must be greater than zero.",
      }));
    } else if (parsedValue > parseFloat(accountDetails?.AccountBalance)) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Send Amount should not greater than available balance",
      }));
    }
    else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));
    }  
  };

  const handleAmountInputBlur = () => {
    setLoading(true);
    const amount = parseFloat(accountDetails.SendAmount);
    if (!isNaN(amount) && !error?.amountYouSendError) {
      getDataForSendAmount(
        account,
        amount,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiversCountryName,
        flagselect,
        nextAssignmentId,
        eTag,
        actionName,
      )
        .then((response) => {
          const resData = response.data.data.caseInfo.content;
          setAccountDetails({
            ...accountDetails,
            CurrencyConvDesc: resData.CurrencyConvDesc,
            FXRateGoodForText: resData.FXRateGoodForText,
            TransactionDesc: resData.TransactionDesc,
            SendAmount: resData.SendAmount,
            ReceiveAmount: resData.ReceiveAmount,
          });
          setShowExchangeFields(true);
        })
        .catch((error) => {
          if (error.status == "401") {
            navigate("/login");
          } else {
            console.log("Promise rejected with error:", error.message);
          }
        }).finally(() => {
          setLoading(false);
        })
    }
  };

  const handleReceiveAmountInputChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);
    if (!isNaN(value) || value === "") {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));

      setAccountDetails((prev) => ({
        ...prev,
        ReceiveAmount: value,
      }));
    }

    if (parsedValue < 0) {
      setError((prevState) => ({
        ...prevState,
        amountEnteredError: "Amount Entered must be greater than zero.",
      }));
    } else if (parsedValue > parseFloat(accountDetails?.AccountBalance)) {
      setError((prevState) => ({
        ...prevState,
        amountEnteredError: "Amount Entered should not greater than available balance.",
      }));
    }
    else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));
    }  
  };
  const handleReceiverCountryName = (value) => {
    // const value = event.target.value;
    // console.log(event);
    setSelectedCountryName(value);
    setShowWarning(
      value !== accountDetails.ReceiversCountryName ? true : false
    );
  };

  const handleReceiveAmountInputBlur = () => {
    const amount = parseFloat(accountDetails.ReceiveAmount);
    if (!isNaN(amount) && accountDetails.ReceiveCurrency) {
      getDataForReceiverAmount(
        account,
        accountDetails.SendAmount,
        amount,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiversCountryName,
        flagselect,
        nextAssignmentId,
        eTag,
        actionName
      ).then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          SendAmount: resData.SendAmount,
        });
        setShowExchangeFields(true);
      });
    }
  };

  const handleReceiverCurrencyChange = (option) => {
    setLoading(true);
    const newCurrency = option.value;
    setSelectedCurrency(option);
    setAccountDetails((prev) => ({
      ...prev,
      ReceiveCurrency: newCurrency,
    }));
    getDataForReceiverCurrency(
      account,
      accountDetails.SendAmount,
      accountDetails.ReceiveAmount,
      accountDetails.ReceiversCountryName,
      newCurrency,
      flagselect,
      nextAssignmentId,
      eTag,
      actionName
    )
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          ReceiveCurrency: resData.ReceiveCurrency,
          ReceiversCountryName: resData.ReceiversCountryName,
          SendAmount: resData.SendAmount,
        });
        //setSelectedCountryName(resData.ReceiversCountryName);
      })
      .catch((err) => {
        console.log(err.response.data.errorDetails[0].message);
        setValidateCurrency(err.response.data.errorDetails[0].message);
      }).finally(() => {
        setLoading(false);
      })
  };

  const countriesToShow = availableToPayCountryNamesList
    .map((item) => item.Country)
    .map((country) => {
      const normalizedCountry = country.trim().toLowerCase();

      const countryObj = iso3166.all().find((item) => {
        const isoCountryName = item.country.toLowerCase();

        return isoCountryName.includes(normalizedCountry);
      });

      if (!countryObj) {
        console.warn(`Country code not found for ${country}`);
        return null;
      }

      const countryCode = countryObj.alpha2;
      return countryCode;
    })
    .filter(Boolean);

  const handleReceiverCountrySelect = (code) => {
    setflagselect(code);
    setSelectedCurrency(null)
    const countryName = flagToCountryMap[code] || "";
    setAccountDetails((prev) => ({
      ...prev,
      ReceiversCountryName: countryName,
      SendAmount: "",
      ReceiveAmount: "",
      CurrencyConvDesc: "",
      FXRateGoodForText: "",
      TransactionDesc:"",
    }));

    getDataForReceiversCountryName(
      account,
      accountDetails.SendAmount,
      accountDetails.ReceiveAmount,
      countryName,
      code,
      nextAssignmentId,
      eTag
    )
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          ReceiveCurrency: resData.ReceiveCurrency,
          ReceiversCountryName: resData.ReceiversCountryName,
          SendAmount: resData.SendAmount,
        });
        if (response?.data?.data?.shared?.D_PurposeCodeList) {
          setPurposeCodeList(getPurposeCodeList(response.data.data.shared));
        } else {
          setPurposeCodeList("");
        }
      })
      .catch((err) => {
        console.log(err);
        setValidateCurrency(err.response.data.errorDetails[0].message);
      });
  };

const validateAmount = () => {
  const sendAmount = parseFloat(accountDetails?.SendAmount);
console.log(accountDetails?.SendAmount,sendAmount)
  let amountError = "";
  if( sendAmount <= 0){
    amountError="Send Amount must be a valid number greater than zero."
  }
 else if (isNaN(sendAmount) ) {
    amountError = "Value cannot be blank.";
  } else if (sendAmount > accountDetails?.AccountBalance) {
    amountError = "Send Amount should not greater than Available balance.";
  }else if (sendAmount > accountDetails?.AccountBalance){
    amountError =
      "Send Amount should not greater than available balance"
    
  }   
  return amountError;
};
const validateReceiveAmount = () => {
  const receiveAmount = parseFloat(accountDetails?.ReceiveAmount);
  let amountError = "";
 
  if(receiveAmount===0){
    amountError = "Send Amount must be a valid number greater than zero."
   
  }else if(isNaN(receiveAmount) || receiveAmount < 0){
    amountError =
      "Value cannot be blank."
    
  }else if(receiveAmount > accountDetails?.AccountBalance){
    amountError =
      "Amount Entered should not greater than available balance."
   
  }
  
  return amountError;
};

const validateRemarks = () => {
  return remarks.trim() === "" ? "Remarks are required." : "";
};

// Main form validation
const validateForm = () => {
  const amountError = validateAmount();
  const amountReceiveError = validateReceiveAmount();
  const remarksError = validateRemarks();
  
  if (amountError || remarksError || amountReceiveError) {
    setError({
      amountYouSendError: amountError,
      remarksError: remarksError,
      amountReceiveError: amountReceiveError,
    });
    console.log("Form is invalid.", { amountYouSendError: amountError, remarksError });
    return false;
  }

  console.log("Form is valid.");
  handleContinue();
  return true;
};

  const handleContinue = async () => {
    try {
      const response = await getDataAfterFirstContinue(
        accountDetails.FromAccount,
        accountDetails.SendAmount,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiveAmount,
        accountDetails.ReceiversCountryName,
        flagselect,
        purposeCode,
        remarks,
        nextAssignmentId,
        eTag
      );
      setEtag(response.headers["etag"]);
      const receiversCountryName = accountDetails.ReceiversCountryName;
      setReceiversCountryName(receiversCountryName);
      onContinue(nextAssignmentId, eTag, setEtag);
    } catch {}
  };

  const options = avaiableToPayCurrencyList.map((currency) => ({
    value: currency.CurrencyName,
    label: currency.CurrencyName,
  }));

  const formatOptionLabel = ({ value }) => (
    <div  className={classes.option}>
      {value && <CurrencyFlag currency={value} />}
      <span className={classes.optionValue}>{value}</span>
    </div>
  );

  const handleCancel = () => {
    navigate("/recentpayments");
  };

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel
          className={classes.inputLable} 
        >
          Select Account To Make Payment From
        </InputLabel>
        <Select
          id="Account"
          variant="standard"
          value={account}
          onChange={handleAccountChange}
          inputProps={{ className: classes.acctInput }}
          label="Select Account To Make Payment From"
          disableUnderline
          displayEmpty
        >
          {accounts &&
            accounts.map((acc) => (
              <MenuItem key={acc.AccountNumber} value={acc.AccountNumber}>
                {acc.MaskAccountNumber} {/* Masked account number shown */}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      {account ? (
        <>
          <Grid container className={classes.spacingCont}>
            <Grid
              size={{ md: 12, xs: 12 }}
              className={`${classes.flex} ${classes.Amountcolor}`}
            >
              <Typography  className={classes.Availablebal}>
                Available Balance:
              </Typography>
              <Typography variant="body2">
                &nbsp;{accountDetails.AccountBalance}
                {/* {accountDetails.SendCurrency} */}
              </Typography>
              &nbsp;
              <CurrencyFlag
                currency={accountDetails?.SendCurrency}
               className={classes.CurrencyFlag}
              />
              <span  className={classes.Sendcurrency}>
                {accountDetails?.SendCurrency}
              </span>
            </Grid>
          </Grid>
          <Divider />
          <Grid container className={classes.spacing}>
            <Grid size={{ md: 5, xs: 12 }}>
              <Typography variant="body2"  className={classes.Fontweight}>
                What country are the funds being sent to ?
              </Typography>
              <ReactFlagsSelect
                selected={flagselect}
                onSelect={handleReceiverCountrySelect}
                countries={countriesToShow}
                searchable
                searchPlaceholder="Search by Country Name"
                className={classes.sent}
              />
            </Grid>
            <Grid size={{ md: 7, xs: 12 }}>
              {isShowWarning ? (
                <>
                  <Box className={classes.flexAlignCenter}>
                    <InfoIcon
                      className={classes.Infoicon}
                    />
                    <Typography
                      variant="body2"
                     className={classes.Infotext}
                    >
                      Paying funds to a country in a currency other than its
                      domestic currency may result in the funds being converted
                      by the beneficiary bank before they are applied to the
                      beneficiary's account if that account is not held in the
                      currency of the payment. Please be sure you want to
                      proceed before continuing.
                    </Typography>
                  </Box>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          {flagselect && (
            <Grid container className={classes.spacingCont} alignItems="center">
              <Grid size={{ md: 5, xs: 12 }}>
                <Grid container className={classes.spacingCont}>
                  <Grid className={classes.flex}>
                    <Typography
                      variant="body2"
                      className={classes.textstyle}
                    >
                      Amount You Send <Tooltip title="Enter the amount that you want to send in the currency of your account.The beneficiary will receive the equivalent of this amount."><HelpOutlineOutlinedIcon className={classes.InfoOutlinedIcon}/></Tooltip>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container /* className={classes.spacingCont} */>
                  <Grid className={classes.flex}>
                    <TextField
                      variant="outlined"
                      size="small"
                      className={`${classes.sendAmountTextField} ${classes.itemWidth}`}
                      onChange={handleAmountInputChange}
                      onBlur={handleAmountInputBlur}
                      value={accountDetails.SendAmount}
                      slotProps={{
                        input: {
                          className:classes.borderstyle,
                          endAdornment: (
                            <InputAdornment position="end">
                              <CurrencyFlag
                                currency={accountDetails?.SendCurrency}
                               className={classes.CurrencyFlag}
                              />
                            </InputAdornment>
                          ),
                        },
                      }}
                    ></TextField>
                  </Grid>
                  {error.amountYouSendError && (
                    <div className="error-text">{error.amountYouSendError}</div>
                  )}
                </Grid>
              </Grid>
              <Grid size={{ md: 2 }} className={classes.flexCenter}>
                <SwapHorizIcon
                  className={classes.Swaphorizicon}
                />
              </Grid>
              <Grid size={{ md: 5, xs: 12 }}>
                <Grid container className={classes.spacingCont}>
                  <Grid className={classes.flex}>
                    <Typography variant="body2" className={classes.Fontweight}>
                      Beneficiary Gets <Tooltip title="Enter the amount that you want beneficiary to receive.Your account will be debited for the equivalent amount, plus transaction fee."><HelpOutlineOutlinedIcon className={classes.InfoOutlinedIcon}/></Tooltip>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid size={{ xs: 12, md: 5 }}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={handleReceiveAmountInputChange}
                      onBlur={handleReceiveAmountInputBlur}
                      value={accountDetails.ReceiveAmount}
                      className={classes.itemWidth}
                      slotProps={{
                        input: {
                          className:classes.borderstyle,
                          endAdornment: (
                            <InputAdornment position="end">
                              <FormControl fullWidth>
                                <SelectFeild
                                  options={options}
                                  value={selectedCurrency}
                                  onChange={handleReceiverCurrencyChange}
                                  formatOptionLabel={formatOptionLabel}
                                  isSearchable={true}
                                  placeholder="Select Currency"
                                  className={classes.currencyCodeSelect}
                                  classNamePrefix="custom"                                 
                                />
                              </FormControl>
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                {error.amountReceiveError && (
                    <div className="error-text">{error.amountReceiveError}</div>
                  )}
              </Grid>
            </Grid> 
          )}
          {loading ? (<Loader />) : (
          flagselect && showExchangeFields && (
            <Grid
              container
              spacing={2}
              className={`${classes.spacing} ${classes.exchangefields}`}
            >
              <Grid
                size={{ md: 12, xs: 12 }}
              >
                <Grid container>
                  <Grid  className={classes.exchangeGrid}>
                  <Grid size={{ md: 6 }}>
                    <div className={classes.label}>
                      <Typography variant="body2">Exchange Rate: <span className={classes.exchangeFeild}> {accountDetails.CurrencyConvDesc}</span></Typography>
                    </div>
                  </Grid>
                  <Grid size={{ md: 6 }}  >
                    {" "}
                    <div className={classes.label} >
                      <Typography variant="body2">
                      Exchange Rate Valid For: <span className={classes.exchangeFeild}>{accountDetails.FXRateGoodForText}</span>
                      </Typography>
                    </div>
                  </Grid>
                  </Grid>
                  <Grid size={{ md: 12 }} className={classes.spacing}>
                    <div className={classes.label}>
                      <Typography variant="body2">
                        Transaction Fee: <span className={classes.exchangeFeild}> {accountDetails.TransactionDesc}</span>
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
          )}

          <Grid container spacing={1}>
            {purposeCodeList && (
              <Grid size={{ xs: 12, md: 6 }} className={classes.purpose}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Purpose of Payment
                </Typography>
                
                <TextField
                  id="PurposeCode"
                  variant="outlined"
                  value={purposeCode}
                  size="small"
                  onChange={handlePurposeCode}
                  slotProps={{
                    input: { className: `${classes.purposeCodeDropdown} ${classes.space}`  },
                  }}
                  //label="Select Pay Purpose"
                  select
                  fullWidth
                >
                  {purposeCodeList &&
                    purposeCodeList.map((pCode) => (
                      <MenuItem
                        key={pCode.PayPurPCodeDesc}
                        value={pCode.PayPurPCodeDesc}
                      >
                        {pCode.PayPurPCode}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            )}
            {flagselect && (
              <Grid size={{ md: 6, xs: 12 }} className={classes.purpose}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Remarks <span className={classes.mandatory}>*</span>
                </Typography>
                <TextField
                  id="remarks"
                  //label="Remarks"
                  multiline
                  rows={1}
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  fullWidth
                  className={classes.space}
                />
                {/* {error.remarksError && (
                  <div className="error-text">{error.remarksError}</div>
                )} */}
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <></>
      )}

      <Grid container spacing={2} className={classes.spacingCont}>
        <Grid size={{ xs: 12, md: 12 }} className={classes.flexBetween}>
          <Button className={classes.cancel} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.continue}
            // onClick={handleContinue}
            onClick={validateForm}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AmountSection;
