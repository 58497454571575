import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import endPoints from "../../../config/Api";
import axios from "axios";
import CurrencyFlag from "react-currency-flags";
import { getAccessToken } from "../../Authorization/Services/authService";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { format, formatDistanceToNow, differenceInDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Card, CardContent,  IconButton, Typography,TablePagination } from "@mui/material";
import Grid from "@mui/material/Grid2"

const colors = [
  "#0b8953",
  "#c23824",
  "#2c97de",
  "#00657c",
  " #00aa66",
  "#667700",
  "#e27100",
  "#52459f",
];

const RecentPayments = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [searchText,setSearchText] = useState('');
  const [searchData, setSearchData] = useState([]);

  const fetchData = () => {
    const token = getAccessToken();
    axios
      .post(
        `${endPoints.baseUrl}/data_views/${endPoints.recentPayments.D_Page_Name}`,
        {
          dataViewParameters: {},
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setData(data?.data.data);
        setSearchData(data?.data.data);
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        navigate("/login");
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  const columns = [
    "Beneficiary",
    "Date",
    "Receive Amount/Currency",
    "Status",
    "StatusIcon",
  ];

  const searchHandler=(event)=>{
    const searchValue = event.target.value;
    setSearchText(searchValue)
    if (searchValue === '') {
      fetchData();  
    }
    const SearchedData = searchData.filter(row=>
      row.BeneficiaryName && row.BeneficiaryName.toLowerCase().includes(searchValue.toLowerCase())
    );
     setData(SearchedData);
     setPage(0);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function CustomTablePagination({ count, page, rowsPerPage, onPageChange }) {
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeftIcon />
        </IconButton>

        {/* Page count in the center */}
        <Typography variant="body2" sx={{ margin: "0 16px" }}>
          {`${page * rowsPerPage + 1}-${Math.min(
            count,
            (page + 1) * rowsPerPage
          )} of ${count}`}
        </Typography>

        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    );
  }

  const getInitials = (name) => {
    if (!name) return "";
    const nameArray = name.trim().split(/\s+/);
    return nameArray.length > 1
      ? `${nameArray[0][0]}${nameArray[1][0]}`.toUpperCase()
      : name.substring(0, 1).toUpperCase();
  };

  const getColor = (initials) => {
    if (!initials) return colors[0]; // Default color

    const firstChar = initials[0].toUpperCase();

    if (firstChar >= "A" && firstChar <= "Z") {
      const charCode = firstChar.charCodeAt(0) - "A".charCodeAt(0);
      return colors[Math.floor(charCode / 3) % colors.length];
    } else if (firstChar >= "0" && firstChar <= "9") {
      const num = parseInt(firstChar, 10);
      return colors[Math.floor(num / 3) % colors.length];
    }

    return colors[0]; // Default color for any other characters
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    //const daysDifference = differenceInDays(new Date(), date);
    return format(date, "MM/dd/yyyy, hh:mm a");
  }

  return (
    <>
      <Grid container  style={{ padding: "16px" }}>
        {/* Header */}
        <Grid
          size={{xs:12}}
          style={{ paddingBottom: "10px", borderBottom: "1px solid #c7c2c2" }}
        >
          <h2 style={{ margin: 0, fontSize: "18px", color: "#52459f" }}>
            Recent Payments
          </h2>
        </Grid>
        <Grid size={{md:12, xs:12}} style={{ marginTop: "20px" }}>
          <Grid
           size={{md:12 , xs:12}}
            style={{
              position:"relative",
              display:"flex",
              justifyContent:"center",
            }}
          >
            <input
              placeholder="Search for beneficiary by name"
              className={classes.inputInput}
              onChange={searchHandler}
              value={searchText}
              
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          spacing={2}
          style={{ padding: "10px" }}
        >
          <Grid  container  size={{xs:12}}>
            {data
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, index) => {
                const initials = getInitials(row?.BeneficiaryName);
                const backgroundColor = getColor(initials);
                return (
                  <Grid size={{xs:12}}>
                  <Card key={row.id} className={classes.accountCard} >
                    <CardContent className={classes.acContent}>
                    <div style={{
                          display: "flex",
                        }}>
                      <div style={{
                          marginRight: "15px",
                        }}>
                        <Avatar
                          style={{
                            backgroundColor,
                          }}
                        >
                          {initials}
                        </Avatar>
                      </div>
                      <Grid
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {/* <strong style={{ fontSize: "16px" }}> */}
                          {row?.BeneficiaryName}
                        {/* </strong> */}
                        <div
                          style={{
                            fontSize: "11px",
                            color: "#5649a1",
                            //fontWeight: "bold",
                          }}
                        >
                          {formatDate(row?.DateOfPayment)}
                        </div>
                      </Grid>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        {/* <strong style={{ fontSize: "14px" }}> */}
                          {row?.PaymentAmount?.toFixed(2)}
                        {/* </strong> */}
                        <div style={{ fontSize: "12px" }}>
                          {" "}
                          {row?.PaymentCurrency}
                        </div>
                        <div
                          style={{ fontSize: "10px" }}
                          className={classes.flag}
                        >
                          <CurrencyFlag
                            currency={row?.PaymentCurrency != null ? row?.PaymentCurrency : ""}
                            style={{
                              borderRadius: "50%",
                              width: "20px",
                              height: "20px",
                              //gap:"10px"
                            }}
                          />
                        </div>
                        <img title={row?.ImageOver} src={row?.pyImage}  />
                      </div>
                    </CardContent>
                  </Card>
                   </Grid>
                );
              })}
            
            {data && data.length > 0 && (
               <Grid container style={{marginLeft:"50%"}}>
              <CustomTablePagination
                count={data.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
            
              />
              </Grid>
            )}
            
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default RecentPayments;
