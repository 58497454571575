const flagsCode = {
  Austria: "EUR",
  Belgium: "EUR",
  Cyprus: "EUR",
  Canada: "CAD",
  Germany: "EUR",
  Estonia: "EUR",
  Spain: "EUR",
  Finland: "EUR",
  France: "EUR",
  "United Kingdom": "GBP",
  Greece: "EUR",
  Croatia: "EUR",
  Ireland: "EUR",
  Italy: "EUR",
  Japan: "JPY",
  Lithuania: "EUR",
  Luxembourg: "EUR",
  Latvia: "EUR",
  Malta: "EUR",
  Netherlands: "EUR",
  Portugal: "PTE",
  Qatar: "QAR",
  Slovenia: "EUR",
  Slovakia: "EUR",
  "United States": "USD",
  Argentina:"ARS"
};

export default flagsCode;