import React, { useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import CurrencyFlag from "react-currency-flags";
import useStyles from "./styles";
import Button from "@mui/material/Button";
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

const ModalPopup = (props) => {
  const {
    handleClose,
    rowData,
    open,
    colors,
    isPayAgainAlert,
    openAlertMessage,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    console.log("Modal received rowData:", rowData);
  }, [rowData]);

  const getInitials = (name) => {
    if (!name) return "";
    const nameArray = name.trim().split(/\s+/);
    return nameArray.length > 1
      ? `${nameArray[0][0]}${nameArray[1][0]}`.toUpperCase()
      : name.substring(0, 2).toUpperCase();
  };

  const dateConvert = (isoString) => {
    console.log(isoString);
    const date = new Date(isoString);
    // Define options for date and time formatting
    const dateOptions = { month: "2-digit", day: "2-digit", year: "numeric" };
    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };

    // Format the date and time
    const formattedDate = date.toLocaleDateString("en-US", dateOptions);
    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

    // Combine date and time
    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    return formattedDateTime;
  };
  const getColor = (initials) => {
    if (!initials) return colors[0]; // Default color

    const firstChar = initials[0].toUpperCase();

    if (firstChar >= "A" && firstChar <= "Z") {
      const charCode = firstChar.charCodeAt(0) - "A".charCodeAt(0);
      return colors[Math.floor(charCode / 3) % colors.length];
    } else if (firstChar >= "0" && firstChar <= "9") {
      const num = parseInt(firstChar, 10);
      return colors[Math.floor(num / 3) % colors.length];
    }

    return colors[0];
  };
  const backgroundColor = getColor(getInitials(rowData?.BeneficiaryNm));
  return (
    <>
      {isPayAgainAlert ? (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="simple-dialog-title" className={classes.dialogTitle}>
            <div className={classes.popup}>
              <Typography variant="body1" className={classes.popupTitle}>
                Pay Again
              </Typography>
              <ClearIcon className={classes.clearIcon} onClick={handleClose} />
            </div>
          </DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1" className={classes.alertMessage}>
              {openAlertMessage && openAlertMessage}
            </Typography>
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle id="simple-dialog-title" className={classes.dialogTitle}>
            <div className={classes.popup}>
              <Typography variant="body1" className={classes.popupTitle}>
                {rowData?.BeneficiaryNm}
              </Typography>
              <ClearIcon className={classes.clearIcon} onClick={handleClose} />
            </div>
          </DialogTitle>
          <DialogContent className="dialog-content">
            <Grid container spacing={1}>
              <Grid size={{ md: 12, xs: 12 }} className={classes.flexCenter}>
                <Avatar style={{ backgroundColor }}>
                  {getInitials(rowData?.BeneficiaryNm)}
                </Avatar>
              </Grid>
              <Grid size={{ md: 4 }}>
                <Typography variant="subtitle1" className={classes.labels}>
                  Beneficiary Account
                </Typography>
                <Typography className={classes.values}>
                  {rowData?.BeneficiaryAcct}
                </Typography>
              </Grid>
              <Grid size={{ md: 4 }}>
                <Typography variant="subtitle1" className={classes.labels}>
                  Amount Paid
                </Typography>
                <Typography
                  className={`${classes.values} ${classes.flexAlignCenter}`}
                >
                  {rowData?.PaymentAmount}
                  {rowData?.PaymentCurrency && (
                    <CurrencyFlag
                      currency={rowData?.PaymentCurrency}
                      className={classes.currencyFlag}
                    />
                  )}
                </Typography>
              </Grid>
              <Grid size={{ md: 4 }}>
                <Typography variant="subtitle1" className={classes.labels}>
                  Amount Received
                </Typography>
                <Typography
                  className={`${classes.values} ${classes.flexAlignCenter}`}
                >
                  {rowData?.ReceivedAmount}
                  {rowData?.ReceivedAmountCurrency && (
                    <CurrencyFlag
                      currency={rowData?.ReceivedAmountCurrency}
                      className={classes.currencyFlag}
                    />
                  )}
                </Typography>
              </Grid>
              <Grid size={{ md: 4 }}>
                <Typography variant="subtitle1" className={classes.labels}>
                  Beneficiary Bank Name
                </Typography>
                <Typography className={classes.values}>
                  {rowData?.BeneficiaryBkNm}
                </Typography>
              </Grid>
              <Grid size={{ md: 4 }}>
                <Typography variant="subtitle1" className={classes.labels}>
                  Reference ID
                </Typography>
                <Typography className={classes.values}>
                  {rowData?.TRN}
                </Typography>
              </Grid>
              <Grid size={{ md: 4 }}>
                <Typography variant="subtitle1" className={classes.labels}>
                  Date of Payment
                </Typography>
                <Typography className={classes.values}>
                  {dateConvert(rowData?.DateofPayment)}
                </Typography>
              </Grid>
              <Grid size={{ md: 6 }}>
                <Typography variant="subtitle1" className={classes.labels}>
                UETR
                </Typography>
                <Typography className={classes.values}>
                  {rowData?.UETR}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ModalPopup;
