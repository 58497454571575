import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import AmountSection from "./amountSection";
import Beneficiary from "./Beneficiary";
import { fetchAccountsData} from "../../CommonService/sendPaymentService";
import { useNavigate } from "react-router-dom";
import ReviewBeneficiary from "./ReviewBeneficiary";
import Review from "./Review";
import { Box, Tab, Tabs, Typography,Divider } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CurrencyFlag from "react-currency-flags";
import checkMark from "../../../assets/images/check_mark_green.svg";

const SendPayment = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [accounts, setAccounts] = useState([]);
  const [data, setData] = useState();
  const [avaiableToPayCurrencyList, setAvailableToPayCurrencyList] = useState(
    []
  );
  const [nextAssignmentId, setnextAssignmentId] = useState();
  const [caseId, setCaseId] = useState();
  const [eTag, setEtag] = useState();
  const [availableToPayCountryNamesList, setAvailableToPayCountryNamesList] =
    useState([]);
  const [receiversCountryName, setReceiversCountryName] = useState("");
  const [selectedBeneDetails, setSelectedBeneDetails] = useState(null);
  const [previousData, setPreviousData] = useState(null);
  const [purposeCodeList, setPurposeCodeList] = useState([]);
  const [reviewDetails, setReviewDetails] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [finalResponse, setFinalResponse] = useState(null);

  const [showReviewBeneficiary, setShowReviewBeneficiary] = useState(false);
  
  function getAccounts(response) {
    const list = response.data.data.shared.D_GetAccountList;
    const keys = Object.keys(list);
    let pxResults = [];
  
    for (const key of keys) {
      if (key.startsWith("D_GetAccountList_")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  function getCountryNamesList(response) {
    const list = response.data.data.shared.D_GetCountryName;
    const keys = Object.keys(list);
    let pxResults = [];
  
    for (const key of keys) {
      if (key.startsWith("D_GetCountryName")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchAccountsData();
        setData(response.data);
        setEtag(response.headers["etag"]);
        setnextAssignmentId(response.data.nextAssignmentInfo.ID);
        setCaseId(response.data.ID.split(" ")[1]);
        setAvailableToPayCurrencyList(
          response.data.data.shared.D_AvailabletoPayCurrencyListAPIList
            .D_AvailabletoPayCurrencyListAPIList.pxResults
        );
        setAvailableToPayCountryNamesList(getCountryNamesList(response));
        let pxResults = getAccounts(response);
        setAccounts(pxResults);  
      } catch (error) {
        console.error('Error fetching accounts data:', error);
        localStorage.clear();
        navigate("/login")
      }
    };
  
    fetchData();
  }, []);

  // Function to render tab content
  const renderTabContent = () => {
    switch (tabIndex) {
      case 0:
        return (
          <AmountSection
            onContinue={() => setTabIndex(1)}
            data={data}
            accounts={accounts}
            eTag={eTag}
            nextAssignmentId={nextAssignmentId}
            avaiableToPayCurrencyList={avaiableToPayCurrencyList}
            availableToPayCountryNamesList={availableToPayCountryNamesList}
            setPurposeCodeList={setPurposeCodeList}
            caseId={caseId}
            setEtag={setEtag}
            setReceiversCountryName={setReceiversCountryName}
            previousData={previousData}
          />
        );
      case 1:
        return (
          <Beneficiary
            onContinue={() => {
              setTabIndex(2); setShowReviewBeneficiary(true);
            }}
            receiversCountryName={receiversCountryName}
            nextAssignmentId={nextAssignmentId}
            eTag={eTag}
            setEtag={setEtag}
            setSelectedBeneDetails={setSelectedBeneDetails}
            setPurposeCodeList={setPurposeCodeList}
            onPrevious={() => setTabIndex(0)}
            setPreviousData={setPreviousData}
            previousData={previousData}
          />
        );
      case 2:
        if (showReviewBeneficiary) {
          return (
            <ReviewBeneficiary
              onContinue={() => {
                setTabIndex(3);
              }}
              selectedBeneDetails={selectedBeneDetails}
              purposeCodeList={purposeCodeList}
              nextAssignmentId={nextAssignmentId}
              eTag={eTag}
              setEtag={setEtag}
              setReviewDetails={setReviewDetails}
              onPrevious={() => {
                setShowReviewBeneficiary(false);
                setTabIndex(1);
              }}
              setPreviousData={setPreviousData}
              previousData={previousData}
            />
          );
        } else {
          return null;
        }
      case 3:
        return (
          <Review
            onContinue={() => setTabIndex(3)}
            nextAssignmentId={nextAssignmentId}
            eTag={eTag}
            setEtag={setEtag}
            reviewDetails={reviewDetails}
            setFinalResponse={setFinalResponse}
            onPrevious={() => {
              setTabIndex(2);
              setShowReviewBeneficiary(true);
            }}
            setPreviousData={setPreviousData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Box className={classes.rootContainer}>
      {!finalResponse?.content && (
          <>
        <Typography className={classes.title}>
          {/* New Send Payment {caseId} */}
          Send Money
        </Typography>
          <Tabs
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="payment steps"
            style={{ borderBottom: "2px solid #ededed" }}
          >
            <Tab label="Amount" className={classes.tabHeading} />
            <Tab label="Beneficiary" className={classes.tabHeading} />
            <Tab label="Review" className={classes.tabHeading} />
          </Tabs>
        {/* Render content based on the selected tab */}
        <Box style={{padding:'8px'}}>{renderTabContent()}</Box>
        </>
        )}
        {finalResponse?.content && (
          <>
            <div style={{ textAlign: "-webkit-center" }}>
              <img src={checkMark} width={50} alt="checkmark" height={50} />
              <Typography>Money Sent</Typography>
            </div>
            <Grid
              container
              style={{
                position: "relative",
                padding: "20px",
                borderRadius: "8px",
              }}
            >
              <Grid
                size={{ md: 12 }}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(255, 255, 255, 0.5)", 
                  zIndex: 1, 
                }}
              />
              <Grid size={{ md: 12 }}>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#52459f",
                    fontWeight: "700",
                  }}
                >
                  Transaction Summary
                </Typography>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Account to be Debited
                </Typography>
                <Box>
                  <Typography variant="body2" className={classes.contentstyle}>
                    {finalResponse?.content?.MaskAccountNumber}
                  </Typography>
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Available Balance
                </Typography>
                <Box className={classes.flex}>
                  <Typography variant=" body2" className={classes.contentstyle}>
                    {finalResponse?.content?.AccountBalance}
                  </Typography>
                  <Box style={{ marginLeft: "10px" }}>
                    {finalResponse?.content?.SendCurrency}
                  </Box>
                  <CurrencyFlag
                    currency={finalResponse?.content?.ReceiveCurrency}
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "50px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid size={{ md: 12 }}>
                {" "}
                <Divider />
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Amount to be sent
                </Typography>
                <Box className={classes.flex}>
                  <Typography className={classes.contentstyle}>
                    {finalResponse?.content?.ReceiveAmount}
                  </Typography>
                  <Box style={{ marginLeft: "5px" }}>
                    {finalResponse?.content?.SendCurrency}
                  </Box>
                  <CurrencyFlag
                    currency={finalResponse?.content?.ReceiveCurrency}
                    style={{
                      marginLeft: "5px",
                      width: "20px",
                      height: "20px",
                      borderRadius: "50px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Amount to be Received
                </Typography>
                <Box className={classes.flex}>
                  <Typography className={classes.contentstyle}>
                    {finalResponse?.content?.ReceiveAmount}
                  </Typography>
                  <Box style={{ marginLeft: "5px" }}>
                    {finalResponse?.content?.SendCurrency}
                  </Box>
                  <CurrencyFlag
                    currency={finalResponse?.content?.ReceiveCurrency}
                    style={{
                      marginLeft: "5px",
                      width: "20px",
                      height: "20px",
                      borderRadius: "50px",
                    }}
                  />
                </Box>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }} >
                <Typography variant="body2" className={classes.headerstyle}>
                  Transaction Fee
                </Typography>
                <Box className={classes.flex}>
                  <Typography variant="body2" style={{ fontsize: "13px" }}>
                    {finalResponse?.content?.TransactionDesc}
                  </Typography>
                  <CurrencyFlag
                    currency={finalResponse?.content?.ReceiveCurrency}
                    style={{
                      marginLeft: "5px",
                      width: "20px",
                      height: "20px",
                      borderRadius: "50px",
                    }}
                  />
                </Box>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Estimated Delivery Time
                </Typography>
                <Typography style={{ fontSize: "13px" }}>
                  {finalResponse?.content?.EstimatedDeliveryTime}
                </Typography>
              </Grid>
              <Grid container size={{ md: 12 }} >
                <Grid size={{ xs: 12, md: 6 }}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    Foreign Exchange Rate
                  </Typography>
                  <Typography style={{ fontSize: "13px" }}>
                    {finalResponse?.content?.CurrencyConvDesc}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    Exchange Rate Valid For
                  </Typography>
                  <Typography variant="small">
                    {finalResponse?.content?.FXRateGoodForText}
                  </Typography>
                </Grid>
              </Grid>
              <Grid size={{ md: 12 }}>
                {" "}
                <Divider className={classes.spacing} />
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Name
                </Typography>
                <Typography style={{ fontSize: "13px" }}>
                  {finalResponse?.content?.SelectedBeneDetails?.FullName}
                </Typography>
              </Grid>

              <Grid size={{ md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Account Type
                </Typography>
                <Typography variant="body2" style={{ fontSize: "13px" }}>
                  {finalResponse?.content?.SelectedBeneDetails?.CdtrAcctTpPrtry}
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Account Number
                </Typography>
                <Typography variant="body2" style={{ fontSize: "13px" }}>
                  {finalResponse?.content?.SelectedBeneDetails?.MaskAccNumb}
                </Typography>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Bank Address
                </Typography>
                <Typography variant="body2" style={{ fontSize: "13px" }}>
                  {finalResponse?.content?.SelectedBeneDetails?.BeneBankAddress}
                </Typography>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Bank BIC Code
                </Typography>
                <Typography style={{ fontSize: "13px" }}>
                  {finalResponse?.content?.SelectedBeneDetails?.BICFI}
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Payment Purpose Code
                </Typography>
                <Typography variant="body2" style={{ marginLeft: "34%" }}>
                  {finalResponse?.content?.PaymentPurposeCode_RVW}
                  {console.log("finalresponse",finalResponse)}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </>

  );
};
export default SendPayment;
