import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: theme.spacing(1),
    backgroundColor: "#fff",
    // boxShadow: theme.shadows[3],
    borderRadius: "30px",
    overflow: "hidden",
    margin: "3%",
  },
  flex: {
    display: "flex",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexAlignCenter: {
    display: "flex !important ",
    alignItems: "center !important ",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: "1.25rem !important ",
    fontWeight: "bold !important ",
    color: "#7270a0 !important ",
  },
  selectedRow: {
    backgroundColor: "#e0e0e0 !important ",
  },
  editIcon: {
    background: "#52459f !important ",
    color: "#fff !important ",
    padding: "3px !important ",
    borderRadius: "8px !important",
    cursor: "pointer !important",
    marginLeft:"35% !important"
  },
  tabHeading: {
    textTransform: "none !important",
    fontSize: "1rem !important ",
    padding: '0px !important ',
    color:"black !important ",
    "&.Mui-selected": {
      color: "#52459f !important",
    },
  },
  textfield: {
    marginTop: "3px",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      /* border: 'none',   */
      borderRadius: "20px",
    },
    "& input": {
      paddingLeft: "0px !important",
    },
  },
  inputLabel: {
    fontSize: "14px !important",
    color: "lightgrey !important",
    paddingLeft: "8px !important",
    marginTop:"6px !important "
  },
  acctInput: {
    padding: "2.5% !important ",
    border: "1px solid #d5d5d5 !important",
    borderRadius: "10px !important",
    fontSize: "14px !important ",
  
  },
  placeholder: {
    background: "none",
    color: "grey !important",
    fontSize: "0.9em",
  },

  spacingCont: {
    margin: "10px 0px !important ",
  },
  goodForTextContainer: {
    width: "100%",
    height: "100%",
    borderRadius: "50% !important ",
    background: "#52459f",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  goodForText: {
    color: "#fff",
    padding: "10px",
    width: "60%",
    alignSelf: "center",
    fontSize: "0.8rem !important ",
  },

  text: {
    fontSize: "0.7rem !important ",
    
  },
  currencyCodeSelect: {
    borderRadius: "15px",
    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  countrySelect: {
    border: "1px solid #d5d5d5 !important ",
    borderRadius: "8px !important",
    textAlign:"Left",
    "& .MuiSelect-select": {
      display: "flex !important ",
      alignItems: "center !important ",
      //justifyContent: "center !important ",
      marginLeft:"5px !important ",
      padding: "5px  !important ",
    },
  },
  spacing: {
    margin:"17px 0 0 10px"
  },
  header:{
    marginTop: "17px !important",
    background:"#52459f !important",
    color:"#fff !important ",
    padding:'2% !important ',
  },
  headerDetails:{
    display: 'flex !important ', 
    justifyContent: 'space-between !important', 
    alignItems: 'center !important '
  },
  headerstyle:{
    fontWeight:"600 !important ",
    fontSize:"0.9rem !important",
    lineHeight:"2rem !important"
  },
  contentstyle:{
    fontSize:"0.8rem !important",
  },
  infoStyle:{
    color:"Yellow !important", 
    fontSize:"1.5rem !important",
  },
  infoDetails:{
    fontWeight:"600 !important",
    fontSize:"0.9rem !important",
  
    marginLeft:"5px !important"
  },
  space:{
    margin:"17px 0 0 10px !important"
  },
  inputBase: {
    width:'50% !important ',
    padding: "5px !important ",
    display: "flex",
    alignItems: "center !important ",
    fontWeight: "bold !important ",
    fontSize: "0.76rem !important ",
  },
  label: {
    marginBottom: "0",
    display: "flex",
    alignItems: "center",
  },
  cancel: {
    border: "1px solid #BC64C7 !important  ",
    borderRadius: "40px !important ",
    background: "#ffff !important ",
    padding: "3px 12px 3px 12px !important",
    textTransform: "none !important ",
  },
  continue: {
    color: "#ffffff !important",
    padding: "5px 12px 5px 12px !impotant",
    background: "#51459E !important",
    textTransform: "none !important",
    borderRadius: "40px !important",
    "&:hover": {
      backgroundColor: "black !important ",
      color: "#ffffff !important ",
    },
  },
  searchIcon: {
    position: "absolute",
    right: "35px", // Adjust to align icon properly
    top: "50%",
    transform: "translateY(-50%)",
    color: "#52459f",
  },
  closeIcon: {
    position: "absolute",
    right: "10px", // Adjust to align icon properly
    top: "50%",
    transform: "translateY(-50%)",
    color: "#52459f",
  },
  circleIcon: {
    fill: "#51459E !important ",
  },
  addtxt: {
    fontSize: "14px !important ",
    fontWeight: "bold !important",
  },
  inputInput: {
    width: "100%",
    padding: "10px 0px 10px 10px",
    border: "1px solid #dcdcdc",
    borderRadius: "12px",
    fontSize: "14px",
    "&:focus": {
      border: "1px solid #dcdcdc",
      outline: "none",
    },
  },
  b_heading: {
    color: "#52459f !important",
    padding: "10px !important",
    fontSize: "14px !important ",
    fontWeight: 'bold !important',
    backgroundColor: "#dddd !important",
    display: "flex !important",
    alignItems: "center !important",
  },
  avatar: {
    marginRight: "15px",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "end",
    width: "15%",
  },
  accountCard: {
    border: "1px solid #DDDDDD !important ",
    borderRadius: "16px !important ",
    marginTop:'4px !important '
  },
  acContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent", // Ensure no conflicting background color
    padding: "20px !important",
    // "& .MuiCardContent-root:last-child ": { paddingBottom: 0 },
  "&:hover": {
      backgroundColor: "#51459e20 !important", // Hover effect for Card
    borderRadius: "16px",
    },
  },
}));
export default useStyles;
